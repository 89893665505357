/*
 * Headings
 *
 * Specific heading styles
 */

.c-heading-main {

  font-size: 1.5rem;
  line-height: 1.5;

  @include breakpoint(large) {

    font-size: 1.75rem;
    line-height: 1.285714285714286;

  }

}

.c-heading-section {

  margin-top: 1rem;
  margin-bottom: 1rem;

  font-size: 1.25rem;
  line-height: 1.5;
  color: $color-text-strong;
  font-weight: $semibold;

  &:first-child {

    margin-top: 0;

  }

  .o-icon {

    position: relative;

    margin-right: .5rem;

    &:last-child {

      margin-right: 0;
      margin-left: .5rem;

    }

  }

  .o-icon-sync {

    top: .25rem;

    color: $color-primary-dark;

  }
  
  .c-fields--modern & {
    margin-bottom: 0;
    margin-top: 1rem;
  }
  
  &--large {
    font-size: 1.5rem;
  }

}

.c-heading-section + .c-heading-description {

  margin-top: -1rem;

  font-size: 1rem;

}

.c-heading-section--separator {

  display: flex;
  align-items: center;

  &:after {

    display: block;
    height: .0625rem;
    width: 100%;
    margin-left: $unit-s;

    background-color: $color-border-weak;

    content: "";

  }

  span {

    flex: 1 0 auto;

  }

  @include breakpoint(medium) {

    margin-top: 2rem;
    margin-bottom: 2rem;

  }

}