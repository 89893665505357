/*
 * Conditional logic
 */

$conditional-background-color: $color-accent-blue;  //  Update in components.switchable.scss to match!
$conditional-spacing: $unit-xs;

.c-conditional {
  // background: #f9f9f9;
  background: $conditional-background-color;
  padding: $conditional-spacing;
  // border: 3px solid $conditional-background-color;
  // box-shadow: inset 0 0 0 4px $conditional-background-color;
  // padding: $unit-l;
  // padding: 0 $unit-l 0 ($unit-l+rem(2));
  // margin: 0 (-$unit-l);
  display: grid;
  // display: none;
  // grid-gap: $unit-m;
  position: relative;
  border-radius: $border-radius-medium;
  overflow: hidden;
  
  &--empty {
    text-align: center;
    padding-top: $unit-l*3;
    padding-bottom: $unit-l*3;
  }
  
  &.is-hidden {
    display: none;
  }
  
  // &:before, &:after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   background: transparent-color($color-black, .04);
  // }
  // 
  // &:before {
  //   left: $unit-l;
  //   top: 0;
  //   bottom: 0;
  //   width: rem(2);
  // }
  // 
  // &:after {
  //   left: ($unit-l+rem(2));
  //   right: ($unit-l+rem(2)); 
  //   height: rem(2);
  // }
}

.c-conditional__rule {
  // padding-top: $unit-m;
  // padding-left: $unit-m;
  // padding: $unit-l;
  // background: transparent-color(#000, .04);
  // border-radius: $border-radius-small;
  // padding: $unit-m;
  
  // &:first-of-type {
  //   padding-top: 0;
  //   border-top: 0;
  // }
  
  &:not(.c-conditional__rule--actions) {
    background: $color-white;
    border-radius: $border-radius-small;
  }
  
  &:first-child {
    border-top-left-radius: $border-radius-small;
    border-top-right-radius: $border-radius-small;
  }
  
  &.c-conditional__rule--actions {
    // background: $color-border-weak;
    padding: $unit-s $unit-l $unit-l;
    // border-bottom-left-radius: $border-radius-small;
    // border-bottom-right-radius: $border-radius-small;
    margin-bottom: $conditional-spacing;
    
    // + .c-conditional__rule {
    //   border-top-left-radius: $border-radius-small;
    //   border-top-right-radius: $border-radius-small;
    // }
    
    + .c-conditional__rule--always .c-conditional__option {
      padding-top: 0;
    }
  }
  
  &.c-conditional__rule--always {
    background: $conditional-background-color;
    // padding: $unit-m;
    // margin: 0 (-$unit-l) (-$unit-l);
    border-radius: 0;
    
    .c-conditional__option {
      padding: $unit-s $unit-l;
    }
    
    + .c-conditional__footer {
      background: $conditional-background-color;
      // margin: 0;
      padding: 0 $unit-l $unit-l;
    }
  }
}



.c-conditional__option {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $conditional-spacing;
  grid-row: auto;
  padding: $unit-m $unit-l;
  background: $color-white;
  // padding-top: $unit-m;
  position: relative;
  
  &:first-child {
    border-top-left-radius: $border-radius-small;
    border-top-right-radius: $border-radius-small;
  }
  
  &:first-child:last-child {
    border-radius: $border-radius-small;
  }

  + .c-conditional__option {
    // padding-top: 0;
    border-bottom-left-radius: $border-radius-small;
    border-bottom-right-radius: $border-radius-small;
  }
  
  &.c-conditional__option--action {
    // border-top: 1px solid transparent-color(#000, .1);
    // margin-top: -$border-radius-small;
    // margin-top: $unit-l;
    background: $color-border-weak;
    margin: $conditional-spacing;
    border-radius: $border-radius-small;
    padding: $unit-m ($unit-l - $conditional-spacing);
  }
  
  .c-conditional__rule--always & {
    background: none;
    margin: 0;
  }
  
  &:first-of-type {
    border-top: 0;
    // padding-top: 0;
    margin-top: 0;
  }
  // 
  // .conditional__title {
  //   grid-column: 1;
  // }
  // 
  // .conditional__options {
  //   grid-column: 1;
  //   grid-row: 2;
  // }
  // 
  // .conditional__actions {
  //   grid-column: 2;
  //   grid-row: 1 / span 2;
  // }
}

.conditional__title {
  
  strong {
    background: transparent-color($color-black, .1);
    border-radius: $border-radius-small;
    padding: rem(4) rem(6);
  }
  
  .o-select {
    // margin: $unit-m 0 $unit-m;
    max-width: rem(120);
  }
}

.conditional__options {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: $unit-xs;
  position: relative;
  padding-right: rem(48) + $unit-xs;
  
  .l-form__item {
    margin: 0;
  }
  
  .c-field:first-child:last-child {
    grid-column: 1 / span 2;
  }
  
  .o-button--danger {
    position: absolute;
    top: 0;
    right: 0;
    width: rem(48);
    height: rem(48);
    
    .o-label {
      @include hide-visually();
    }
  }
}

.conditional__actions {
  
}

.c-conditional__rule--actions {
  display: flex;
  // margin: -$unit-s 0 $unit-xs;
  
  button {
    margin-left: $unit-xs;
    display: block;
    
    &:first-of-type {
      margin-left: 0;
    }
  }
}