/*
 * Rich text
 *
 * Overrides for the default CSS used by react-mde component
 * https://github.com/andrerpena/react-mde
 *
 * To test locally:
 * 1) copy the HTML from the website (e.g. into inspector)
 * 2) include the CSS from react-mde in this file
 */

$editor-toolbar-height: rem(40);
$editor-content-height: rem(200);
$editor-bar-height: rem(30);

.markdown_preview {
  background-color: transparent !important;
}

.o-textarea--rich-text {

  .w-md-custom-editor {
    // background: yellow;
    border: 1px solid $color-border-weak;
    border-radius: $border-radius-small;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: rem(10);
  
    &.w-md-custom-editor-show-edit .w-md-custom-editor-input { width: 100% }
    &.w-md-custom-editor-show-preview .w-md-custom-editor-preview { width: 100%; border-left: 0 }
  }
  
  .w-md-custom-editor-toolbar {
    
  }
  
  .wmde-markdown {
    background: transparent !important;
  }
}

.w-md-custom-editor-toolbar {
  background: $color-border-weak;
  display: flex;

  ul {
    @include list-reset();
    display: flex;
    padding: rem(4) rem(2);
    
    + ul {
      margin-left: auto;
    }
  }
  
  li {
    margin: 0 rem(2);
  }
  
  button {
    background: none;
    border: 0;
    line-height: 0;
    padding: $unit-xs;
    border-radius: $border-radius-small;
    transition: $transition-fast;
    
    &:hover {
      cursor: pointer;
    }

    svg {
      width: $unit-s;
      height: $unit-s;
      color: $color-grey-x-dark;
    }
  }
  
  .active button,
  button:hover,
  button:focus {
    background: $color-grey-mid-light;
    
    svg {
      color: $color-primary-dark;
    }
  }
}

.w-md-custom-editor-content {
  // height: $editor-content-height;
  position: relative;

  &:not(pre):not(code) {
    //  =info (Emanuel, 2022-02-01)
    //  This was changed from targeting everything (*) to targeting
    //  just the element. I believe that ":not(pre):not(code)" are
    //  completely obsolete and we might remove them since this
    //  shouldn't impact a thing like this, but I'll leave it as is for now.
    
    margin: 0;
    padding: 0;
    overflow: auto;
    
    //  =info (Emanuel, 2024-04-08)
    //  Added this following line since without proper height on this element
    //  the element would be rendered based on default value, and would not resize
    //  vertically and we want to prevent this.
    height: 100%; 
  }
  
  .w-md-custom-editor-show-preview > & {
    //  =info (Emanuel, 2022-11-28)
    //  The following was added due to an issue mentioned in #2093
    //  https://github.com/sdesregistry/IH-Engage/issues/2093#issuecomment-1314588791
    //  The problem arise after TypeScript update (possibly some structure changes).
    //  To solve it, we are applying the following fix only when preview
    //  content is active (and not for the editor itself, since it's being
    //  reused with the regular markdown editor)
    
    overflow: visible;
    height: 100%;
  }
  
  .w-md-custom-editor-input {
    width: 50%;
    height: 100%;
  }
  
  .w-md-custom-editor-text,
  .w-md-custom-editor-text-pre {
    white-space: pre-wrap;
    word-break: keep-all;
    overflow-wrap: break-word;
  }
  
  .w-md-custom-editor-text {
    position: relative;
    min-height: 100%;
    padding: $unit-s;
  }
  
  .w-md-custom-editor-text-input {
    box-shadow: none;
    resize: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: inherit;
  }
  
  .w-md-custom-editor-preview {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    padding: $unit-s;
    overflow: auto;
    border-left: 1px solid $color-border-weak;
  }
  
  .w-md-custom-editor-text-pre {
    position: relative;
    margin: 0;
    pointer-events: none;
    
    .c-upload-caption__edit & {
      max-width: rem(295);
    }
  }
  
  pre, code {
    border: 0;
  }
}

.w-md-custom-editor-bar {
  position: absolute;
  cursor: s-resize;
  left: rem(-1);
  bottom: rem(-10);
  width: calc(100% + 2px);
  z-index: 3;
  height: 10px;
  background: $color-border-weak;
  border-bottom-left-radius: $border-radius-small;
  border-bottom-right-radius: $border-radius-small;
  
  svg {
    width: rem(16);
    height: rem(16);
    color: $color-grey-x-dark;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// .o-textarea--rich-text {
// 
//   textarea {
// 
//     border-radius: 0;
// 
//   }
// 
//   .react-mde {
// 
//     border: none;
// 
//     background-color: $color-primary-light;
//     border-radius: $border-radius-small;
// 
//     button {
// 
//       color: $color-text;
// 
//     }
// 
//     .mde-header {
// 
//       border-bottom: none;
// 
//       background-color: transparent;
// 
//       button {
// 
//         color: $color-text;
//         border-radius: $border-radius-small;
//         font-weight: $semibold;
//         font-family: $font-body;
// 
//         &:hover,
//         &:focus,
//         &:active {
// 
//           color: $color-text-strong;
// 
//         }
// 
//       }
// 
//       button.selected {
// 
//         border: none;
// 
//         color: $color-text-strong;
//         background-color: #fff;
//         box-shadow: inset 0 0 0 .0625rem $color-input-regular;
// 
//       }
// 
//     }
// 
//     .mde-header-group {
// 
//       .mde-header-item button {
// 
//         color: $color-text-weak;
// 
//         &:hover,
//         &:focus,
//         &:active {
// 
//           color: $color-text-strong;
// 
//         }
// 
//       }
// 
//     }
// 
//     .grip {
// 
//       border-top: none;
// 
//       height: $unit-s;
//       display: flex;
//       align-items: center;
//       justify-content: center;
// 
//       background-color: transparent;
// 
//     }
// 
//     .grip .icon {
// 
//       height: 1rem;
// 
//       color: $color-text-weak;
// 
//     }
// 
//     .mde-preview {
// 
//       .mde-preview-content a {
// 
//         color: $color-link;
//         text-decoration: underline;
// 
//         &:hover,
//         &:focus,
//         &:active {
// 
//           text-decoration: none;
// 
//         }
// 
//       }
// 
//     }
// 
//   }
// 
// }

/*
 * Rich Text
 *
 * Component showing edit and preview tabs
 *
 * NOTE: Our custom component, documented for posterity.
 */

// .c-rich-text {}

// .c-rich-text__edit {

//   box-shadow: inset 0 0 0 .0625rem $color-input-regular;
//   border-radius: $border-radius-small;
//   border-top-right-radius: 0;

//   p {

//     padding: .75rem 1rem;
//     margin-bottom: 0;
//     border-bottom: .0625rem solid $color-grey-x-light;

//     font-size: .875rem;
//     line-height: 1.4285714286;
//     color: $color-text-weak;

//     a {

//       color: inherit;
//       text-decoration: underline;

//     }

//   }

//   p + textarea {

//     border-top-left-radius: 0;
//     border-top-right-radius: 0;

//   }

//   textarea {

//     font-size: 1rem;
//     line-height: 1.5;

//     box-shadow: none;
//     background-color: transparent;

//   }

// }

// .c-rich-text__preview {

//   padding: .75rem 1rem;

//   border-radius: $border-radius-small;
//   box-shadow: inset 0 0 0 .0625rem $color-input-regular;
//   // background-color: $color-grey-x-light;

//   display: none;

// }

// .c-rich-text__controls {

//   display: flex;
//   justify-content: flex-end;

//   margin-top: -2rem;

//   .o-button {

//     padding: .375rem .75rem;
//     margin-bottom: -.0625rem;

//     font-size: .875rem;
//     line-height: 1.4285714286;

//     border-bottom-left-radius: 0;
//     border-bottom-right-radius: 0;
//     border: .0625rem solid $color-input-regular;

//     &.is-active {

//       color: $color-text-strong;
//       background-color: #fff;
//       border-bottom-color: transparent;

//     }

//   }

//   .o-button + .o-button {

//     margin-left: $unit-xs;

//   }

// }
