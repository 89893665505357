.c-header-search {

  display: none;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  box-shadow: $box-shadow-small;

}

.c-header-search__field {

  input[type="text"] {

    border-radius: 0;
    border: none;
    padding: 1.25rem 3.5rem 1.25rem 3.5rem;

    font-size: 1rem;
    line-height: 1.5;
    background-color: #fff;
    box-shadow: inset 0 -1px 0 $color-border-normal;

  }

}

.c-header-search__action {

  position: absolute;
  top: .5rem;

  height: 48px;
  width: 48px;
  padding: 12px;
  border: none;

  font-size: 1.5rem;
  line-height: 1;
  color: $color-text;
  background: transparent;

  .c-header-search__label {

    @include hide-visually;

  }

}

.c-header-search__action--search {

  left: .5rem;

}

.c-header-search__action--clear {

  right: .5rem;

}

.c-header-search__results {

  padding: .5rem 0;

  background-color: #fff;

}

.c-header-search__title {

  margin: 0;
  padding: .5rem 1rem .5rem 1rem;

  font-size: .75rem;
  line-height: 1.5;
  font-weight: $semibold;
  font-family: $font-headline;
  text-transform: uppercase;
  letter-spacing: .05em;
  color: $color-text-strong;


}

.c-header-dropdown__separator {

  display: block;
  margin: .5rem 1rem;
  border-top: 1px solid $color-border-normal;

}

.c-header-search__list {

  list-style: none;
  margin: 0;
  padding: 0;

}

.c-header-search__item {

  margin-top: 0;

  font-size: 1rem;
  line-height: 1.5rem;

}

.c-header-search__link {

  display: block;
  padding: .5rem 1rem;

  color: $color-link;

  .o-icon {

    float: left;
    width: 1.5rem;
    margin-right: .5rem;

    font-size: 1.5rem;
    color: $color-text-weak;

  }

  &:hover,
  &:focus,
  &:active {

    color: $color-link;
    background-color: $color-link-tint;

  }

}

/*
 * Desktop
 *
 * Component description
 */

@include breakpoint(large) {

  .c-header-search {

    display: block;

    position: relative;

    margin: 0 1rem;
    width: 28rem;

    box-shadow: none;

  }

  .c-header-search__field {

    input[type="text"] {

      padding: .75rem 3rem;

      background-color: $color-primary-light;

      box-shadow: none;
      border-radius: $border-radius-small;

      &:hover {

        background-color: #fff;

      }

      &:focus {

        background-color: #fff;
        box-shadow: inset 0 -1px 0 $color-border-normal;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

      }

    }

  }

  .c-header-search__action {

    top: 0;

  }

  .c-header-search__action--search {

    left: 0;
    color: $color-grey-dark;

  }

  .c-header-search__action--clear {

    right: 0;
    color: $color-grey-dark;

  }

  .c-header-search__results {

    display: none;

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    box-shadow: $box-shadow-small;

    border-bottom-left-radius: $border-radius-small;
    border-bottom-right-radius: $border-radius-small;

  }

}


/*
 * Interactions
 *
 * Toggle on and off on mobile
 */

@include breakpoint(to-large) {

  .c-header-search-opened {

    .c-header-search {

      display: block;

    }

  }

}

@include breakpoint(large) {

  .c-header-search__action--clear {

    opacity: 0;
    pointer-events: none;

  }

  .c-header-search-focused,
  .c-header-search-opened {

    .c-header-search__field input[type="text"] {

      background-color: #fff;
      box-shadow: inset 0 -1px 0 $color-border-normal;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

    }

    .c-header-search__results {

      display: block;

    }

    .c-header-search__action--clear {

      opacity: 1;
      pointer-events: auto;

    }

  }

}