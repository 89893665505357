/*
 * Clients
 *
 * List of clients with meta information.
 */

.c-list-clients {

  list-style: none;
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: $unit-xs;

  @include breakpoint(extra-small) {

    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-auto-rows: 1fr;

  }

}

.c-list-clients__item {

  margin-top: 0;

}

.c-list-clients__link {

  display: block;
  height: 100%;
  padding: $unit-xs $unit-s;
  border: .0625rem solid $color-border-weak;

  border-radius: $border-radius-small;

  &:hover,
  &:focus,
  &:active {

    background-color: $color-primary-light;

  }

}

.c-list-clients__name {

  font-weight: $semibold;

}

.c-list-clients__meta {

  display: block;

  color: $color-text-weak;

}