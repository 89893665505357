/*
 * List validation
 *
 * Items with validation states, used for previewing
 * updates before they are published.
 */

.c-list-validation {

  list-style: none;
  margin: 0;
  padding: 0;

  margin-bottom: $unit-m;

  .o-icon {

    position: relative;
    top: .0625rem;

    font-size: 1rem;

    &:first-child {

      width: 1.5rem;
      // margin-right: $unit-xs;

    }

  }

  .o-icon-check {

    color: $color-success;

  }

  .o-icon-triangle {

    color: $color-warning;

  }

  .o-icon-times {

    color: $color-danger;

  }

  .o-checkbox & {

    margin-top: $unit-xs;

    margin-left: $unit-s;
    padding-left: $unit-s;

    border-left: .0625rem solid $color-border-weak;

  }

}

.c-list-validation__item {

  margin-top: $unit-xs;

  font-size: .875rem;

  // display: flex;
  // flex-direction: row;

}

.c-list-validation__name {

  margin-right: $unit-xs;

  // font-size: 1rem;
  // line-height: 1.5;
  color: $color-text;

}

.c-list-validation__meta {

  margin-right: $unit-xs;

  // font-size: .875rem;
  color: $color-text-weak;
  // display: none;

}

.c-list-validation__description {

  margin-right: $unit-xs;

  // display: none;

  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // max-width: 400px;
  // display: inline-block;
  // vertical-align: middle;

  // font-size: .875rem;
  color: $color-text-weak;
  opacity: .5;
  // opacity: .5;

  // white-space: nowrap;
  // text-overflow: ellipsis;
  // overflow: hidden;

}

.c-list-validation__translation {

  display: inline-flex;

  // color: $color-danger;

  abbr {

    padding: 0 .5rem;

    font-size: .75rem;
    text-transform: uppercase;
    font-weight: $semibold;
    color: $color-text-strong-invert;
    background-color: $color-danger;

    &:first-child {

      border-top-left-radius: .5rem;
      border-bottom-left-radius: .5rem;

    }

    &:last-child {

      border-top-right-radius: .5rem;
      border-bottom-right-radius: .5rem;

    }

  }

  abbr + abbr {

    box-shadow: inset .0625rem 0 0 0 rgba(255,255,255,.25);

  }

  // font-size: .75rem;

}