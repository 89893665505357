/*
 * Question: Distribute
 */

.c-question-distribute {

  display: flex;
  flex-flow: column;

}

.c-question-distribute__label {

  display: inline-block;
  padding: .5rem 1rem;
  margin-left: auto;
  margin-bottom: 1rem;

  border-radius: $border-radius-small;
  background-color: $color-primary-light;

}

.c-question-distribute__items {}

.c-question-distribute__item {

  margin-top: .5rem;

  &:first-child {

    margin-top: 0;

  }

  .o-input-range {}

  .o-label {

    margin-bottom: 0;

    font-family: $font-body;
    letter-spacing: 0;
    color: $color-text-strong;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: $semibold;

  }

}