/*
 * Annotate map
 *
 * Component to style maps done with Google API
 * including maps containing "clusters".
 */

$reaction-size-small: 2rem;
$reaction-size-medium: 3rem;

.gm-style {
  
  .gm-style-iw {
    padding: auto !important;
    max-width: none !important;
    max-height: none !important;
    border-radius: $border-radius-medium !important;
  }
   
  .gm-style-iw-d {
    overflow: auto !important;
    max-height: none !important;
  }
  
  .gm-style-iw-c {
    box-shadow: $box-shadow-large !important;
    padding: $unit-xs 2.375rem $unit-xs $unit-xs !important;
  }
  
  .gm-style-iw button {
    opacity: .5 !important;
    top: 0 !important;
    right: 0 !important;
    
    &:focus {
      opacity: 1 !important;
    }
    
    img {
      width: 1.25rem !important;
      height: 1.25rem !important;
      margin: 0.375rem !important;
    }
  }
  
  
  //  ------------------------------
  //  The following styles are similar to .c-annotate-image__cluster-details,
  //  though they are using some different approach due to Google API
  //  limitations. That's why these are included as part of .gm-style
  //  parent – to make sure these changes impact just those styles with
  //  embeded Google Map.

  .c-annotate-map__cluster-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: .375rem;
    transition: $transition-default;
    background-color: #fff;
    pointer-events: none;
  
    .c-annotate-map__reaction {
      position: relative;
      margin: 0;
  
      img {
        filter: none;
      }
    }
  
    .c-annotate-map__value {
      margin-left: .25rem;
      padding: 0;
      box-shadow: none;
      background: none;
    }
  }
  
  .c-annotate-map__reaction {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0 - ($reaction-size-small * 0.5);
    margin-top: 0 - ($reaction-size-small * 0.5);
    pointer-events: none;
  
    img {
      position: relative;
      display: block;
      width: $reaction-size-small;
      height: $reaction-size-small;
      min-width: $reaction-size-small;
      min-height: $reaction-size-small;
      margin-bottom: 0;
      filter: drop-shadow(0 .125rem .25rem rgba(0,0,0,.8));
      pointer-events: none;
    }
  }
  
  .c-annotate-map__value {
    padding: 0 .25rem 0 .75rem;
    margin-left: -.75rem;
    background-color: #fff;
    font-size: .75rem;
    font-weight: bold;
    box-shadow: $box-shadow-medium;
    border-radius: $border-radius-small;
  }
}