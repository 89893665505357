.c-header-logo {

  margin: 0;

  @include hide-text;

}

.c-header-logo__link {

  display: block;

}

/*
 * Mobile
 *
 * Logo is only a Built-ID symbol
 */

@include breakpoint(to-large) {

  .c-header-logo {

    // position: absolute;
    // left: 50%;
    // top: 50%;

    width: 48px;
    height: 48px;

  }

  .c-header-logo__link {

    height: 48px;

    background: assets-path('images/bid-gmv-symbol.svg') no-repeat 50% 50%;

  }

}

/*
 * Desktop
 *
 * Logo is a full Built-ID title, aligned left.
 */

@include breakpoint(large) {

  .c-header-logo {

    flex: 0 0 220px;

  }

  .c-header-logo__link {

    height: 64px;
    width: 220px;

    background: assets-path('images/bid-gmv-logo--white--normal.svg') no-repeat 0 50%;

  }

}

