/*
 * Suggest
 *
 * Auto suggestion component
 */

.c-suggest {

  position: relative;

}

.c-suggest__content {

  display: none;

}

.c-suggest__items {

  list-style: none;
  margin: 0;
  padding: 0;

  box-shadow: inset 0 0 0 .0625rem $color-input-regular;
  background-color: #fff;

  border-bottom-left-radius: $border-radius-small;
  border-bottom-right-radius: $border-radius-small;

  // background-color: $color-input-regular;

}

.c-suggest__item {

}

.c-suggest__link {

  padding: $unit-xs $unit-m;
  // padding: .375rem $unit-m;
  padding-left: 3rem;
  width: 100%;
  border: none;

  color: $color-text-weak;
  text-align: left;
  background-color: transparent;

  transition: all .1s ease-in;

  &:hover,
  &:focus,
  &:active {

    color: $color-link;
    background-color: tint($color-link, 90%);

  }

}

/*
 * Modifiers
 */

.c-suggest--active {

  .c-suggest__search {

    input[type="text"] {

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

    }

  }

  .c-suggest__content {

    display: block;

  }

}

.c-suggest--floating {

  .c-suggest__content {

    position: absolute;
    z-index: $z-index-suggest;
    top: 100%;
    left: 0;
    right: 0;

    box-shadow: $box-shadow-large;

  }

}