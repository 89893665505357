/*
 * Fields
 *
 * Fields with additional info:
 * - label
 * - input/textarea/select/checkbox/radio
 * - description
 * - character count
 */

.c-fields {

  //  =Modern
  &--modern {
    display: grid;
    grid-gap: $unit-xs;
    width: 100%;
    justify-content: normal;
    
    
    @include breakpoint(large) {
      grid-gap: $unit-s;
    }
  }

}

.c-field {

  position: relative;

  text-align: left;
  
  &.is-hidden {
    display: none;
  }
}

.c-field__label {

  position: relative;
  display: block;
  margin-bottom: .5rem;

  font-family: $font-body;
  font-size: 1rem;
  line-height: 1.5;
  color: $color-text;
  font-weight: $semibold;

} 

.c-field__description {

  margin-bottom: 0;
  margin-top: .5rem;

  font-size: .875rem;
  line-height: 1.4285714286; // 20/14
  color: $color-text-weak;

  &:first-child {

    margin-top: 0;

  }

}

.c-field__description--error {

  color: $color-danger;
  font-weight: $semibold;

}

.c-field__description + .c-field__description {

  margin-top: .25rem;

}

.c-field__count {

  position: absolute;
  top: 0;
  right: 0;

  margin: 0;

  font-size: .75rem;
  line-height: 1.5rem;
  color: $color-text-weak;
  text-align: center;
  border-radius: $border-radius-small;
  transition: all .15s ease-in;

  &:before {

    display: inline-block;
    width: .375rem;
    height: .375rem;
    margin-right: .1875rem;

    background-color: $color-primary-light;
    border-radius: .25rem;
    vertical-align: middle;

    transform: scale(0);
    transition: all .15s ease-in;

    content: "";

  }

}

.c-field__count--limit {

  color: $color-text-strong;

  &:before {

    transform: scale(1);
    background-color: $color-warning;

  }

}

.c-field__reorder {


  .c-field--reorder-images & {

    display: flex;
    flex-flow: row wrap;

    > .c-field {

      margin-right: $unit-s;

    }

  }

}

.c-field--reorder {

  display: flex;
  margin-bottom: $unit-s;

  .c-field__group {

    width: 100%;

  }

  .c-field__count {

    position: absolute;
    top: auto;
    right: .5rem;
    bottom: 2.25rem;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;

    border-radius: 50%;
    text-align: center;
    background: $color-link-tint;

    &:before {

      display: none;

    }

  }

  .c-field__count.c-field__count--limit {

    background-color: $color-warning;

  }

  &.c-field--label-hidden {

    #{$all-text-inputs} {

      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

    }

  }

  &.c-field--disabled {

    #{$all-text-inputs} {

      border-top-left-radius: $border-radius-small;
      border-bottom-left-radius: $border-radius-small;

    }

  }

  // Class added when item is dragged (and duplicated)
  &.c-field--reorder-helper {

    z-index: $z-index-modal;

  }

  // Reactions
  &.c-field--reaction {

    max-width: 10rem;

    #{$all-text-inputs} {

      padding-left: $unit-xs;
      padding-right: $unit-xs;

      font-size: 1rem;
      line-height: 1.5;

    }

  }

}

.c-field__handle {

  position: relative;
  z-index: $z-index-switchable;

  height: 3rem;
  padding: .75rem .25rem;
  border: none;
  margin-right: $unit-s;

  text-align: center;
  color: rgba($color-text, .4);
  background-color: $color-link-tint;
  border-radius: $border-radius-small;

  cursor: move;
  @include prefixer(cursor, grab, ("webkit", "moz"));

  transition: all .1s ease-in;

  &:hover {

    color: $color-text-strong;

  }

  .o-icon {

    position: relative;
    font-size: 1.5rem;
    width: 1.5rem;

  }

  .o-label {

    @include hide-visually();

  }

  .c-field--label-hidden.c-field--reorder & {

    margin-right: 0;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }

  .c-field--reaction & {

    display: flex;
    align-items: center;
    height: auto;

  }

  .c-field--disabled & {

    cursor: not-allowed;
    pointer-events: none;

    .o-icon {

      opacity: 0;

    }

  }

}



/*
 * Modifiers
 */

.c-field--action {

  .c-field__group {

    display: flex;
    flex-flow: row wrap;

    .o-button {

      margin-right: .5rem;

    }

    .o-button:last-child {

      margin-right: 0;

    }

    .o-button--danger {

      margin-left: auto;

    }

    .c-field__description {

      margin-top: 1rem;
      width: 100%;

    }

    @include breakpoint(extra-small) {

      flex-flow: row wrap;

      .o-button {

        margin-top: 0;

      }

    }

  }

}

.c-field--label-padding {

  .c-field__group {

    padding-top: 2rem;

  }

}

.c-field--label-hidden {

  .c-field__label {

    @include hide-visually();

  }

}

.c-field--label-inline {

  display: flex;
  align-items: center;

  .c-field__label {

    margin-bottom: 0;
    margin-right: $unit-s;

  }

  .c-field__group {

    width: 100%;

  }

}

.c-field--disabled {



}

.c-field--contains-translation {
  
  .o-tooltip:first-of-type {
    margin-left: rem(8);
    margin-right: 0;
  }
}

.c-field--logic {

  display: flex;
  align-items: center;

  background-color: $color-grey-x-light;
  border-radius: $border-radius-small;

  .c-field__label {

    margin-bottom: 0;
    margin-right: $unit-s;
    padding-left: $unit-s;

    width: 50%;

  }

  .c-field__group {

    width: 50%;

  }

}

.c-field--logic + .c-field--logic {

  margin-top: 0 - $unit-s;
}

.c-field--grid-field-group {
  
  > .c-field__group {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}


.c-field--inline-label {
  //  This modifier targets .c-field__label and .c-field__group so label is
  //  positioned left, and group to right, each taking 50% of field size

  //  Note!
  //  This is not used anywhere for time being
  //  Needs to be further tested
  
  @include breakpoint(medium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    label {
      display: flex;
      align-items: center;
      margin: 0;
    }
  }
}

.c-field--boxed,
.c-field--subtle {
  background: $color-border-weak;
  border-radius: $border-radius-small;
  padding: $unit-s $unit-m;
  
  + & {
    margin-top: -$unit-xs;
  }
  
  .c-field__label + .c-field__group > .c-field > .c-field__label {
    margin-top: $unit-xs;
  }
  
  &.c-field--inline-fields .c-field__label + .c-field__group > .c-field:not(:first-child) > .c-field__label {
    margin-top: 0;
  }
}

.c-field--subtle {
  background: none;
  border: 1px solid $color-input-regular;
}

.c-field--inline-fields {
  //  This modifier targets first .c-field__group and makes it flex&wrap, then
  //  each .l-form__item is being stripped of margin and width initially, so
  //  those form items that have modifiers are recalculated properly (to avoid)
  //  rendering issues.
  
  > .c-field__group {
    display: flex;
    flex-wrap: wrap;
    grid-gap: $unit-s;
  }
  
  > .c-field__group > .l-form__item {
    margin: 0;
    width: auto;
    
    &.l-form__item--l {
      width: 100%;
      flex: 0 1 100%;
    }
    
    &.l-form__item--m {
      width: 50%;
      flex: 0 1 50%;
    }
    
    &.l-form__item--s {
      width: 33.333333%;
      flex: 0 1 33.333333%;
    }
    
    &.l-form__item--xs {
      width: 25%;
      flex: 0 1 25%;
    }
  }
}


/*
 * States
 */

.c-field.is-error {

  #{$all-text-inputs} {

    border-color: $color-danger;
    box-shadow: inset 0 0 0 .125rem $color-danger;

  }

  .o-select {

    border-color: $color-danger;
    box-shadow: inset 0 0 0 .125rem $color-danger;

  }

  .c-field__label {

    color: $color-danger;

  }

}

.c-conditional .c-field.is-required:not(.is-error) {
  
  //  =todo
  //  This functionality is for now used on conditional logic only
  //  We will roll it out across application when times come, by
  //  removing .c-conditional parent class.
  
  .o-input:before,
  .o-select:before {
    content: "";
    width: rem(4);
    height: rem(36);
    border-radius: rem(20);
    background: $color-danger;
    position: absolute;
    top: rem(6);
    left: rem(4);
    opacity: 1;
    transition: $transition-fast;
    
  }

  &.is-populated {

    .o-input:before,
    .o-select:before {
      opacity: 0;
    }
  }

  .c-field__label {

    color: $color-danger;

  }

}

/*
 * Survey export special field
 */

.c-survey-export {

  .c-field__label {
    margin: 0;
    background: $color-white;
    padding: 0 $unit-s 0 0;
    display: inline-block;
    margin-bottom: rem(4);
  }

  .c-field__group {
    border: 1px solid $color-border-weak;
    padding: $unit-m;
    margin-top: -$unit-s;
    
    @include breakpoint(small) {
      //  Overflow hidden is set so we can "hide" scrollbars that will
      //  show up in cases when we have more than two reports.
      overflow: hidden;
    }
  }
  
  .c-message {
    margin-bottom: $unit-s;
  }
}


//  =Modern fields

.c-field--modern {
  
  //  ---------------
  //  Definitions
  
  $label: '[class*="__label"]';
  $description: '[class*="__description"]';
  $background-color: mix($color-primary-light, $color-white, 50%);
  $border-color: $color-primary-light;
  
  padding: 1rem;
  border-radius: $border-radius-small;
  margin: 0;
  background: $background-color;
  display: grid;
  grid-gap: $unit-xxs;
  
  // * {
  //   margin: 0;
  //   padding: 0;
  // }
  
  #{$label} {
    font-size: 1.0625rem;
  }
  
  [class*="__description"] {
    // font-size: 1rem;
  }
  
  
  @include breakpoint(large) {
    padding: 1.5rem 2rem;
  }
  
  
  //  Size: Large
  &.c-field--large {
    
    #{$label} {
      font-size: 1.25rem;
    }
  }

  //  Style: with border only
  &.c-field--border {
    border: 1px solid $border-color;
    background: none;
  }
  
  .c-field--action {
    margin-top: 1rem;
  }
  
  //  Various
  .o-select-group + .o-select-group {
    margin-top: $unit-xs;
  }

}