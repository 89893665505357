/*
 * Header
 *
 * Visible on all pages
 */

.c-header {

  position: relative;
  z-index: $z-index-header;

  background-color: $color-primary-dark;

}