/*
 * Poll add
 *
 * Add questions and facts
 */

.c-poll-add {

  padding: 1rem;

  background-color: #fff;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-extra-large;

  @include breakpoint(medium) {

    padding: 2rem;

  }

}

.c-poll-add__items {}

.c-poll-add__submenu {}

.c-poll-add__label {

  display: flex;
  flex-flow: row wrap;
  align-items: center;

  margin-bottom: .5rem;

  font-family: $font-body;
  font-size: 1rem;
  line-height: 1.5;
  color: $color-text-weak;
  font-weight: $semibold;

  .o-icon {

    margin-left: .5rem;
    margin-right: .5rem;

    color: $color-text-weak;
    font-size: 1.25rem;

  }

}
.c-poll-add__back {

  border: none;
  margin: 0;
  padding: 0;

  font-family: $font-body;
  font-size: 1rem;
  line-height: 1.5;
  color: $color-text;
  font-weight: $semibold;
  background: transparent;

  cursor: pointer;
  transition: $transition-default;

  &:hover,
  &:focus {

    color: $color-text-strong;

  }

}

.c-poll-add__active {


}