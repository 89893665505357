/*
 * Result choice
 *
 * Choice result has a collection of different values:
 * - title
 * - votes
 * - image
 * - bar
 * - bar comparison
 */

$result-choice-media: 4rem;
$result-choice-percent: 5rem;

.c-result-choice {

  display: flex;
  align-items: center;
  margin-top: $unit-xs;

  &:first-child {

    margin-top: 0;

  }
  
  body.l-print .c-result-annotate--image + .c-result-group & {
    
    margin-top: 0;

  }
  

}

.c-result-choice--no-media {
  align-items: stretch;
}

.c-result-choice--no-title {
  
  .c-result-choice__content {
    position: relative;
    padding: rem(24) 0 rem(10);
  }
  
  &.c-result-choice--compare .c-result-choice__content {
    padding: rem(9) 0;
  }
  
  .c-result-choice__bar-filled {
    // height: rem(12);
  }

  .c-result-choice__votes {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.c-result-choice__label {
  
  //  Used to show a value, instead of an image
  //  (i.e. like when using rating report)
  
  width: rem(60);
  background: $color-border-weak;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: rem(5);
  margin-left: 0;
  margin-right: $unit-s;
  border-radius: $border-radius-small;
  font-weight: $bold;
}

.c-result-choice__media {

  flex: 1 0 auto;

  margin-top: .125rem;
  margin-bottom: .125rem;
  margin-right: $unit-s;

  img {

    height: $result-choice-media;
    margin: 0;

    border-radius: $border-radius-small;

  }

}

.c-result-choice__content {

  width: 100%;
  display: flex;
  flex-flow: row wrap;

}

.c-result-choice__title {

  margin-right: $unit-xs;
  // margin-bottom: .25rem;

  font-size: 1rem;
  line-height: 1.5;
  font-weight: $semibold;

  @include breakpoint(medium) {

    font-size: 1.125rem;
    line-height: 1.5555555556;

  }

}

.c-result-choice__icon {

  margin-right: $unit-xs;
  display: flex;
  align-items: center;

  .o-icon {

    font-size: 1.25rem;

  }

}

.c-result-choice__votes {

  margin-left: auto;
  padding-top: .25rem;
  padding-bottom: .25rem;

  font-size: .875rem;
  line-height: 1.4285714286;
  color: $color-text-weak;

}

.c-result-choice__value,
.c-result-choice__compare {

  position: relative;

  display: flex;
  align-items: center;
  width: 100%;
  min-height: 1.5rem;
  padding-right: $result-choice-percent;

}

.c-result-choice__compare {

  display: none;

}

.c-result-choice__percent {

  // flex: 1 0 $result-choice-percent;
  width: $result-choice-percent;

  font-size: 1rem;
  font-weight: $semibold;
  text-align: right;
  color: darken($color-accent-green, 10%);

  position: absolute;
  right: 0;
  bottom: 0;

  // font-feature-settings: "tnum";
  // font-variant-numeric: tabular-numbs;

  .c-result-choice__compare & {

    top: 0;
    bottom: auto;
    color: darken($color-accent-red, 10%);

  }

  @include breakpoint(small) {

    font-size: 1.125rem;
    line-height: 1.3333333333;

  }

  @include breakpoint(medium) {

    font-size: 1.25rem;
    line-height: 1.2;

  }

}

.c-result-choice__bar {

  width: 100%;
  margin: .0625rem 0;

  // Previously used box-shadow inset, but sicne
  // snapshot functionality doesn't render box-shadow
  // correctly, we moved to border-bottom for
  // consistency purposes.
  border-bottom: .0625rem solid $color-primary-light;

}

.c-result-choice__bar-filled {

  height: .75rem;

  background-color: $color-accent-green;

  -webkit-print-color-adjust: exact;

  .c-result-choice__compare & {

    background-color: $color-accent-red;

  }

}

.c-result-choice.is-highlight {

  .c-result-choice__media img,
  .c-result-choice__label {

    box-shadow: 0 0 0 .25rem $color-accent-green;

  }

}

.c-result-choice.is-highlight-compared {}

/*
 * Compared active
 */

.c-result-choice--compare {

  .c-result-choice__votes {

    opacity: 0;

  }

  .c-result-choice__value {

      margin-top: -.5rem;

  }

  .c-result-choice__bar {

    margin-bottom: .0625rem;
    margin-top: auto;

    border-bottom: none;

  }

  .c-result-choice__bar-filled {

    height: .5rem;

  }

  .c-result-choice__compare {

    display: flex;
    // margin-top: 0;

    .c-result-choice__bar {

      margin-top: .0625rem;
      margin-bottom: auto;
      padding-bottom: .5rem;

      border-bottom: .0625rem solid $color-primary-light;

    }

    .c-result-choice__percent {

      font-size: 1.125rem;

    }

  }

}


/*
 * Toggle result: '.o-checkbox'
 */

.c-result-choice__toggle {

  display: flex;
  margin-right: $unit-s;

  label {

    padding-left: 1.75rem;
    width: 1.75rem;

    @include hide-text();

  }

  // Fix for HTML2Canvas reports and the extra
  // div we add around the inline SVG icon
  .o-icon--inline {

    .o-icon {

      display: block;

    }

  }

}

/*
 * Modifiers
 */

.c-result-choice--fade {

  .c-result-choice__percent,
  .c-result-choice__votes,
  .c-result-choice__title {

    color: $color-text-weak;
    opacity: .5;

  }

  .c-result-choice__bar-filled {

    background-color: $color-primary-light;

  }

  .c-result-choice__media {

    opacity: .5;

  }

}

.c-result-choice--deleted,
.c-result-choice--missing {
  
  .c-result-choice__media,
  > .o-checkbox,
  .c-result-choice__votes,
  .c-result-choice__value,
  .o-tag {
    filter: grayscale(1);
    opacity: .25;
  }
  
  .c-result-choice__title {
    display: flex;
    align-items: center;
    grid-gap: $unit-xxs;
  }
}
