/*
 * Question: demographic
 */

.c-question-demographic {

  display: flex;
  flex-flow: column;

}

.c-question-demographic__image {

  margin-bottom: 2rem;

  img {

    margin: 0;
    width: 100%;

    border-radius: $border-radius-small;

  }

}

.c-question-demographic__items {

  list-style: none;
  margin: 0;
  padding: 0;

}

.c-question-demographic__item {

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 0;
  padding-top: .5rem;
  // border-bottom: .0625rem solid $color-border-weak;

  font-size: 1rem;
  line-height: 1.5;
  box-shadow: inset 0 -.0625rem 0 0 $color-border-weak;

  @include breakpoint(extra-small) {

    font-size: 1.125rem;

  }

}

.c-question-demographic__label {

  margin-right: $unit-xs;

  .o-icon {

    margin-right: $unit-xs;

  }

}

.c-question-demographic__bar {

  width: 100%;
  margin-top: $unit-xs;

}

.c-question-demographic__score {

  height: .375rem;
  height: .5rem;

  background-color: $color-success;
  -webkit-print-color-adjust: exact;

  transition: all .1s ease-in;

  &.c-question-demographic__score--compare {

    display: none;
    margin-top: .125rem;

    background-color: $color-accent-green;
    background-color: $color-accent-red;

  }

}

.c-question-demographic__percent {

  margin-left: $unit-xs;
  margin-left: auto;

  color: $color-success;
  font-weight: $bold;

}

.c-question-demographic__percent--compare {

  display: none;
  margin-left: $unit-xs;

  color: $color-accent-red;

}

.c-question-demographic__votes {

  margin-left: $unit-xs;

  font-size: .875rem;
  color: $color-text-weak;

}

/*
 * Modifier
 */

.c-question--library {

  .c-question-demographic__label {

    @include hide-visually();

  }

  .c-question-demographic__items {}

  .c-question-demographic__item {

    margin-top: $unit-xs;
    padding: .5rem 1rem;

    font-size: 1rem;
    line-height: 1.5;

    background-color: $color-grey-x-light;
    border-radius: $border-radius-small;

    &:before {

      display: none;

    }

    &:first-child {

      margin-top: 0;

    }

  }

}


/*
 * Compare
 */

.c-question--compare {

  .c-question-demographic__percent--compare,
  .c-question-demographic__score--compare {

    display: block;

  }

}