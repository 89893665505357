/*
 * Messages
 *
 * Messages can include:
 * - title
 * - desription
 * - icon
 */

.c-message {

  position: relative;

  padding: .75rem 1rem;
  margin-top: $unit-m;
  margin-bottom: $unit-m;

  background-color: $color-grey-x-light;
  border-radius: $border-radius-small;
  -webkit-print-color-adjust: exact;
  
  &.is-hidden {
    display: none;
  }
  
  .c-fields--modern > & {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
  }
  
  .c-fields--modern .c-field__group > &:first-child {
    margin-bottom: $unit-xs;
  }
  
  .c-fields--modern .c-field__group > &:nth-child(2):last-child {
    margin-top: $unit-xs;
  }
  
  .c-field--modern .o-checkbox + & {
    margin-top: $unit-xs;
  }

  &:first-child {

    margin-top: 0;

  }

  &:last-child {

    margin-bottom: 0;

  }

  .o-icon {

    position: absolute;

    top: .875rem;
    left: 1rem;
    width: 1.25rem;

    font-size: 1.25rem

  }

}

.c-message + .c-message {

  margin-top: 0 - $unit-s;

}

.c-fields--modern > .c-message {
  margin-top: 0 !important;
}

.o-checkbox + .c-message {
  margin-top: 0;
}

.c-message__title {

  margin-bottom: 0;

  font-size: 1rem;
  line-height: 1.5;
  font-family: $font-body;
  font-weight: $semibold;
  letter-spacing: 0;

}

.c-message__description {

  margin-bottom: 0;

  font-size: .875rem;
  // line-height: 1.4285714286rem;
  line-height: 1.2857142857rem;

  a {

    text-decoration: underline;

  }
  
  .o-icon {
    position: relative;
    top: 0;
    left: 0;
  }
  
  .o-icon--small {
    font-size: rem(16) !important;
    color: $color-black !important;
    margin: rem(-2) 0 !important;
  }

}

/*
 * Modifiers
 */

.c-message--icon {

  padding-left: 3rem;

}

/*
 * Dismissable & Close
 */

.c-message__close {
  position: absolute;
  top: 0;
  right: 0;
  width: rem(32);
  height: rem(32);
  top: rem(8);
  right: rem(8);
  
  .o-icon {
    color: black !important;
    font-size: rem(20) !important;
    margin: 0 !important;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
  
  .o-label {
    @include hide-visually();
  }
}

.c-message.is-dismissable {
  
}

/*
 * States
 */

.c-message.is-success {

  background-color: $color-success-tint;

  .o-icon {

    color: $color-success;

  }

}

.c-message.is-warning {

  background-color: $color-warning-tint;

  .o-icon {

    color: $color-warning;

  }

}

.c-message.is-naked {
  background: none;
  padding: 0;
  padding-left: 2rem;
  
  .o-icon {
    top: 0;
    left: 0;
  }
}

.c-message.is-border {
  background: none;
  border: 1px solid $color-border-normal;
  
  .o-icon {
    top: 0;
    left: 0;
  }
}

.c-message.is-note {

  background-color: $color-note;

  .o-icon {

    color: $color-note-tint;
  }
}

.c-message.is-note-subtle {

  background-color: $color-note-subtle;

  .o-icon {

    color: $color-note-tint;
  }
}

.c-message.is-white {

  background-color: $color-white;

  .o-icon {

    color: $color-primary-dark;
  }
}

.c-message.is-warning-strong {

  background-color: $color-warning;

  .o-icon {

    color: $color-primary-dark;
  }
}

.c-message.is-positive {

  background-color: $color-success;
  
  * {
    color: $color-white;
  }

  .o-icon {

    color: $color-white;
  }
}


.c-message.is-information {

  background-color: $color-information-tint;

  .o-icon {

    color: $color-information;

  }

}

.c-message.is-danger {

  background-color: $color-danger-tint;

  .o-icon {

    color: $color-danger;

  }

}

.c-message.is-danger-strong {
  background-color: $color-danger;
  
  * {
    color: $color-danger-tint;
  }
  
  .c-message__title,
  strong {
    color: $color-white;
  }
  
  .o-icon {
    color: $color-danger;
  }
}

.c-message--highlight {

  padding: $unit-l $unit-m;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  .o-icon {

    position: relative;
    top: auto;
    left: auto;

    width: 2rem;
    margin-bottom: $unit-xs;

    font-size: 2rem

  }

  .c-message__title {

    margin-bottom: $unit-xs;

    font-size: 1.375rem;
    line-height: 1.5;

  }

  .c-message__description {

    font-size: 1.125rem;
    line-height: 1.5;

    max-width: 60ch;
    
    .o-icon {
      width: 1.25rem;
    }

  }

}

.c-message--large {
  padding: $unit-s $unit-m;
  
  .c-message__title {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  
  .c-message__description {
    font-size: 1rem;
    line-height: 1.5;
  }
  
}

/*
 * Messages holder
 */

.c-messages-list {

  margin-bottom: $unit-m;

  &:last-child {

    margin-bottom: 0;

  }

}

.c-messages-list--fixed {

  position: fixed;
  z-index: $z-index-modal;
  top: 0;
  right: 0;

  @include breakpoint(to-medium) {

    left: 0;

    .c-message {

      margin-top: 0;
      margin-bottom: 0;

      border-radius: 0;

    }

  }

  @include breakpoint(medium) {

    padding: $unit-m;
    max-width: 24rem;

  }

}