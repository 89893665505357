/*
 * Report overview
 *
 * Report overview is used to preview important
 * stats at a global level on the reports page
 */

.c-report-overview {

  position: relative;

  margin-bottom: $unit-s;

  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-large;

  &:last-child {

    margin-bottom: 0;

  }

}

.c-report-overview__toggle {

  position: absolute;
  z-index: $z-index-main;
  top: 1rem;
  right: 1rem;

  @include breakpoint(medium) {

    top: $unit-m;
    right: $unit-l;

  }

}

.c-report-overview__snapshot {

  /* Wrapper for elements that should be
     included in the screenshot. */

}

$report-overview-logo-size: 3rem;

.c-report-overview__header {

  position: relative;

  display: flex;
  flex-direction: column;

  padding: 1rem;
  padding-top: $unit-s;
  padding-bottom: $unit-s;
  padding-left: 1rem + $report-overview-logo-size + $unit-xs;
  margin-bottom: -1rem;

  background: assets-path('images/bid-gmv-symbol.svg') no-repeat 1rem 50%;
  background-size: $report-overview-logo-size $report-overview-logo-size;

  @include breakpoint(medium) {

    padding: 2rem;
    padding-top: $unit-m;
    padding-bottom: $unit-m;
    padding-left: 2rem + $report-overview-logo-size + $unit-xs;
    margin-bottom: 0 - $unit-m;

    background-position-x: 2rem;

  }

}

.c-report-overview__title {

  margin-top: 0;
  margin-bottom: 0;
  margin-right: 8rem;

  font-size: 1.25rem;

  @include breakpoint(medium) {

    font-size: 1.5rem;

  }

}

.c-report-overview__date {

  margin-top: 0;
  margin-bottom: 0;

}

.c-report-overview__body {

  position: relative;
  z-index: $z-index-switchable;

  padding: 1rem;

  border-radius: 0 0 $border-radius-small $border-radius-small;

  @include breakpoint(medium) {

    padding: 2rem;

  }
  
  //  Functionality to show/hide component body content
  //   (used with .c-toggle-button component)
  &.is-open,
  &.is-closed {
    
    .c-heading-section {
      position: relative;
    }
  }
  
  &.is-closed > :not(h2) {
    display: none;
  }
  
  &.is-closed h2 {
    margin-bottom: 0;
  }
  
  &.is-open > :not(h2) {
    display: block;
  }
  
  .c-toggle-button {
    width: rem(56);
    position: absolute;
    top: 50%;
    right: rem(-18);
    transform: translateY(-50%);
    background: #fff;
  }

}