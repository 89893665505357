/*
 * Poll choices
 *
 * Questions and facts list
 */

.c-poll-choice {}

.c-poll-choice__items {

  position: relative;

  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: -1rem;
  margin-right: -1rem;

  flex: 1 1 100%;
  overflow: hidden;

  @include breakpoint(medium) {

    margin-left: -2rem;
    margin-right: -2rem;

    //  The following was updated to 680px to make room for 
    //  question note on the second level of this component.
    min-height: 680px;
    //min-height: 480px;

  }

}

.c-poll-choice__item {

  margin-top: 0;

}

.c-poll-choice__item {

  input[type="radio"] {

    position: absolute;

    opacity: 0;

  }

  label {

    display: grid;
    grid-template-columns: 2.25rem 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: $unit-xs;
    align-items: center;

    padding: .75rem 1rem;

    font-size: 1rem;
    line-height: 1.5;
    color: $color-text;

    cursor: pointer;
    transition: all .1s ease-in;

    .o-icon {

      // float: left;
      // grid-row: -1 / 1;
      grid-row: span 2;
      margin-left: auto;
      margin-right: auto;

      // position: absolute;
      // top: 50%;
      // left: 1rem;
      // transform: translateY(-50%);

      // width: 1.25rem;
      // margin-right: .5rem;

      // font-size: 1.25rem;
      font-size: 1.5rem;
      color: $color-text;

    }

    @include breakpoint(medium) {

      padding-left: 2rem;
      padding-right: 2rem;

    }

  }

  input[type="radio"]:hover + label,
  input[type="radio"]:checked + label {

    background-color: $color-link-tint;


  }

  input[type="radio"]:disabled + label {

    pointer-events: none;

    opacity: .3;

  }

}

.c-poll-choice__title {

  margin-right: .5rem;

  font-size: 1rem;
  line-height: 1.25;
  color: $color-link;
  font-weight: $semibold;
  white-space: nowrap;

}

.c-poll-choice__count {

  position: relative;
  top: -.125rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.25rem;
  min-height: 1.25rem;
  margin-left: .25rem;


  font-size: .75rem;
  border-radius: 1rem;
  text-align: center;
  background-color: rgba($color-primary-dark, .1);

}

.c-poll-choice__description {

  color: $color-text-weak;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-size: .875rem;
  line-height: 1.428571428571429; // 20 / 14

  @include breakpoint(to-large) {

    @include hide-visually;

  }

}

.c-poll-choice__image {

  display: none;

}

/*
 * Desktop
 */

@include breakpoint(large) {

  .c-poll-choice__image {

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    width: 18rem;
    padding: 0 1rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column;
    border-left: 1px solid $color-border-weak;

    background-color: #fff;

    opacity: 0;

    transition: all .1s ease-in;

    img {

      // height: 100%;
      max-height: 100%;
      max-width: 15rem;
      margin: 0;

      border-radius: $border-radius-medium;
      box-shadow: $box-shadow-medium;
      pointer-events: none;

    }

    p {

      margin-top: .75rem;
      margin-bottom: 0;

      font-size: .875rem;
      line-height: 1.4285714286;
      text-align: center;
      color: $color-text-weak;

      &:first-child {

        margin-top: 0;

      }

    }
    
    &:has(.c-poll-choice__note) {
      grid-gap: $unit-s;
      flex-direction: column-reverse;
      
      img {
        margin-bottom: auto;
      }
    }
    
    .c-poll-choice__note {
      background: $color-note-subtle !important;
      border-radius: $border-radius-small;
      padding: $unit-xs $unit-s;
      margin: 0;
      max-width: 15rem;
      color: $color-text;
      font-family: $font-stack-system !important;
      line-height: 1.5 !important;
      text-align: left;
      
      max-height: rem(216);
      overflow-y: scroll;
      
      p {
        text-align: left;
      }
      
      * {
        background: none;
        margin: 0;
        padding: 0;
      }
      
      strong {
        color: $color-black;
      }
    }

  }

  .c-poll-choice__item {

    input[type="radio"]:hover + label .c-poll-choice__image,
    input[type="radio"]:checked + label .c-poll-choice__image {

      opacity: 1;

    }

    input[type="radio"]:hover + label .c-poll-choice__image {

      z-index: $z-index-modal;

    }

  }

}


/*
 * Options
 */

.c-poll-choice__options {

  display: none;

  padding: 0 1rem;

  @include breakpoint(medium) {

    padding: 0 2rem;

  }

  .o-radio label {

    padding-left: 2.25rem;
    padding-right: 0;
    background-color: transparent!important;

  }

  .o-radio input[type="radio"]:checked label {

    // background-color: transparent;

  }

}

.c-poll-choice__item input[type="radio"]:checked + label + .c-poll-choice__options {

  display: block;

}