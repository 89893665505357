/* =Forms
===================================================== */

/*
 * Fieldset
 */

fieldset {

  border: none;
  margin: 0;
  padding: 0;

}

/*
 * Inputs
 */

#{$all-text-inputs} {

  /* Positioning */

  /* Box-model */
  display: block;
  width: 100%;
  // padding: .625rem 1rem;
  padding: .75rem 1rem;
  margin: 0;
  box-shadow: inset 0 0 0 .0625rem $color-input-regular;
  border: none;
  // border: .125rem solid $color-input-regular;

  /* Typography */
  font-family: $font-body;
  font-size: 1rem;
  line-height: 1.5;
  color: $color-text-strong;

  /* Visual */
  background-color: #fff;
  border-radius: $border-radius-small;

  /* Misc */
  transition: all .1s ease-in;
  appearance: none;

  @include breakpoint(small) {

    font-size: 1.125rem;
    line-height: 1.3333333333; // 24/18

  }

  &:disabled {

    // background-color: $color-grey-x-light;
    background-color: $color-link-tint;
    box-shadow: none;

    pointer-events: none;

  }

}

#{$all-text-inputs-focus},
#{$all-text-inputs-hover} {

  outline: none;
  color: $color-text-strong;
  box-shadow: inset 0 0 0 .125rem $color-input-focus;

  &:disabled {

    color: $color-text;
    background-color: $color-grey-x-light;
    box-shadow: none;


  }

}

#{$all-text-inputs-active} {

}


/*
 * Textarea
 */

textarea {

  resize: vertical;
  min-height: 8rem;
  display: block;

}

/*
 * Input type: range
 */

/*
 * Select
 */

select {

  /* Box-model */
  display: block;
  height: 3rem;
  width: 100%;
  padding: .625rem 1rem;
  margin: 0;
  border: none;
  // border: .125rem solid $color-input-regular;
  box-shadow: inset 0 0 0 .125rem $color-input-regular;

  /* Typography */
  font-family: $font-body;
  font-size: 1rem;
  line-height: 1.5;
  color: $color-text;

  /* Visual */
  background-color: #fff;
  border-radius: $border-radius-small;

  /* Misc */
  cursor: pointer;

  @include breakpoint(small) {

    font-size: 1.125rem;
    line-height: 1.3333333333;

  }

}

/*
 * Placeholder
 */

::-webkit-input-placeholder { /* Chrome/Opera/Safari */

  color: $color-text-weak;

}
::-moz-placeholder { /* Firefox 19+ */

  color: $color-text-weak;

}
:-ms-input-placeholder { /* IE 10+ */

  color: $color-text-weak;

}
:-moz-placeholder { /* Firefox 18- */

  color: $color-text-weak;

}

/*
 * Editable content
 *
 * Simple style to indicate this field is editable
 */

[contenteditable="true"] {

  &:hover {

    background-color: $color-primary-light;

  }

  &:focus {

    outline: none;

  }

}