/*
 * Tooltip
 *
 * Different variations of the tooltip
 */

// +tooltip('o-tooltip--bottom-center', null, 'info-circle', 'Data from Google Analytics')

.o-tooltip {

  position: relative;

  &:first-child {

    margin-right: .25rem;

  }

  &:last-child {

    margin-left: .25rem;

  }

  &:only-child {

    margin-left: 0;
    margin-right: 0;

  }

}

.o-tooltip__action {

  color: $color-text-weak;

  transition: all .15s ease-in;

  .o-label {

    font-weight: $regular;

  }

  .o-icon {

    position: relative;
    top: .125rem;
    margin-left: .25rem;

  }

  .o-icon:first-child {

    margin-left: 0;

  }

}

.o-tooltip__description {

  &:before {

    position: absolute;
    border: .25rem solid transparent;

    content: "";

  }

  opacity: 0;

  position: absolute;
  z-index: $z-index-tooltip;

  padding: .75rem;

  max-width: 24rem;
  min-width: 12rem;

  font-size: .75rem;
  line-height: 1.5;
  font-weight: $regular;
  color: $color-text;
  background-color: $color-primary-light;
  border-radius: $border-radius-small;
  white-space: normal;

  pointer-events: none;
  transition: all .1s ease-in;
  
  img {
    margin: $unit-xs auto 0;
  }

  @include breakpoint(medium) {

    // min-width: 16rem;

  }

  .multiple-images {
    
    //  This is added to show multiple inline images when we're using tooltip
    //  as assisted translation for carousels.
    display: grid;
    min-width: 22rem;
    grid-template-columns: 1fr 1fr;
    grid-gap: $unit-xs;
    margin-top: $unit-xs;
    
    img {
      margin: 0;
    }
  }

}

.o-tooltip__action:hover,
.o-tooltip__action:focus {

  color: $color-text-strong;

}

.o-tooltip__action:hover + .o-tooltip__description,
.o-tooltip__action:focus + .o-tooltip__description {

  opacity: 1;

}

/*
 * Modifiers
 */

.o-tooltip--s {

  .o-tooltip__action {

    vertical-align: middle;
    font-size: 75%;
    color: $color-text-weak;
    font-weight: $regular;
    letter-spacing: .0125em;

  }

}

.o-tooltip--assisted-translation,
.o-tooltip--dark {
  
  .o-tooltip__description {
    background-color: transparent-color($color-black, .85);
    color: $color-white;
  }
  
  &.o-tooltip--top-center .o-tooltip__description:before {
    border-top-color: transparent-color($color-black, .85);
  }
  
  &.o-tooltip--bottom-center .o-tooltip__description:before {
    border-bottom-color: transparent-color($color-black, .85);
  }
  
  &.o-tooltip--left-center .o-tooltip__description:before {
    border-right-color: transparent-color($color-black, .85);
  }
}

/*
 * Tooltip location
 *
 * Where will the description show
 */

.o-tooltip--bottom-center {

  .o-tooltip__description {

    margin-top: .5rem;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);

    text-align: center;

  }

  .o-tooltip__description:before {

    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-color: $color-primary-light;

  }

  &.o-tooltip--media .o-tooltip__description:before {

    border-bottom-color: #fff;

  }

  &.o-tooltip--marker .o-tooltip__description:before {

    border-bottom-color: #fff;

  }

}

.o-tooltip--left-center {

  .o-tooltip__description {

    margin-left: .5rem;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);

    text-align: left;

  }

  .o-tooltip__description:before {

    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-right-color: $color-primary-light;

  }

  &.o-tooltip--media .o-tooltip__description:before {

    border-right-color: #fff;

  }

  &.o-tooltip--marker .o-tooltip__description:before {

    border-right-color: #fff;

  }

}

.o-tooltip--top-center {

  .o-tooltip__description {

    margin-bottom: .5rem;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);

    text-align: center;

  }

  .o-tooltip__description:before {

    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-top-color: $color-primary-light;

  }

  &.o-tooltip--media .o-tooltip__description:before {

    border-top-color: #fff;

  }

  &.o-tooltip--marker .o-tooltip__description:before {

    border-top-color: #fff;

  }

}

/*
 * Media
 *
 * If the tooltip has an image, treat it a little differently.
 */

.o-tooltip--media {

  .o-tooltip__description {

    background-color: #fff;
    color: $color-text;
    box-shadow: $box-shadow-large;
    min-width: 16rem;

    img {

      display: block;
      width: 100%;
      border: .0625rem solid $color-primary-light;
      margin-bottom: $unit-xs;

    }

    @include breakpoint(large) {

      min-width: 24rem;

    }

  }

}

/*
 * Button
 */

.o-tooltip--button {

  &:focus,
  &:active,
  &:hover, {

    .o-tooltip__description {

      opacity: 1;

    }

  }

}

/*
 * Marker
 */

.o-tooltip--marker {

  .o-tooltip__action {

    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;

    text-align: center;
    font-size: 1.25rem;
    border-radius: 50%;

    .o-icon {

      top: 0;

    }

  }

  .o-tooltip__action:hover,
  .o-tooltip__action:focus {

    color: $color-text-strong;
    background-color: $color-accent-green;

  }

  .o-tooltip__description {

    background-color: #fff;
    box-shadow: $box-shadow-large;

  }

  &.o-tooltip--avatar {

    .o-tooltip__description {

      min-width: 16rem;
      min-height: 5rem;
      padding-left: 5rem;
      display: flex;
      flex-flow: column;
      justify-content: center;

      text-align: left;

      img {

        position: absolute;
        top: .75rem;
        left: .75rem;

        width: 3.5rem;
        height: 3.5rem;
        margin-bottom: 0;
        margin-right: $unit-xs;

        border-radius: $border-radius-small;

      }

    }

  }

}

/*
 * States
 */

.o-tooltip--danger {

  .o-tooltip__action {

    color: $color-danger;

  }

}

.o-tooltip--success {

  .o-tooltip__action {

    color: $color-success;

  }

}