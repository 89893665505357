/*
 * Style guide
 *
 * Style guide elements, links, components
 */
 
.l-styleguide {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: $unit-xl;
  max-width: $container-max-width;
  margin: $unit-xl*2 auto;
  
  .l-main {
    background: $color-white;
    border-radius: $border-radius-medium;
    padding: $unit-xl;
  }
  
  .l-main > h1,
  .l-main > h2,
  .l-main > h3 {
    margin: $unit-m 0;
  }
  
  // h1, h2, h3, table, ul, ol {
  //   margin: $unit-m 0;
  // }
  // 
  // h1, h2, h3 {
  //   margin-top: $unit-xl;
  // }
  // 
  // a {
  //   text-decoration: underline;
  // }
}
 
.c-sg-pagetitle {
  border-bottom: 1px solid $color-border-weak;
  margin: (-$unit-xl) (-$unit-xl) $unit-xl (-$unit-xl) !important;
  padding: $unit-m $unit-xl;
}
 
.c-sg-table {
   // margin-left: -$unit-xl !important;
   // margin-right: -$unit-xl !important;
   // width: calc(100% + 96px);
   border-right: 1px solid $color-border-normal;
   // box-shadow: 0 5px 12px transparent-color(#000, .1);
   // border-radius: $border-radius-medium;
   // overflow: hidden;
   
  .col-50 { width: 50% }
  
  th, td {
    vertical-align: top;
    border-left: 1px solid $color-border-normal !important;
    border-bottom: 1px solid $color-border-normal;
    padding: rem(10) rem(16);
  
  &:first-child {
     // padding-left: $unit-xl;
     border-left: 0;
  }
  // 
  // &:last-child {
  //   padding-right: $unit-xl;
  // }
  }
  
  thead {
  
    th {
      border-top: 1px solid $color-border-normal;
      font-size: rem(12);
      text-transform: uppercase;
      background: $color-border-weak;
    }
  }
  
  tbody {
  
  
    th {
      font-weight: $regular;
    }
  }
}
 
.c-styleguide-navigation {
  @include list-reset();
  display: flex;
  flex-direction: column;
  
  li {
    display: block;
    margin: rem(2) 0;
  }
  
  a {
    display: block;
    padding: rem(8) rem(16);
    border-radius: $border-radius-medium;
    text-decoration: none;
  }
  
  .is-active a {
    background: $color-white;
  }
}
 
.c-sg-icons {
  @include list-reset();
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: $unit-s;
  
  li {
    border: 1px solid $color-border-weak;
    text-align: center;
    padding: $unit-m;
    margin: 0;
  
  span {
    display: block;
    margin-top: $unit-xs;
    font-size: rem(14);
    }
  }
  
  svg {
    width: rem(40);
    height: rem(40);
  }
}

.c-sg-links {
  list-style: none;
  margin: 0;
  padding: 0;

  margin-bottom: $unit-m;

  ul {

    list-style: none;
    margin: 0;
    padding: 0;

  }

  li {

    display: block;
    margin-top: 0;
    padding: .5rem 0 .25rem;

    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: $regular;

  }

  li li {

    padding: .125rem 0 .125rem $unit-m;

    font-size: 1rem;
    line-height: 1.5;
    font-weight: $regular;

  }

  li ul {

    margin-top: $unit-xs;

  }

}

.c-sg-component-container {
  
  &.has-background {
    $sg-component-patter-color: #eee;
    background-image: repeating-linear-gradient(45deg, $sg-component-patter-color 0, $sg-component-patter-color 1px, transparent 0, transparent 50%);
    background-size: 10px 10px;
    background-color: $color-white;
    padding: $unit-m;
  }
}