/*
 * Result group
 *
 * Grouping different results together in a question
 */

.c-result-group {

}

.c-result-group__items {

  list-style: none;
  margin: 0;
  padding: 0;

  margin-bottom: $unit-s;

  &:last-child {

    margin-bottom: 0;

  }

}

.c-result-group__meta {

  list-style: none;
  margin: 0;
  padding: 0;

  margin-top: $unit-s;
  margin-bottom: $unit-s;

  &:first-child {

    margin-top: 0;

  }

  &:last-child {

    margin-bottom: 0;

  }

  li {

    color: $color-text-strong;
    font-size: 1rem;
    line-height: 1.5;

  }

  @include breakpoint(small) {

    display: flex;
    flex-flow: row wrap;

    li {

      margin-top: 0;
      margin-right: $unit-xs;
      padding-right: $unit-xs;
      border-right: .0625rem solid $color-border-normal;

      &:last-child {

        border-right: none;

      }

    }

  }

}

.c-result-group__label {
  padding: .5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: $border-radius-small;
}

.c-result-group__label--top {
  margin-bottom: .5rem;
}

.c-result-group__label--bottom {
  margin-top: .5rem;
}

.c-result-group__label--positive {
  color: $color-success;
  background-color: $color-success-tint;
}

.c-result-group__label--negative {
  color: $color-danger;
  background-color: $color-danger-tint;
}

/*
 * Columns
 *
 * This modifier is used to show items in two columns.
 * This is useful for PDF reports when you want to fit
 * more 10+ choices on one A4 page. For online reports,
 * it's better to show all choices in one column.
 */

.c-result-group__items.c-result-group__items--columns {

  display: grid;
  grid-row-gap: $unit-s;
  grid-column-gap: $unit-m;
  grid-template-columns: 1fr 1fr;

  .c-result-choice {

    margin-top: 0;

  }

}