/*
 * Text teaser
 *
 * Basic text information presented in a list
 */

.c-text-teaser {

  margin-bottom: 1.5rem;

  &:last-child {

    margin-bottom: 0;

  }

}

.c-text-teaser__title {

  margin-bottom: .25rem;

  font-size: 1.25rem;
  line-height: 1.4;

  @include breakpoint(small) {

    // font-size: 1.5rem;
    // line-height: 1.3333333333;

  }

}

.c-text-teaser__description {

  margin-bottom: .25rem;

  font-size: 1rem;
  line-height: 1.5;

  color: $color-text-weak;

  word-break: break-all;

}

.c-text-teaser__meta {

  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;

  li {

    display: flex;
    flex-flow: row wrap;
    align-items: center;

    margin-right: 1rem;

    font-size: 1rem;
    line-height: 1.5;
    color: $color-text;

  }

  .o-icon {

    margin-right: .5rem;

    font-size: 1.25rem;
    color: $color-text-weak;

  }

}

/*
 * Reorder modifier
 *
 * When poll teaser component is used as a reordering block
 * make it more compact used with `c-reorder` block.
 */


.c-text-teaser--reorder  {

  width: 100%;
  padding: $unit-s $unit-s;

  .c-text-teaser__title {

    font-size: 1.125rem;
    line-height: 1.4444444444;

  }

  .c-text-teaser__description,
  .c-text-teaser__meta li {

    font-size: .875rem;
    line-height: 1.4285714286;

  }

}