/*
 * Layout
 *
 * Main layout blocks and styles
 */
 

.l-container {

  position: relative;

  margin-left: auto;
  margin-right: auto;
  padding: 1rem;

  max-width: $container-max-width;

  @include breakpoint(medium) {

    padding: 1.5rem 1.5rem;

  }

  @include breakpoint(large) {

    padding: 3rem 2rem;

  }
  
  //  Help is visible
  @include breakpoint(extra-large) {
    
    &:has(.l-page--show-help),
    body:has(.l-page--show-help) & {
      max-width: calc($container-max-width + $contextual-help-width);
    }
  }

}

.l-header {

  .l-container {

    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 0;
    padding-bottom: 0;
    height: 4rem; // 64px

    @include breakpoint(large) {

      justify-content: flex-start;
      height: 6rem; // 96px

    }

  }
}

.l-footer {

  /*
   * Legacy fix
   *
   * Remove when there is no more `.section-main`
   * which uses `width: 100%` and `float: left;`
   */

  clear: both;

}

.l-body {

  background-color: #fff;

  &.l-body--transparent {

    background-color: transparent;

  }

}

/*
 * Modifiers
 *
 * Container modifiers for profile using CSS Grid
 * and enabling easy creation of grids for
 * full width or templates that have a sidebar
 */

.l-container--profile,
.l-container--profile-aside {

  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  @include breakpoint(medium) {

    grid-column-gap: 2rem;
    grid-row-gap: 2rem;

  }

}

.l-container--profile-aside {

  @include breakpoint(large) {

    grid-template-columns: 18rem auto;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;

  }

  .l-masthead {

    @include breakpoint(large) {

      grid-column-start: 1;
      grid-column-end: 3;

    }

  }

}

/*
 * Main grid
 *
 * 1) Masthead
 * 2) Aside (optional)
 * 3) Main content
 */

.l-page {

  display: grid;
  grid-gap: $unit-m;
  grid-template-rows: 1fr;

  @include breakpoint(large) {

    grid-gap: $unit-l;

  }

  &.l-page--aside {

    @include breakpoint(large) {

      grid-template-columns: 18rem 1fr;

    }

  }
  
  &.l-page--show-help {
    
    @include breakpoint(extra-large) {
      grid-template-columns: 18rem 1fr $contextual-help-width;
      grid-template-areas: 
      'masthead masthead masthead'
      'aside content help';
    }
  }

  .l-main,
  .l-masthead {

    grid-column: -1/1;
    min-width: 0; // https://css-tricks.com/preventing-a-grid-blowout/

  }

  .l-aside {

    grid-column: 1;

  }

  .l-aside + .l-main {

    @include breakpoint(large) {

      grid-column: 2;

    }

  }

}

/*
 * Grid
 *
 * Default grid elements
 */

.l-grid {

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

}

.l-grid--halfs,
.l-grid--thirds,
.l-grid--fourths {

  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

}

.l-grid--halfs {

  grid-template-columns: 1fr;

  @include breakpoint(small) {

    grid-template-columns: 1fr 1fr;

  }

}

.l-grid--thirds {

  grid-template-columns: 1fr;

  @include breakpoint(medium) {

    grid-template-columns: 1fr 1fr;

  }

  @include breakpoint(large) {

    grid-template-columns: 1fr 1fr 1fr;

  }

}

.l-grid--fourths {

  grid-template-columns: 1fr;

  @include breakpoint(small) {

    grid-template-columns: 1fr 1fr;

  }

  @include breakpoint(large) {

    grid-template-columns: 1fr 1fr 1fr 1fr;

  }

}

/*
 * Lists
 *
 * Default list columns
 */

.l-list {

  display: grid;
  grid-gap: $unit-s;

  @include breakpoint(medium) {

    grid-gap: $unit-m;

  }

}

.l-list--halfs {

  grid-template-columns: 1fr;

  @include breakpoint(medium) {

    grid-template-columns: 1fr 1fr;

  }

}