/* =Headings
===================================================== */

// Headings
//
// Heading examples
//
// Markup:
// <h1>Nikola Tesla — Heading 1</h1>
// <h2>War of Currents — Heading 2</h2>
// <h3>X-ray experimentation — Heading 3</h3>
// <h4>AC and the induction motor — Heading 4</h4>
//
// Style guide: 4.headings

h1,
h2,
h3,
h4 {

  margin-bottom: 1rem;
  margin-top: 0;

  font-family: $font-headline;
  font-weight: $semibold;
  line-height: 1.25;
  letter-spacing: -.025em;
  color: $color-text-strong;

}

h1 {

  font-size: 2rem;

}

h2 {

  font-size: 1.5rem;

}

h3 {

  font-size: 1.25rem;

}

h4 {

  font-size: 1.125rem;

}

h1, h2, h3, h4 {

  abbr {

    vertical-align: middle;
    font-size: 75%;
    color: $color-text-weak;
    font-weight: $regular;
    letter-spacing: .0125em;

  }

}