.c-footer-info {

  margin-top: .75rem;

  text-align: center;

  &:first-child {

    margin-top: 0;

  }

}

.c-footer-info__text {

  p {

    margin-bottom: 0;

    font-size: .75rem;
    line-height: 1.5;
    color: $color-text-weak;

    &:not(:first-child) {

      margin-top: .25rem;

    }

  }

  a {

    color: $color-text;

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong;

    }

  }

}

.c-footer-info__version {

  position: fixed;
  bottom: 0;
  right: 0;

  padding: $unit-xs $unit-s;

  font-size: .875rem;
  line-height: 1.5;
  color: $color-text-weak;
  transition: all .1s ease-in;
  opacity: 0;

  border-top-left-radius: $border-radius-medium;

  &:hover {

    opacity: 1;
    background-color: #fff;

  }

}

.c-footer-info__top {

  display: inline-block;
  margin-top: 1rem;

  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  color: $color-text;
  white-space: nowrap;

  .o-icon-arrow-up {

    margin-left: .25rem;
    display: inline-block;

    color: $color-link;
    vertical-align: middle;

    transition: .1s all ease-in;

  }

  &:hover,
  &:focus,
  &:active {

    color: $color-text-strong;

    .o-icon-arrow-up {

      transform: translateY(-.25rem);

    }

  }

}

@include breakpoint(large) {

  .c-footer-info {

    display: flex;

  }

  .c-footer-info__text {

    padding-left: 3.5rem;

    text-align: left;

    background: assets-path('images/bid-gmv-symbol.svg') no-repeat 0 50%;

    p {

      font-size: .875rem;

      &:not(:first-child) {

        margin-top: 0;

      }

    }

  }

  .c-footer-info__top {

    margin-left: auto;
    margin-top: 0;

    font-size: .875rem;

  }

}