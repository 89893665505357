/*
 * Question: Reorder
 */

.c-question-reorder {

  display: flex;
  flex-flow: column;

}

.c-question-reorder__label {

  padding: .5rem 1rem;

  font-size: 1rem;
  line-height: 1.5;

  border-radius: $border-radius-small;

}

.c-question-reorder__label--top {

  margin-bottom: .5rem;

  color: $color-success;
  background-color: $color-success-tint;

}

.c-question-reorder__label--bottom {

  margin-top: .5rem;

  color: $color-danger;
  background-color: $color-danger-tint;

}

.c-question-reorder__items {

  list-style: none;
  margin: 0;
  padding: 0;

  counter-reset: section;

}

.c-question-reorder__item {

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 0;
  padding: .5rem 1rem;

  font-size: 1rem;
  line-height: 1.5;

  &:before {

    min-width: 1.5rem;
    display: inline-block;

    font-weight: $semibold;

    counter-increment: section;
    content: counter(section);

  }

  @include breakpoint(extra-small) {

    font-size: 1.125rem;

  }

  .o-tooltip {

    margin-left: $unit-xs;

  }

}

.c-question-reorder__highlight {

  display: flex;
  align-items: center;
  margin-left: .75rem;

  color: $color-text-weak;

  font-size: .75rem;
  line-height: 1.6666666667;
  font-family: $font-headline;
  font-weight: $semibold;
  font-variant: small-caps;
  text-transform: uppercase;
  letter-spacing: .025em;

  .o-icon {

    margin-right: .25rem;

    font-size: 1rem;
    color: $color-highlight;

  }

}

/*
 * Modifier
 */

.c-question--library {

  .c-question-reorder__label {

    @include hide-visually();

  }

  .c-question-reorder__items {}

  .c-question-reorder__item {

    margin-top: $unit-xs;

    font-size: 1rem;
    line-height: 1.5;

    background-color: $color-grey-x-light;
    border-radius: $border-radius-small;

    &:before {

      display: none;

    }

    &:first-child {

      margin-top: 0;

    }

  }

}
