/*
 * Slim
 *
 * Slim Image Cropper is a cross platform JavaScript Image Cropping and Uploading plugin.
 * https://pqina.nl/slim/
 *
 * `.o-slim` is our parent container that makes it easier to target slim components
 * on various locations. E.g. avatar upload or poll image choice upload.
 */

.o-slim {

  position: relative;

  .slim {

    font-size: 1rem;
    line-height: 1.5;
    color: $color-text;
    // background-color: $color-grey-x-light;
    background-color: tint($color-primary-dark, 90%);

  }
  
  //  ---------------
  //  =note
  //  2022-10-13
  //  This was added to prevent slim areas having very small heights.
  
  &:not(.o-slim--prototype) {
    
    .slim .slim-area .slim-status {
      // @include breakpoint(medium) { min-height: 25vh); }
    }
  }
  
  .c-upload-group__item & {
    
    .slim .slim-area .slim-status {
      @include breakpoint(medium) { min-height: auto; }
    }
  }
  
  //  ---------------


  &.o-slim--square,
  &.o-slim--circle,
  &.o-slim--fluid {

    font-size: .875rem;
    line-height: 1.4285714286;

  }

  &:first-child .slim {

    border-top-left-radius: $border-radius-small;
    border-top-right-radius: $border-radius-small;

  }

  &:last-child .slim {

    border-bottom-left-radius: $border-radius-small;
    border-bottom-right-radius: $border-radius-small;

  }

  
  .c-field--contains-translation & {
    
    .slim-area .slim-status {
      line-height: 1.3;
    }
    
    .slim-label {
      max-width: rem(440);
      font-size: rem(15);
      line-height: 1.2;
    }
  }

  .l-form__item--s.c-field--contains-translation & {
    
    .slim-area .slim-status {
      font-size: rem(15);
    }
  }

}


/*
 *
 * Use:
 * - Avatar 1:1 ratio
 *
 */

.o-slim--square {

  max-width: 16rem;

}


/*
 *
 * Use:
 * - Fills the available space
 *
 */

.o-slim--fluid {

  max-width: 100%;

}


/*
 *
 * Use:
 * - Rounded avatar 1:1 ratio
 *
 */

.o-slim--circle {

  max-width: 16rem;

  .slim {

    font-size: .875rem;
    line-height: 1.4285714286;
    border-radius: 50%!important;

  }

  .o-slim__search {

    border-top: none;

    font-size: .875rem;

    &:hover,
    &:focus,
    &:active {

      background: transparent;

    }


  }

  .o-slim__search + .slim .slim-area .slim-status {

    bottom: 0;

  }

  @include breakpoint(medium) {

    max-width: 16rem;

  }

}


/*
 *
 * Use:
 * - Backgrounds on projects that conform to 2:1 ratio
 *
 */

.o-slim--wide {

  // max-width: 100%;
  max-width: 75rem;

}


/*
 *
 * Use:
 * - Poll image choices that conform to 3:2 ratio
 *
 */

.o-slim--landscape {

  // max-width: 100%;
  max-width: 50rem;

}

/*
 * Slim image editor
 *
 * Buttons for cropping
 */

.slim-popover {

  background: rgba($color-primary-dark, .95);

  &:after {

    display: none;

  }

}

.slim-editor-btn-group .slim-editor-btn,
.slim-editor-utils-group .slim-editor-utils-btn {

  padding: .625rem 1rem;
  border: .125rem solid $color-border-normal;

  border-radius: $border-radius-small;
  box-shadow: none;

  color: $color-text-invert;

  &:hover,
  &:focus,
  &:active {

    color: $color-text-strong-invert;

  }

  &:active {

    padding: .625rem 1rem;
    box-shadow: none;

  }

}

.slim-editor-btn,
.slim-editor-utils-btn {

  color: $color-text-strong;
  background-color: $color-border-normal;

}

.slim-image-editor .slim-editor-btn-group .slim-editor-btn.slim-btn-confirm {

  color: $color-text-strong;
  background-color: $color-border-normal;

  &:hover,
  &:focus,
  &:active {

    color: $color-text-strong;

  }

}

.slim[data-state*="empty"]:hover {

  color: $color-link;
  background-color: $color-link-tint;
  background-color: tint($color-primary-dark, 85%);

}

/*
 * Image choice use–case
 */

.o-slim--fluid + .o-input {

  input,
  input[type="text"] {

    border-top-left-radius: 0;
    border-top-right-radius: 0;

    text-align: center;

  }

}


/*
 * Slim circle
 *
 * Adds circular visual cue to the image crop preview area
 */

.slim-popover--crop-circle {

  .slim-crop-area:before {

    box-sizing: border-box;

    position: absolute;
    z-index: $z-index-modal;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(255,255,255,.5);

    border-radius: 50%;

    pointer-events: none;

    content: "";

  }

}

/*
 * Slim search
 *
 * Show a button that opens a media modal
 * where user can search for images or select
 * image from the image library.
 */

.o-slim__search {

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $z-index-modal;

  width: 100%;
  min-height: 3rem;
  border: none;
  border-top: .0625rem solid rgba($color-link, .05);

  background: none;
  border-radius: 0;

  &:hover,
  &:focus,
  &:active {

    background-color: $color-link-tint;
    border-color: transparent;

  }

}

.o-slim__search + .slim .slim-area .slim-status {

  bottom: 3rem;

}


/*
 * Slim prototype
 *
 * Used for prototyping purposes an faking the slim
 * layout in our own templates
 */

.o-slim--prototype {

  width: 100%;

  .slim {

    position: relative;
    padding-bottom: .025px;

  }

  .slim-area .slim-status {

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    padding-left: $unit-m;
    padding-right: $unit-m;

  }

  .slim-area .slim-status {

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    pointer-events: none;

  }

  .slim input[type="file"] {

    display: block !important;
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
    padding: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    border: 0 !important;

  }

}