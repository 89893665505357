/*
 * Embed example
 *
 * Showing the embeded code within a contianer
 * that simulates a mock page.
 */

.c-embed-example {

  padding: $unit-s;

  box-shadow: inset 0 0 0 .0625rem $color-input-regular;
  border-radius: $border-radius-small;

}
.c-embed-example__content {

  margin: $unit-s 0;

}

.c-embed-example__decoration {}
.c-embed-example__line {

  display: block;
  height: .625rem;
  margin-bottom: $unit-s;

  background-color: $color-grey-x-light;

  &:last-child {

    max-width: 90%;
    margin-bottom: 0;

  }

}