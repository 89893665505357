/*
 * Upload
 *
 * Upload component with styled preview clear button
 */

.c-upload {

  display: flex;

  min-height: 3rem;

  // background-color: $color-primary-light;
  background-color: $color-link-tint;

  &.is-filled {

    background: none;

  }

}

/*
 * Action
 */

.c-upload__action {

  position: relative;

  overflow: hidden;

}

.c-upload__label {

  position: relative;
  z-index: $z-index-upload + 1;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: .6875rem 1rem;
  border: .0625rem solid $color-input-regular;

  text-align: center;

  border-radius: $border-radius-small;

  .o-label {

    color: $color-link;
    font-size: 1rem;
    font-weight: $regular;

  }

  .o-icon {

    font-size: 1.5rem;
    margin-right: .5rem;

    color: $color-text;

  }

  &:hover {

    border-color: transparent;
    background: $color-grey-x-light;

  }

}

.c-upload__input {

  position: absolute;
  z-index: $z-index-upload;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;

  opacity: 0;

}

/*
 * Progress
 */

.c-upload__progress {

  position: relative;

  padding: .75rem 1rem;
  min-width: 10rem;

  font-size: 1rem;
  font-weight: $regular;
  background-color: $color-grey-x-light;
  border-radius: $border-radius-small;

}

.c-upload__progress-label {

  display: block;
  height: 100%;

  font-size: .875rem;
  line-height: 1.4285714286rem;
  color: $color-text-weak;

  text-align: center;

}

.c-upload__progress-bar {

  position: absolute;
  top: 50%;
  left: 1rem;
  right: 1rem;
  height: .25rem;

  max-width: 10rem;
  margin-left: auto;
  margin-right: auto;

  transform: translateY(-50%);

  background-color: #fff;
  border-radius: .125rem;

}

.c-upload__progress-current {

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  background-color: $color-success;
  border-radius: .125rem;

}

/*
 * Content
 */

.c-upload__content {

  position: relative;

  img {

    width: 100%;

    margin: 0;

  }

}

.c-upload__remove {

  position: absolute;
  top: -.5rem;
  right: -.5rem;

  border: none;

  padding: .5rem;
  width: 2.5rem;
  height: 2.5rem;

  color: $color-text-strong;
  background-color: $color-accent-green;
  border-radius: 1.25rem;

  .o-label {

    @include hide-visually();

  }

  .o-icon {

    font-size: 1.5rem;

  }

}

/*
 * States
 */

.c-upload.is-empty {

  .c-upload__action {

    display: block;

  }

  .c-upload__progress,
  .c-upload__content {

    display: none;

  }

}

.c-upload.is-progress {

  .c-upload__action,
  .c-upload__content {

    display: none;

  }

  .c-upload__progress {

    display: block;

  }

}

.c-upload.is-filled {

  .c-upload__action,
  .c-upload__progress {

    display: none;

  }

  .c-upload__content {

    display: block;

  }

}

.c-upload.is-disabled {

  pointer-events: none;

  .c-upload__action {}

  .c-upload__label {

    border-color: transparent;
    background-color: $color-border-weak;

    .o-label {

      color: rgba($color-text, .25);

    }

    .o-icon {

      color: rgba($color-text, .25);

    }

  }

  .c-upload__remove {

    display: none;

  }

}

/*
 * Option modifier
 *
 * Enables different style when input is the next element.
 * Used for poll questions where the image needs a label for example.
 */

.c-upload--option {

  display: flex;
  flex-direction: column;

  min-height: 8rem;

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .c-upload__action {

    height: inherit;

  }

  .c-upload__label {

    height: inherit;
    min-height: 8rem;

    border-bottom: none;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

  }

  .c-upload__progress {

    height: inherit;
    min-height: 8rem;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

  }

  .c-upload__content {

    height: inherit;
    width: 100%;

    img {

      height: inherit;

      @include object-fit(cover, 50% 50%);

      border-top-left-radius: $border-radius-small;
      border-top-right-radius: $border-radius-small;

    }

  }

}

.c-upload--option + .o-input {

  input,
  input[type="text"] {

    border-top-left-radius: 0;
    border-top-right-radius: 0;

    text-align: center;

  }

}


/*
 * Circle
 *
 * Circular images get 50% border-radius
 */

.c-upload--circle {

  .c-upload__content img {

    border-radius: 50%;

  }

}


/*
 * Fill
 *
 * Image gets wide as the parent
 */

.c-upload--fill {

  .c-upload__content {

    width: 100%;

  }

}


/*
 * Video
 *
 * Ads a video overlay
 */

.c-upload--video {

  .o-tag {

    position: absolute;
    top: 1rem;
    left: 1rem;
    margin: 0;

  }

  .c-upload__content:before {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba($color-primary-dark, .2);

    content: "";
    pointer-events: none;

  }

}