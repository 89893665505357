/*
 * Pills
 *
 * Actionable filter list where items can
 * be removed.
 */

.c-pills {

  display: flex;
  flex-flow: row wrap;

  margin-top: $unit-xs;

  &:first-child {

    margin-top: 0 - $unit-xs;

  }

}

.o-pill {

  font-size: .875rem;
  line-height: 1.5;
  font-weight: 500;

  display: flex;

  margin-top: $unit-xs;
  margin-right: $unit-xs;

  border-radius: .75rem;
  background-color: tint($color-link, 90%);

}

.o-pill__label {

  padding: 0 $unit-xs 0 $unit-s;
  margin-right: auto;
  display: block;
}

.o-pill__action {

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  border: none;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;

  border-radius: 50%;
  color: $color-text-weak;
  background-color: tint($color-link, 90%);

  .o-label {

    @include hide-visually();

  }

  &:hover,
  &:focus,
  &:active {

    background-color: $color-link;
    color: $color-text-strong-invert;

  }

}