/*
 * Report list
 *
 * List of available reports with
 * loading indicators and as links
 * ready to download.
 */

.c-report-list {

  list-style: none;
  padding: 0;
  margin: 0;

}

.c-report-list__item {

  position: relative;

  display: block;
  padding: $unit-xs $unit-xs $unit-xs 2.5rem;
  margin-top: .25rem;

  background-color: $color-primary-light;
  border-radius: $border-radius-small;

  &.is-loading {

    background-color: $color-primary-light;

  }

  &.is-error {

    background-color: $color-danger-tint;

  }

}

a.c-report-list__item {

  padding-right: 2.5rem;

  background-color: $color-success-tint;

  .o-icon {

    color: $color-success;

  }

  .o-icon-arrow-to-bottom {

    position: absolute;
    top: 50%;
    right: $unit-s;
    transform: translateY(-50%);

    color: $color-text;

  }

  &:hover,
  &:focus,
  &:active {

    box-shadow: inset 0 0 0 .125rem $color-success;

  }
  
  .c-report-list__additional-action {
    display: block;

    svg {
      font-size: rem(14);
      color: $color-primary-dark;
      position: absolute;
      top: 50%;
      right: rem(36);
      transform: translateY(-50%);
      transition: $transition-default;
    }
    
    &:hover, &:focus {
      
      svg {
        opacity: .5;
      }
    }
  }

}

.c-report-list__title {

  margin-bottom: 0;

  font-family: $font-body;
  font-size: .875rem;
  line-height: 1.5;
  color: $color-text;

}

.c-report-list__meta {

  font-size: .75rem;
  line-height: 1.25;
  color: $color-text-weak;

  @include ellipsis;

}

.c-report-list__state {

  position: absolute;
  top: 50%;
  left: .25rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);

  .o-icon {

    position: relative;
    top: auto;
    left: auto;

  }

  .is-error & {

    color: $color-danger;

  }

}