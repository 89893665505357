/*
 * Action bar
 *
 * Single line bar consisting of:
 * - Title (short title)
 * - Description (small text in the form of a paragraph)
 * - Actions (buttons)
 */

.c-action-bar {

  margin-bottom: 1rem;

  @include breakpoint(extra-small) {

    display: flex;
    align-items: center;
    min-height: 3rem;

  }

  @include breakpoint(small) {

    margin-bottom: $unit-m;

  }
  
  &:has(.c-filter-tabs) {
    
    @include breakpoint(to-medium) {
      flex-direction: column;
      align-items: flex-start;
      grid-gap: $unit-s;
      
      .c-action-bar__action {
        flex-direction: column;
        grid-gap: $unit-xs;
        margin: 0;
        width: 100%;
        
        > * + * {
          margin-left: 0;
        }
      }
    }
  }

}

.c-action-bar__title {

  margin-bottom: .25rem;

  font-size: 1.5rem;
  line-height: 1.5;
  color: $color-text-strong;

  @include breakpoint(extra-small) {

    margin-bottom: 0;
    margin-right: 1rem;

  }

  @include breakpoint(large) {

    font-size: 1.75rem;
    line-height: 1.285714285714286;

  }

}

.c-action-bar__description {

  margin-bottom: .5rem;

  font-size: 1rem;
  line-height: 1.5;
  color: $color-text-weak;

  @include breakpoint(extra-small) {

    margin-bottom: 0;

  }

}

.c-action-bar__action {

  display: flex;

  &:empty {

    display: none;

  }

  > * + * {

    margin-left: $unit-s;

  }

  @include breakpoint(extra-small) {

    margin-left: auto;

  }

}

.c-action-bar + p {

  margin-top: 0 - $unit-s;

}