/*
 * Reorder
 *
 * Basic structure for reordering of elements:
 * - images (via upload)
 */


.c-reorder-list {

  margin-bottom: $unit-m;

  .c-reorder {

    margin-bottom: $unit-s;

  }

}

.c-reorder {

  margin-bottom: $unit-m;
  display: flex;
  align-items: stretch;
  -webkit-user-select: none !important;

  &:last-child {

    margin-bottom: 0;

  }

}

.c-reorder__handle {

  position: relative;

  display: flex;
  align-items: center;
  flex: 0 0 2.5rem;
  min-height: 3rem;
  padding: .75rem .5rem;
  border: none;

  text-align: center;
  font-size: 1.5rem;
  line-height: 1.25rem;
  color: rgba($color-text, .4);
  border-radius: $border-radius-small 0 0 $border-radius-small;
  background-color: $color-grey-x-light;

  cursor: move;
  @include prefixer(cursor, grab, ("webkit", "moz"));

  transition: all .1s ease-in;

  .o-icon {

    position: relative;

  }

  .o-label {

    @include hide-visually;

  }

  &:hover,
  &:focus,
  &:active {

    color: $color-link;

  }

  &:active {

    .o-icon {

      top: .0625rem;

    }

  }

}

/*
 * Upload overrides
 */

.c-reorder__handle + .c-upload .c-upload__label,
.c-reorder__handle + .c-upload .c-upload__progress {

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

}

/*
 * Modifier
 */

.c-reorder-list--gallery {

  .c-reorder {

    max-width: 24rem;

  }

}


.c-reorder-list--content {

  .c-reorder {

    border-radius: $border-radius-small;

    box-shadow: inset 0 0 0 .0625rem $color-border-weak;

  }

}

.c-reorder-list--gallery-captions {

  width: 100%;

  @include breakpoint(medium) {

    // Make sure that all uploads are of the same width
    // as ones with captions will have the edit form on
    // the right

    .c-upload {

      max-width: 24rem;
      min-width: 24rem;

    }

  }

}