/*
 * Lead image
 */

.c-lead-image {
  margin-top: rem(-12);

  > .c-field__label {
    margin: 0;
    bottom: rem(-12);
    background: $color-white;
    padding: 0 $unit-m 0 0;
    display: inline-block;
    position: relative;
  }
  
  > .c-field__group {
    border: 1px solid $color-border-weak;
    padding: $unit-m;
    border-radius: $border-radius-small;
  }
}


/*
 * Lead image: Multiple image upload component
 *
 * (in case we want to reuse this component elsewhere
 * we should think of removing this component's style from
 * here and maybe add it in a separate file named after it)
 */

$multiple-image-upload-focus: #47B9FF;
$multiple-image-upload-border: $color-border-weak;
$multiple-image-upload-image-size: rem(60);
$multiple-image-upload-image-border-radius: rem(4);

.c-multiple-image-upload {}

.c-multiple-image-upload-navigation {
  position: relative;
  padding-bottom: $unit-m;
  margin-bottom: $unit-m;
  
  &:before {
    content: "";
    width: 100%;
    height: rem(1);
    background: $multiple-image-upload-border;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.c-multiple-image-upload-navigation__container {
  @include list-reset();
  display: flex;
  grid-gap: rem(12);
}

.c-multiple-image-upload-navigation__item {
  margin: 0;
  width: $multiple-image-upload-image-size;
  height: $multiple-image-upload-image-size;
  z-index: 9999;
  list-style: none;
  
  button {
    width: $multiple-image-upload-image-size;
    height: $multiple-image-upload-image-size;
    margin: 0;
    position: relative;
    border: 0;
    box-shadow: none;
    border-radius: $multiple-image-upload-image-border-radius;
    cursor: pointer;
    transition: $transition-fast;
    outline: none;
    -webkit-tap-highlight-color:  transparent;
    
    &[aria-selected="true"] {
      box-shadow: 0 0 0 4px $multiple-image-upload-focus;
      
      &:before,
      &:after {
        content: "";
        position: absolute;
        transform: translateX(-50%);
        top: 100%;
        left: 50%;
	      width: 0;
	      height: 0;
	      border-style: solid;
	      border-width: 0 10px 10px 10px;;
        transition: $transition-fast;
        opacity: 1;
      }
      
      &:before {
        margin-top: rem(13);
	      border-color: transparent transparent $multiple-image-upload-border transparent;
      }
      
      &:after {
        margin-top: rem(15);
	      border-color: transparent transparent #fff transparent;
      }
    }
  }
  
  &.c-field--reorder-helper button {
    
    //  Styles to hide arrows on selected buttons when
    //  items are being dragged!
    
    &:before,
    &:after {
      opacity: 0;
    }
  }
  
  &:hover button:not([aria-selected="true"]),
  button:focus {
    box-shadow: 0 0 0 4px $color-grey-mid-dark;
  }
  
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    border-radius: $multiple-image-upload-image-border-radius;
  }
  
  &.c-multiple-image-upload-navigation__item--add-image {
    
    .o-icon {
      width: rem(24);
      height: rem(24);
      color: $color-grey-x-dark;
    }
  }
  
  .c-reorder__handle {
    position: absolute;
    top: 0;
    left: 0;
    width: rem(16);
    height: 100%;
    padding: 0;
    border-radius: $multiple-image-upload-image-border-radius 0 0 $multiple-image-upload-image-border-radius;
    background: rgba(255,255,255,.8);
  }
}