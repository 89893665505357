body {
  overflow: auto;
}
.gmv-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.gmv-button {
  display: inline-block;
  padding: 1rem 2rem;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.5;
  background: #070047;
  color: #fff;
  border: none;
  border-radius: 3px;
}
.gmv-button:hover,
.gmv-button:focus {
  color: #070047;
  background: #fff;
}
.gmv-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gmv-modal-content {
  width: 100%;
  height: 100%;
}
.gmv-modal-close {
  position: absolute;
  z-index: 2000;
  top: 1rem;
  left: 1rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 3rem;
  min-height: 3rem;
  padding: 0;
  margin: 0;
  font-size: 1.75rem;
  line-height: 1;
  border-radius: 50%;
  background: #fff;
  color: #070047;
  transition: all 0.2s ease-in;
}
.gmv-modal-close:hover,
.gmv-modal-close:focus {
  color: #070047;
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.1);
}
.gmv-modal-close-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'%3E%3Cpath d='M446.627 110.627l-45.254-45.254-145.373 145.372-145.373-145.372-45.254 45.253 145.373 145.374-145.373 145.373 45.254 45.254 145.373-145.373 145.373 145.373 45.254-45.255-145.372-145.372z'%3E%3C/path%3E%3C/svg%3E%0A");
  text-indent: -9999px;
  background-size: 1.25rem 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  display: block;
}
.gmv-modal-close-label {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.gmv-modal iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  border: none;
  height: 100%;
}
