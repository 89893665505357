/*
 * Buttons
 *
 * Default button styling
 */

.o-button {

  position: relative;

  display: inline-block;
  padding: .625rem 1rem;
  border: .125rem solid transparent;

  font-size: 1rem;
  line-height: 1.5;
  font-weight: $semibold;
  text-align: center;

  color: $color-text;
  background-color: $color-button-regular;

  font-family: $font-body;
  text-decoration: none;
  text-transform: none;

  border-radius: $border-radius-small;
  transition: all .1s ease-in;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {

    color: $color-text-strong;
    background-color: $color-button-regular-hover;

  }

  @include breakpoint(small) {

    font-size: 1.125rem;
    line-height: 1.3333333333; // 24/18

  }

  .o-icon {

    position: relative;
    top: .125rem;

  }

  .o-icon + .o-label {

    margin-left: .5rem;

  }

  .o-label + .o-icon {

    margin-left: .5rem;

  }

  .o-tag {

    position: relative;
    top: -.125rem;

  }
  
  .c-message--highlight & {
    display: table;
    margin: $unit-xs auto 0;
  }

}

/*
 * Modifiers
 */

.o-button--primary {

  color: $color-text-strong-invert;
  background-color: $color-button-primary;

  &:hover,
  &:focus,
  &:active {

    color: $color-text-strong-invert;
    background-color: $color-button-primary-hover;

  }

}

.o-button--alternate {

  color: $color-text;
  border-color: $color-button-alternate;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {

    color: $color-text-strong;
    border-color: $color-button-alternate-hover;
    background-color: transparent;

  }

}

.o-button--naked {

  color: $color-link;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {

    color: $color-link-hover;
    background-color: transparent;

  }

}

.o-button--success {

  color: $color-success;
  background-color: $color-success-tint;

  &:hover,
  &:focus,
  &:active {

    color: darken($color-success, 5%);
    background-color: darken($color-success-tint, 5%);

  }

}

.o-button--danger {

  color: $color-danger;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {

    color: darken($color-danger, 5%);
    background-color: darken($color-danger-tint, 5%);

  }
  
  &.o-button--solid {
    // We are defining "solid" variation, to have a more prominent "danger" button
    // to be used in cases where we have (for example) at least two "delete" CTAs

    background-color: $color-danger;
    color: $color-white;

    &:hover,
    &:focus,
    &:active {
  
      color: darken($color-white, 5%);
      background-color: darken($color-danger, 5%);
  
    }

  }

}

.o-button--link {

  padding: 0;
  border: none;

  font-size: inherit;
  line-height: inherit;
  color: $color-link;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {

    color: $color-link-hover;
    background-color: transparent;

  }

  .o-label {

    text-decoration: underline;

  }

  .o-icon + .o-label {

    margin-left: .125rem;

  }

  .o-label + .o-icon {

    margin-left: .125rem;

  }

}


.o-button--ellipsis {

  width: 1.5rem;
  height: 1.5rem;
  padding: .25rem;
  border: none;

  font-size: 1rem;
  line-height: 1;

  border-radius: .75rem;
  vertical-align: middle;

  .o-icon + .o-label,
  .o-label + .o-icon  {

    margin: 0;

  }

  .o-label {

    @include hide-visually();

  }

  .o-icon {

    top: 0;

  }

  &.is-warning {

    color: $color-warning;
    background-color: $color-warning-tint;

  }

  &.is-danger {

    color: $color-danger;
    background-color: $color-danger-tint;

  }

  &.is-success {

    color: $color-success;
    background-color: $color-success-tint;

  }

}

/*
 * Size
 */

.o-button--s {

  font-size: 1rem;
  line-height: 1.5rem;

  padding: .375rem .5rem;

}

.o-button--xs {

  font-size: 1rem;
  line-height: 1.25rem;

  padding: .25rem .5rem;

}

.o-button--inline {

  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;

}

/*
 * Block
 */

.o-button--block {

  display: block;
  width: 100%;

}


/*
 * States
 */

.o-button.is-loading {

  border-color: transparent;
  pointer-events: none;
  background-color: $color-grey-x-light;

  .o-label {

    opacity: 0;

  }

  .o-icon {

    opacity: 0;

  }

  &:after {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 1rem;
    height: 1rem;
    border: .125rem solid $color-grey-mid-light;

    border-radius: 50%;

    border-top-color: $color-link;

    content: "";

    animation: rotate360 1s infinite linear;

  }

  &:hover,
  &:focus,
  &:active {

    border-color: transparent;
    background-color: inherit;

  }

}

@keyframes rotate360 {

  0% {

   transform: translate(-50%, -50%) rotate(0);

  }

  100% {

   transform: translate(-50%, -50%) rotate(360deg);

  }

}

.o-button.is-disabled {

  background-color: $color-grey-x-light;
  color: rgba($color-text-weak, .25);

  pointer-events: none;

}