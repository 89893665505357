/*
 * Misc objects
 *
 * Various miscellaneous objects used accross the app.
 */

.o-filter-label {
  font-size: rem(12);
  font-weight: $semibold;
  background: transparent-color($color-black, .07);
  text-transform: uppercase;
  padding: $unit-xxs $unit-xs;
  border-radius: $border-radius-small;
}

.o-filter-value {
  font-size: rem(14);
  // padding-top: $unit-xxs;
  
  strong {
    color: $color-black;
  }
}

//  Links
.o-link {
  padding: .125rem .25rem;
  text-decoration: underline;
  display: inline-block;
  
  svg {
    margin-left: .25rem;
    position: relative;
    top: rem(2);
  }
    
  label & {
    padding: 0 .375rem;
    color: $color-primary-dark;
    background-color: $color-grey-mid-light;
    // box-shadow: inset 0 0 0 1px $color-grey-x-dark;
    // border: rem(1) solid $color-grey-x-dark;
    text-decoration: none;
    font-size: .75rem;
    line-height: 1.6666666667;
    font-family: $font-headline;
    font-weight: $semibold;
    text-transform: uppercase;
    letter-spacing: .05em;
    border-radius: $border-radius-small;
    vertical-align: middle;
    margin: auto .25rem;
    transition: $transition-fast;
    
    svg {
      top: rem(1);
    }
    
    &:hover, &:focus {
      background-color: $color-grey-mid-dark;
    }
  }
}