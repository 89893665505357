/* =Lists
===================================================== */

// Links
//
// Link examples
//
// :hover - Hover state
// :focus - Focus state
// :visited - Visited state
//
// Markup:
// <a href="#">Link example</a>
//
// Style guide: 4.links

a {

  color: $color-link;
  text-decoration: none;
  transition: all .1s ease-in;

  &:hover,
  &:focus,
  &:active {

    color: $color-link-hover;
    text-decoration: none;

  }

}