/*
 * Image grid
 *
 * https://github.com/xieranmaya/blog/issues/6
 */

$image-grid-gap: .5rem;

.c-image-grid {

  position: relative;

  margin-top: 0 - $unit-s;
  padding-top: $unit-s;

}

.c-image-grid__items {

  margin-left: 0 - $image-grid-gap;
  margin-right: 0 - $image-grid-gap;
  margin-bottom: -.125rem;

  display: flex;
  flex-wrap: wrap;
  overflow: hidden; // Fixes a bug on Windows with a slider showing up

  &:after {

    flex-grow: 999999999;
    content: "";

  }

}

.c-image-grid__item {

  position: relative;
  margin: $image-grid-gap;

  background-color: $color-primary-dark;

  a {

    &:hover,
    &:focus,
    &:active {

      opacity: .75;

    }

  }

  img {

    position: absolute;
    top: 0;
    width: 100%;
    margin-bottom: 0;

  }

}

.c-image-grid__spacer {

  display: block;

  background-color: $color-primary-light;
}

.c-image-grid__actions {

  position: absolute;
  top: $unit-xs;
  right: $unit-xs;

}

.c-image-grid__action {

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  width: 2rem;
  height: 2rem;
  padding: 0;

  font-size: .875rem;
  line-height: 1.4285714286;
  // background: $color-primary-light;
  background: #fff;
  border-radius: 50%;

  opacity: 0;
  cursor: pointer;

  .c-image-grid__item:hover & {

    opacity: 1;

  }

  .o-icon {

    color: $color-text-weak;

  }

  .o-label {

    @include hide-visually(hide);

  }

}

.c-image-grid__action--star {

  .o-icon-star-filled {

    display: none;

  }

  .c-image-grid__item--highlight & {

    opacity: 1;

    .o-icon {

      color: $color-text-strong;

    }

    .o-icon-star {

      display: none;

    }

    .o-icon-star-filled {

      display: block;

    }

  }

}

.c-image-grid__meta {

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  padding: $unit-xs $unit-xs;

  font-size: .75rem; // 12
  line-height: 1.333333333333333; // 18/14
  font-weight: $medium;
  color: $color-text-invert;

  transition: $transition-default;
  opacity: 0;

  a {

    color: $color-text-strong-invert;

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong-invert;
      text-decoration: underline;

      opacity: 1;

    }

  }

  .c-image-grid__item:hover & {

    opacity: 1;

  }

}

.c-image-grid__attribution {

  position: absolute;
  top: 0;
  right: 0;
  // right: $image-grid-gap;

  font-size: .75rem;
  line-height: 1.3333333333;
  color: $color-text-weak;

  a {

    color: $color-text-strong;

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong;
      text-decoration: underline;

      opacity: 1;

    }

  }

}