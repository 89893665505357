/*
 * Community card
 *
 * Community projecy card:
 * - Logo
 * - Background image
 * - Title
 * - Subtitle
 * - Meta information ()
 */


.c-community-card {

  border: .0625rem solid $color-border-normal;

  border-radius: $border-radius-small;

  transition: all .1s ease-in;

  &:hover {

    box-shadow: $box-shadow-large;

  }

  .l-grid--halfs &,
  .l-grid--thirds & {

    height: 100%;

  }

}

.c-community-card__media {

  margin-left: -.0625rem;
  margin-top: -.0625rem;
  margin-right: -.0625rem;

  border-top-left-radius: $border-radius-small;
  border-top-right-radius: $border-radius-small;
  background-color: $color-grey;

  overflow: hidden;

}

.c-community-card__background {

  display: block;
  height: 10rem;
  width: 100%;
  margin: 0;

  @include object-fit(cover, 50% 50%);

}

.c-community-card__background--placeholder {

  background-image: linear-gradient(30deg, $color-accent-red, $color-accent-blue, $color-accent-green);
  background-size: 150% 150%;

}

.c-community-card__info {

  position: relative;
  padding: 3.5rem 1rem .25rem 1rem;

  text-align: center;

}

.c-community-card__meta {

  padding: .25rem 1rem 2rem 1rem;

  text-align: center;

  p {

    margin: .25rem;

    font-size: .875rem;
    line-height: 1.4285714286;
    color: $color-text-weak;

    &:last-child {

      margin-bottom: 0;

    }

  }

}

.c-community-card__title {

  margin-bottom: 0;

  font-size: 1.25rem;
  line-height: 1.4;

}

.c-community-card__subtitle {

  margin-top: .125rem;
  margin-bottom: 0;

  font-size: 1rem;
  line-height: 1.5;

}

.c-community-card__logo {

  width: 4rem;
  height: 4rem;

  border-radius: 50%;

  background-color: $color-grey;
  background-color: #fff;
  box-shadow: $box-shadow-large;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 5rem;
  height: 5rem;

}

.c-community-card__logo--placeholder {

  background: #fff assets-path('images/bid-gmv-symbol.svg') no-repeat 50% 50%;
  background-size: 4rem 4rem;

}