/*
 * Upload group
 *
 * Different types of upload in the same group
 */

.c-upload-group {

  display: flex;
  flex-direction: column;

  width: 100%;

  @include breakpoint(small) {

    flex-direction: row;

  }

}

.c-upload-group__item {

  width: 100%;

  display: flex;
  align-items: center;

  .c-field,
  .o-slim {

    width: 100%;

  }

  .c-field__label {

    font-weight: $regular;
    color: $color-text-weak;

  }

  @include breakpoint(large) {

    width: 50%;

  }

}

.c-upload-group__separator {

  @include breakpoint(to-small) {

    margin: 1.5rem 0;
    border-top: .0625rem solid $color-border-weak;

  }

  @include breakpoint(small) {

    margin: 0 1.5rem;
    border-left: .0625rem solid $color-border-weak;

  }

}