/*
 * Button group
 *
 * More than one button in a row
 */

.c-button-list {

  margin-top: $unit-s;
  margin-bottom: $unit-s;

  &:first-child {

    margin-top: 0;

  }

  &:last-child {

    margin-bottom: 0;

  }

  .o-button {

    margin: .25rem;

  }

}

.c-button-list__items {

  display: flex;
  flex-flow: row wrap;

  margin-top: -.25rem;
  margin-right: -.25rem;
  margin-left: -.25rem;
  margin-bottom: -.25rem;

}

.c-button-list--hidden-scroll {

  position: relative;
  overflow: hidden;
  height: 2.5rem;

  &:after {

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    width: $unit-l;

    pointer-events: none;
    background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));

    content: "";

  }

  .c-button-list__items {

    display: flex;
    flex-flow: row nowrap;
    overflow-x: scroll;
    overflow-y: hidden;

  }

  .o-button {

    margin-right: $unit-xs;

    white-space: nowrap;

  }

}