/*
 * Report download
 *
 * Small island with a title and description
 * where available reports are listed.
 */

.c-report-download {

  margin-top: $unit-m;

  padding: $unit-s;
  border: .0625rem solid $color-border-weak;

  border-radius: $border-radius-small;

  &:first-child {

    margin-top: 0;

  }

}

.c-report-download__title {

  margin-bottom: .25rem;

  font-size: 1.125rem;
  line-height: 1.25;

}

.c-report-download__description {

  margin-bottom: $unit-s;

  font-size: .875rem;
  line-height: 1.5;

}



//  Survey Export variation

.c-report-download--survey-export {
  border: 0;
  padding: 0;
  margin-top: $unit-m;
  
  .c-report-list {
    display: grid;
    grid-gap: $unit-xs;
  }
  
  @include breakpoint(small) {
    padding-bottom: rem(50);
    margin-bottom: rem(-50);
    overflow-x: auto;
    
    .c-report-list {
      grid-template-columns: repeat(12, minmax(rem(300), 100%));
    }
  }
}
