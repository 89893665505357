/*
 * Browse feedback
 *
 * Browse feedback component
 */

.c-browse-feedback {

  position: relative;

  display: flex;
  flex-direction: column;
}

.c-browse-feedback__header {

  margin-bottom: $unit-m;
}

.c-browse-feedback__options {

  margin-top: $unit-m;

  @include breakpoint(medium) {

    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: $unit-m;

  }
  
  .c-filter-set__add > button {
    display: flex;
    align-items: center;
    max-height: rem(48);  // this is to align it with the rest of the items
  }
}

.c-browse-feedback__filter {
  background: $color-border-weak;
  border-radius: $border-radius-small;
  padding: $unit-m;
  margin-bottom: $unit-m;
  
  .c-fields {
    display: flex;
    flex-direction: column;
    grid-gap: $unit-xs;
    flex-wrap: wrap;
    
    > * {
      margin: 0;
      width: auto;
    }
  }
  
  .c-suggest {
    max-width: 100%;
  }
  
  .c-filter-set .o-button:not(:hover) {
    background-color: $color-white;
  }


  @include breakpoint(medium) {
    padding: $unit-s;
    margin: 0;
    
    .c-fields {
      flex-direction: row;
      grid-gap: $unit-m;
    }
    
    .c-suggest {
      min-width: rem(400);
    }
  }
}

.c-browse-feedback__display-options {
  
  .c-toggle-navigation__container {
    margin-bottom: 0;
  }
}

.c-browse-feedback__actions {}

.c-browse-feedback__title {
  margin-top: 0;
  margin-bottom: $unit-xs;

  &:last-child {
    margin-bottom: 0;
  }
}

.c-browse-feedback__content {

  // For scrolling purposes
  &:after {
    display: block;
    height: $unit-m;
    margin-bottom: 0 - $unit-m;
    content: "";
  }
}

.c-browse-feedback__items {

  position: relative;

  display: grid;
  grid-gap: $unit-m;
  margin-bottom: $unit-m;

  @include breakpoint(extra-small) {

    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));

  }
}

.c-browse-feedback__primary-filter {
  margin-bottom: $unit-m;
}