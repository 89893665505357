.c-header-dropdown {



}

.c-header-dropdown__title {

  margin: 0;
  padding: .5rem 1rem .5rem 1rem;

  font-size: .75rem;
  line-height: 1.5;
  font-weight: $semibold;
  font-family: $font-headline;
  text-transform: uppercase;
  letter-spacing: .05em;
  color: $color-text-strong;

}

.c-header-dropdown__children {

  list-style: none;
  margin: 0;
  padding: 0;

  margin-bottom: .5rem;

}

.c-header-dropdown__submenu {

  list-style: none;
  margin: 0;
  padding: 0;

  .c-header-dropdown__item {

    font-size: .875rem;

  }

  .c-header-dropdown__link {

    color: $color-text;

  }

}

.c-header-dropdown__item {

  margin-top: 0;

  font-size: 1rem;
  line-height: 1.5rem;

}

.c-header-dropdown__link {

  display: block;
  padding: .5rem 1rem;

  color: $color-link;

  .o-icon {

    float: left;
    width: 1.5rem;
    margin-right: .5rem;

  }

  &:hover,
  &:focus,
  &:active {

    color: $color-link;
    background-color: $color-link-tint;

  }

}

.c-header-dropdown__separator {

  display: block;
  margin: .5rem 1rem;
  border-top: 1px solid $color-border-normal;

}

.c-header-dropdown__children--icons {

  display: flex;
  justify-content: space-between;
  padding: .5rem 1rem;

  .c-header-dropdown__item {

    font-size: 1.25rem;

  }

  .c-header-dropdown__link {

    padding: 0;

    color: $color-text-weak;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong;

      .o-icon {

        color: inherit;

      }

    }

  }

  .c-header-dropdown__label {

    @include hide-visually;

  }

  .o-icon {

    margin-right: 0;

  }

}

@include breakpoint(large) {

  .c-header-dropdown {

    position: absolute;
    top: 100%;
    right: -1rem;
    margin-top: 1rem;

    min-width: 16rem;
    padding: .5rem 0;

    background-color: #fff;
    box-shadow: $box-shadow-medium;
    border-radius: $border-radius-small;

    &:before {

      position: absolute;
      bottom: 100%;
      right: 1rem;
      border: .5rem solid transparent;
      border-bottom-color: #fff;

      content: "";

    }

    .c-header-nav__item--icon & {

      right: 0;

    }

  }

  .c-header-dropdown--notifications {

    min-width: 20rem;
    // padding-top: 0;
    // padding-bottom: 0;

  }

  .c-header-dropdown__children {

    margin-bottom: 0;

  }

  .c-header-dropdown__separator {

    margin-left: 0;
    margin-right: 0;

  }

}

/*
 * Interactions
 *
 * Toggle on and off
 */

.c-header-dropdown {

  display: none;

}

.c-header-nav__item--parent.is-opened {

  .c-header-dropdown {

    display: block;

  }

}