/*
 * Container block
 *
 * Styles for container blocks.
 * Container blocks are used when we want to contain certain layouts and components
 * together, to group them into similar areas.
 */

.l-container-block {
  margin: 3rem auto;
  display: grid;
  grid-gap: $unit-s;
  width: 100%;

  @include breakpoint(to-small) {
    margin: 1.5rem auto;
  }
  
  
  &[class*="boxed"], &[class*="border"] {
    padding: 2rem;
    border-radius: $border-radius-small;
    margin: 2rem auto;
    
    *:not(button) {
      border-color: darken($color-primary-light, 10%) !important;
    }
  }
  
  &[class*="boxed"] {
    background: mix($color-primary-light, $color-white, 50%);
  }
  
  &[class*="border"] {
    border: 1px solid $color-primary-light;
  }
  
  &[class*="border"] {
    border: 1px solid $color-primary-light;
  }
  
  &[class*="--s"] {
    padding: 1rem;
  }
  
  &.no-margin {
    margin-top: 0;
    margin-bottom: 0;
    
    .c-field + & {
      margin-bottom: 1.5rem;
    }
  }
  
  > * {
    margin: 0 !important;
  }
  
  > h2 {
    font-size: 1rem;
    line-height: 1.25;
    color: $color-text;
    text-transform: uppercase;
  }
  
  > .c-message:not(:last-child) {
    margin-bottom: $unit-xs !important;
  }
}