/*
 * Questions
 *
 * Different type of questions
 */

.c-question {

  margin-bottom: 2rem;
  
  body.l-print & {

    margin-bottom: 0;

  }

  &:only-child {

    margin-bottom: 0;

  }

}

.c-question__type {

  display: flex;
  align-items: center;
  margin-right: $unit-xs;
  margin-bottom: $unit-m;
  min-height: 2rem;

  font-size: .875rem;
  line-height: 1.5;
  font-weight: $bold;
  color: $color-text-weak;

  .o-icon {

    margin-right: $unit-xs;

    font-size: 1.25rem;
    color: $color-text;
    color: $color-link;


  }

  &:last-child {

    margin-bottom: 0;

  }

}

.c-question__category {

  margin-bottom: auto;

  .o-tooltip {

    display: block;

  }

}

.c-question__actions {

  margin-left: auto;
  margin-bottom: $unit-m;

}

.c-question__title {

  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  font-size: 1.25rem;
  line-height: 1.5;

  @include breakpoint(large) {

    font-size: 1.5rem;

  }

}

.c-question__header {

  display: flex;
  flex-flow: row wrap;

  margin-bottom: 0;

}

.c-question__content {

  margin-top: 1.25rem;

  &:first-child {

    margin-top: 0;

  }

}

.c-question__footer {

  margin-top: 1.25rem;

  p {

    margin-top: .25rem;

    &:first-child {

      margin-top: 0;

    }

  }

}

.c-question__stats {

  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;

  li {

    margin-top: 0;
    margin-right: $unit-s;
    display: inline-block;

    font-size: .875rem;

  }

  .o-icon {

    position: relative;
    top: .125rem;

    min-width: 1.25rem;
    margin-right: .125rem;

    font-size: 1rem;
    vertical-align: top;

  }

}

// .c-question__votes {

//   margin-top: 0;
//   margin-bottom: 0;

//   font-size: .875rem;
//   line-height: 1.4285714286;
//   color: $color-text-weak;

// }

.c-question__decision {

  margin-top: 0;
  margin-bottom: 0;

  font-size: .875rem;
  line-height: 1.4285714286;

  &:before {

    position: absolute;
    left: 0;
    bottom: 100%;

    content: "";

  }

  .o-icon {

    position: relative;
    top: .125rem;

    min-width: 1.25rem;
    margin-right: .125rem;

    font-size: 1rem;
    vertical-align: top;

  }

}

/*
 * Modifiers
 */

.c-question--library {

  margin-bottom: 0;

  .c-question__title {

    font-size: 1rem;

    @include breakpoint(small) {

      font-size: 1.125rem;

    }

    @include breakpoint(medium) {

      font-size: 1.25rem;

    }

  }

}