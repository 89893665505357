/* =Generic: Page
===================================================== */

body {

  font-family: $font-body;
  line-height: 1.5;
  background-color: $color-primary-light;
  color: $color-text;

}

::selection {

  color: #fff;
  background-color: $color-primary-dark;

}


.is-block-element {

  display: block !important;

}

.no-break {
  white-space: nowrap;
}