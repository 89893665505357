/*
 * Forms
 *
 * Small form objects:
 * - radio
 * - checkbox
 * - select
 * - upload
 * - map
 */


/*
 * Input
 *
 * Regular input styles for complex containers
 */

.o-input {}

/*
 * Input type: range
 *
 * Style the input range element and output value
 * Based on https://codepen.io/seanstopnik/pen/CeLqA
 */

.o-input-range {

  display: flex;
  width: 100%;

  input[type="range"] {

    -webkit-appearance: none;
    width: 100%;
    height: 2rem;
    border-radius: 1rem;
    background: transparent linear-gradient(to top, transparent 47%, $color-input-regular, transparent 53%);
    outline: none;
    padding: 0;
    margin: auto 0;

    // Range Handle
    &::-webkit-slider-thumb {
      appearance: none;
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 50%;
      background: $color-link;
      cursor: pointer;
      transition: all .15s ease-in-out;

      &:hover {
        background: $color-link-hover;
      }
    }

    &:active::-webkit-slider-thumb {
      background: $color-link-hover;
    }

    &::-moz-range-thumb {
      width: 1.75rem;
      height: 1.75rem;
      border: 0;
      border-radius: 50%;
      background: $color-link;
      cursor: pointer;
      transition: all .15s ease-in-out;

      &:hover {
        background: $color-link-hover;
      }
    }

    &:active::-moz-range-thumb {
      background: $color-link-hover;
    }

    // Hover state
    &:hover {

      // &::-webkit-slider-thumb {

      //   width: 2rem;
      //   height: 2rem;

      // }

      // &::-moz-range-thumb {

      //   width: 2rem;
      //   height: 2rem;

      // }

    }

    // Focus state
    &:focus {}

    &:disabled {

      background: transparent linear-gradient(to top, rgba(255,255,255,0) 47%, $color-input-regular, rgba(255,255,255,0) 53%);

    }

  }

}

// Firefox Overrides
::-moz-range-track {

  background-color: transparent;
  border: 0;

}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}

.o-input-range__value {

  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;

  min-width: 3rem;
  height: 3rem;
  margin-left: .5rem;
  padding: 0 .5rem;

  font-size: 1rem;
  line-height: 1.5;
  color: $color-text;
  font-weight: $semibold;
  border-radius: $border-radius-small;

  background-color: $color-grey-x-light;

}

/*
 * Overrides for the yellow coin style
 */

.o-input-range--coins {

  input[type="range"] {

    // Range Handle
    &::-webkit-slider-thumb {
      background: $color-coins;
      box-shadow: inset 0 0 0 .1875rem rgba(0,0,0,.05);

      &:hover {
        background: tint($color-coins, 10%);
      }
    }

    &:active::-webkit-slider-thumb {
      background: tint($color-coins, 10%);
    }

    &::-moz-range-thumb {
      background: $color-coins;
      box-shadow: inset 0 0 0 .1875rem rgba(0,0,0,.05);

      &:hover {
        background: tint($color-coins, 10%);
      }
    }

    &:active::-moz-range-thumb {
      background: tint($color-coins, 10%);
    }

    // Hover state
    &:hover {

      &::-webkit-slider-thumb {

        // background: tint($color-coins, 20%);
        box-shadow: inset 0 0 0 .1875rem rgba(0,0,0,.2);

      }

      &::-moz-range-thumb {

        // background: tint($color-coins, 20%);
        box-shadow: inset 0 0 0 .1875rem rgba(0,0,0,.2);

      }

    }

  }

}

/*
 * Overrides for the community choice style
 */

.o-input-range--winner {

  input[type="range"] {

    // Range Handle
    &::-webkit-slider-thumb {
      background: $color-success;

      &:hover {
        background: tint($color-success, 10%);
      }
    }

    &:active::-webkit-slider-thumb {
      background: tint($color-success, 10%);
    }

    &::-moz-range-thumb {
      background: $color-success;

      &:hover {
        background: tint($color-success, 10%);
      }
    }

    &:active::-moz-range-thumb {
      background: tint($color-success, 10%);
    }

  }

}

/*
 * Overrides for the emoji slider style
 */

.o-input-range--emoji {

  .o-input-range__value {

    font-size: 2rem;

    font-family: $font-stack-system;

  }

}

/*
 * Input button
 *
 * Input with a button
 */

.o-input-icon {

  position: relative;

  > .o-icon {

    position: absolute;
    z-index: $z-index-main;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;

    font-size: 1.125rem;
    line-height: 1.5;
    color: $color-text-weak;

  }

  input[type="text"],
  input[type="password"], {

    padding-left: 3rem;

  }

  input[type="text"]:focus + .o-icon,
  input[type="password"]:focus + .o-icon {

    color: $color-link;

  }

}

.o-input-clear {

  position: relative;

  input[type="text"] {

    padding-right: 3rem;

  }

  .o-button {

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    padding-left: 0;
    padding-right: 0;

    color: $color-text-weak;
    background-color: transparent;

    .o-icon {

      top: 0;

    }

    .o-label {

      @include hide-visually();

    }

    &:hover,
    &:focus,
    &:active {

      .o-icon-trash {

        color: $color-danger;

      }

    }

  }

}

/*
 * Input button
 *
 * Input with a button
 */

.o-input-button {

  display: flex;

  input[type="text"] {

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }

  .o-button {

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

  }

  @supports (display: grid) {

    display: grid;
    grid-template-columns: 1fr auto;

  }

}

/*
 * Input color
 *
 * Select color or enter hex
 */

.o-input-color {

  display: flex;

  input[type="color"] {

    padding: 0;
    min-width: 3rem;
    height: 3rem;
    border: .125rem solid transparent;
    border-right-width: .0625rem;

    background-color: $color-input-regular;

    border-radius: 0;

  }

  input[type="text"] {

    flex: 0;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    // border-left: none;

  }

  @supports (display: grid) {

    display: grid;
    grid-template-columns: 1fr auto;

  }

}

::-webkit-color-swatch,
::-moz-color-swatch {

  border-color: transparent;

}

/*
 * Input suffix
 *
 * Input with a small label on the right, which
 * could be used for a suffix. e.g. "px".
 */


.o-input-suffix {

  position: relative;

  input {

    padding-right: $unit-xl;

  }

}

.o-input-suffix__label {

  position: absolute;
  top: 50%;
  right: $unit-s;
  transform: translateY(-50%);

  color: $color-text-weak;
  pointer-events: none;

}

/*
 * Select button
 *
 * Input with a button
 */

.o-select-group {
  
  &.is-hidden {
    display: none;
  }
}

.o-select-group--button {

  display: flex;

  @supports (display: grid) {

    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: $unit-m;

  }

}

/*
 * Textarea
 */

.o-textarea {}


/*
 * Textarea: Rich text
 *
 * Overrides defined in a component `components.rich-text.scss`
 *
 */

.o-textarea--rich-text {}

/*
 * Textarea: Code
 *
 * Styles the content inside the textarea with smaller font
 * size and a monospaced font.
 */

.o-textarea--code {

  textarea {

    font-size: .75rem;
    line-height: 1.25;
    color: $color-text-weak;
    font-family: $font-stack-monaco;

  }

}

/*
 * Select
 */

.o-select {

  position: relative;

  background-color: #fff;
  border-radius: $border-radius-small;
  box-shadow: inset 0 0 0 .0625rem $color-input-regular;

  .o-icon-angle-down {

    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);

    color: $color-text-weak;
    font-size: 1.25rem;

    pointer-events: none;

  }

  select {

    background: transparent;
    border: none;
    width: 100%;
    box-shadow: none;

    padding-right: 3rem;

    @include prefixer(appearance, none, ("webkit", "moz"));

    &:focus {

      outline: none;
      background-color: $color-grey-x-light;

    }

  }

  option {

    font-size: 1rem;
    line-height: 1.5rem;

    color: $color-text-strong;

  }

  &:hover {

    box-shadow: inset 0 0 0 .125rem $color-input-focus;

  }

}

.o-select--floating-label {

  position: relative;

  select {

    height: 4rem;
    padding-top: 1.5rem;
    padding-bottom: .375rem;

  }

  .o-select__label {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    padding: .625rem 1rem 0 1rem;

    font-size: .825rem;
    font-weight: $regular;
    color: $color-text-weak;

    pointer-events: none;


  }

}

.o-select--inline {

  display: inline-block;

}

.o-select.is-disabled {

  background-color: $color-grey-x-light;
  box-shadow: none;

  pointer-events: none;

  .o-icon-angle-down {

    color: rgba($color-text-weak, .25);

  }

  select {

    color: rgba($color-text-weak, .25);

  }

}

/*
 * Radio + Checkbox
 *
 * Shared styles for both controls
 */

.o-radio,
.o-checkbox {

  position: relative;

  text-align: left;

  label {

    display: inline-block;
    cursor: pointer;
    padding: .75rem 0 .75rem 2.25rem;

    font-family: $font-body;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: $regular;
    color: $color-text;

    @include breakpoint(small) {

      font-size: 1.125rem;
      line-height: 1.3333333333; // 24/18

    }

    .o-icon + .o-icon {

      margin-right: $unit-xs;
      position: relative;
      top: -.0625rem;
      vertical-align: middle;

      font-size: 1rem;
      color: $color-link;

    }

  }

  label:before {

    position: absolute;
    top: .625rem;
    left: 0;

    width: 1.5rem;
    height: 1.5rem;
    border: .125rem solid $color-input-regular;
    background-color: #fff;

    border-radius: $border-radius-small;

    content: "";

  }

  small {

    font-size: .875rem;
    color: $color-text-weak;

  }

}

.o-radio--inline,
.o-checkbox--inline {

  display: inline-block;
  margin-right: 1rem;

}

.o-radio--inline.o-radio--s,
.o-checkbox--inline.o-checkbox--s {

  margin-right: .5rem;

}

.o-radio--inline.o-radio--s.o-radio--button,
.o-checkbox--inline.o-checkbox--s.o-checkbox--button {

  margin-right: 0;

}

/*
 * Radio
 */

.o-radio {

  label:before {

    border-radius: 50%;

  }

  label:after {

    position: absolute;
    top: 1.25rem;
    left: .625rem;

    border-radius: 50%;
    width: .5rem;
    height: .5rem;
    background-color: #fff;

    opacity: 0;
    content: "";

  }

  input[type="radio"] {

    position: absolute;

    opacity: 0;

  }

  input[type="radio"]:hover + label:before,
  input[type="radio"]:focus + label:before {

    box-shadow: 0 0 0 .25rem $color-grey-x-light;
    border-color: $color-grey;

  }

  input[type="radio"]:checked + label:before {

    border: 2px solid $color-link;

    width: 1.5rem;
    height: 1.5rem;
    background: $color-link;

  }

  input[type="radio"]:checked + label:after {

    opacity: 1;

  }

  input[type="radio"]:hover + label:after,
  input[type="radio"]:focus + label:after {

    background-color: $color-grey-x-light;

    opacity: 1;

  }

  input[type="radio"]:disabled + label {

    cursor: default;

  }

  input[type="radio"]:checked:disabled + label:before {

    border-color: transparent;
    background: $color-input-focus;
    box-shadow: none;

  }

  input[type="radio"]:disabled + label:before {

    border-color: transparent;
    background-color: $color-grey-x-light;
    box-shadow: none;

  }

}

.o-radio + .o-radio {

  margin-top: -.3125rem;

}

.o-radio--s {

  label {

    padding: .375rem 0 .375rem 1.75rem;

    font-size: .875rem; // 14
    line-height: 1.428571428571429; // 14/20

  }

  label:before {

    top: .375rem;

    width: 1rem;
    height: 1rem;

  }

  label:after {

    top: .75rem;
    left: .375rem;

  }

  input[type="radio"]:checked + label:before {

    width: 1rem;
    height: 1rem;

  }

}

.o-radio--s + .o-radio--s {

  margin-top: 0;

}

.o-radio--button {

  margin-top: .25rem;
  margin-bottom: .25rem;

  label {

    padding-left: $unit-s;
    padding-right: $unit-s;

    border-radius: $border-radius-small;
    background: #fff;
    background: $color-primary-light;

    &:before,
    &:after {

      display: none;

    }

  }

  input[type="radio"]:hover + label,
  input[type="radio"]:focus + label {

    box-shadow: 0 0 0 .125rem rgba($color-primary-dark, .1);

  }

  input[type="radio"]:checked + label {

    background-color: $color-accent-green;

  }

}

.o-checkbox--button {

  margin-top: .25rem;
  margin-bottom: .25rem;

  label {

    padding-left: $unit-s !important;
    padding-right: $unit-s !important;

    border-radius: $border-radius-small;
    background: #fff;
    background: $color-primary-light;

    &:before,
    &:after {

      display: none;

    }

  }

  input[type="checkbox"]:hover + label,
  input[type="checkbox"]:focus + label {

    box-shadow: 0 0 0 .125rem rgba($color-primary-dark, .1);

  }

  input[type="checkbox"]:checked + label {

    background-color: $color-accent-green;

  }
  
  .o-icon {
    
    display: none;
    
  }

}

.o-radio--card {

  label {

    display: block;
    padding: $unit-s $unit-m;
    padding-left: $unit-m + 2rem;

    border-radius: $border-radius-small;
    box-shadow: inset 0 0 0 .0625rem $color-input-regular;

    .o-icon {

      position: absolute;
      top: $unit-s;
      left: $unit-m;

      font-size: 1.5rem;

    }

    small {

      display: block;
      margin-top: .25rem;

    }

    &:before,
    &:after {

      display: none;

    }

  }

  input[type="radio"]:checked + label {

    box-shadow:
      inset 0 0 0 .125rem $color-link,
      0 0 0 .1875rem $color-input-regular;

  }

  .l-list & {

    align-self: stretch;

    label {

      height: 100%;

    }

  }

}

.o-radio--card + .o-radio--card {

  margin-top: 0;

}

/*
 * Checkbox
 */

.o-checkbox {

  label > .o-icon-check {

    position: absolute;
    top: .875rem;
    left: .25rem;

    font-size: 1.25rem;
    line-height: 1;
    color: $color-text-weak;

    opacity: 0;

  }

  input[type="checkbox"] {

    position: absolute;

    opacity: 0;

  }

  input[type="checkbox"]:hover + label:before,
  input[type="checkbox"]:focus + label:before {

    box-shadow: 0 0 0 .25rem $color-grey-x-light;
    border-color: $color-grey;

  }

  input[type="checkbox"]:checked + label:before {

    border-color: transparent;
    background: $color-link;

  }

  input[type="checkbox"]:disabled + label {

    cursor: default;

  }

  input[type="checkbox"]:checked:disabled + label:before {

    border-color: transparent;
    background: $color-input-focus;
    box-shadow: none;

  }

  input[type="checkbox"]:disabled + label:before {

    border-color: transparent;
    background-color: $color-grey-x-light;
    box-shadow: none;

  }


  /*
   * Check icon
   */

  input[type="checkbox"]:hover + label > .o-icon-check,
  input[type="checkbox"]:focus + label > .o-icon-check {

    opacity: .2;

  }

  input[type="checkbox"]:checked + label > .o-icon-check {

    color: $color-text-strong-invert;
    opacity: 1;

  }

}

.o-checkbox + .o-checkbox {

  margin-top: -.3125rem;

}

.o-checkbox--s {

  label {

    padding: .375rem 0 .375rem 1.75rem;

    font-size: .875rem; // 14
    line-height: 1.428571428571429; // 14/20

  }

  label > .o-icon-check {

    top: .5rem;
    left: .125rem;

    font-size: 1rem;
  }

  label:before {

    top: .375rem;

    width: 1rem;
    height: 1rem;

  }

}

/*
 * Checkbox toggle
 */

.o-checkbox--toggle {

  label > .o-icon-check {

    display: none;

  }

  label {

    padding-left: 4rem;

  }

  label:before {

    width: 3rem;
    height: 1.5rem;

    border-radius: .875rem;

    transition: all .1s ease-in;

  }

  label:after {

    position: absolute;
    top: .875rem;
    left: .25rem;

    width: 1.25rem;
    height: 1.25rem;
    background-color: $color-input-regular;

    border-radius: .75rem;

    transition: all .1s ease-in;

    content: "";

  }

  input[type="checkbox"]:checked + label:before {

    background-color: $color-link;

  }

  input[type="checkbox"]:checked + label:after {

    left: 1.75rem;
    border-color: transparent;
    background-color: $color-text-strong-invert;

  }

  input[type="checkbox"]:disabled + label:after {

    background-color: #fff;

  }

  &.is-danger {

    input[type="checkbox"]:checked + label:before {

      background-color: $color-danger;

    }

  }

}

.o-checkbox--toggle.o-checkbox--s {

  label {

    padding-left: 2.75rem;

  }

  label:before {

    width: 2rem;
    height: 1rem;

    border-radius: .625rem;

  }

  label:after {

    top: .625rem;
    left: .25rem;

    width: .75rem;
    height: .75rem;

    border-radius: .375rem;

  }

  input[type="checkbox"]:checked + label:after {

    left: 1.25rem;

  }

}

.o-checkbox--s + .o-checkbox--s {

  margin-top: 0;

}

/*
 * Filter reset
 */

.o-reset {
  
  font-weight: $bold;
  cursor: pointer;
  
  &--link {
    //  Basically, everything we styled so far is "link" so we haven't
    //  defined anything here, but in case we want to have another style,
    //  it might be good to copy current styles to "link", and use other
    //  styles elsewhere. TBD.
  }
  
  &--s {

    padding: .375rem 0;

    font-size: .875rem; // 14
    line-height: 1.428571428571429; // 14/20

  }
  
  &--inline {
    
    padding-left: 1rem;
    padding-right: 1rem;
    white-space: nowrap;

  }
}

/*
 * Map
 *
 * Map container
 */

.o-map {

  position: relative;

  // @media screen and (min-height: 24em) {

  //   min-height: 20rem;

  // }

  // @media screen and (min-height: 32em) {

  //   min-height: 24rem;

  // }

  // @media screen and (min-height: 40em) {

  //   min-height: 32rem;

  // }

}

.o-map__content {

  position: relative;

  width: 100%;
  height: 50vh;
  min-height: 16rem;
  max-height: 32rem;

  background-color: $color-grey-x-light;

  @include breakpoint(large) {

    min-height: 24rem;

  }

}

.o-map__info {

  margin-top: 1rem;
  margin-bottom: 0;

  font-size: 1rem;
  line-height: 1.5;

}

.o-map__search {

  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-index-main;

  padding: .625rem;
  max-width: 20rem;
  width: 100%;

  input[type="text"] {

    height: 2.5rem;
    padding: $unit-xs $unit-s;
    width: 100%;

    font-size: 1rem;
    line-height: 1.5;

    border-radius: 0;
    box-shadow: $box-shadow-small;

    &:hover,
    &:focus {

      box-shadow: $box-shadow-medium;

    }

  }

}

.o-map__content img {

  display: flex;
  width: 100%;
  height: 100%;

  @include object-fit(cover, center);

}