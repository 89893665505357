/*
 * Blank state
 *
 * Blank placeholder states with title, description and important CTAs.
 */

.c-blank-state {

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  padding-top: 2rem;
  padding-bottom: 2rem;

  text-align: center;

}

.c-blank-state__title {}

.c-blank-state__description {

  max-width: 40em;

  // @include breakpoint(small) {

  //   font-size: 1.25rem;
  //   line-height: 1.6;

  // }

}