/*
 * Tags
 *
 * Simple tag styling used for marking up
 * new features e.g. "New".
 */

.o-tag {

  display: inline-block;
  padding-left: .375rem;
  padding-right: .375rem;

  color: $color-text-strong;
  background-color: $color-primary-light;

  font-size: .75rem;
  line-height: 1.6666666667;
  font-family: $font-headline;
  font-weight: $semibold;
  text-transform: uppercase;
  letter-spacing: .05em;

  border-radius: $border-radius-small;
  vertical-align: middle;

  margin-left: .25rem;
  margin-right: .25rem;

  .o-icon {

    vertical-align: middle;

    &:first-child {

      margin-right: .25rem;

    }

    &:last-child {

      margin-left: .25rem;

    }

  }

}

.o-tag.is-warning {

  background-color: $color-warning-tint;

}

.o-tag.is-danger {

  background-color: $color-danger-tint;

}

.o-tag.is-success {

  background-color: $color-success-tint;

}

.o-tag.is-highlight {

  background-color: $color-highlight-tint;

}

.o-tag.is-invert {

  color: $color-text-strong-invert;
  // background-color: $color-primary-dark;
  color: $color-text-strong;
  background-color: $color-accent-green;

}

.l-print {

  .o-tag {

    font-size: .625rem;
    line-height: 1.3333333333;

    -webkit-print-color-adjust: exact;

  }

}