/*
 * Upload preview
 *
 * Showing a selected media item
 */

.c-upload-preview {

  position: relative;

  display: flex;
  flex-direction: column;

}

.c-upload-preview__content {

  background-color: $color-link-tint;

  $background-square: 1rem;
  $background-square-color: tint($color-primary-dark, 85%);
  $background-square-half: $background-square * 0.5;

  background-image: linear-gradient(45deg, $background-square-color 25%, transparent 25%), linear-gradient(-45deg, $background-square-color 25%, transparent 25%), linear-gradient(45deg, transparent 75%, $background-square-color 75%), linear-gradient(-45deg, transparent 75%, $background-square-color 75%);
  background-size: $background-square $background-square;
  background-position: 0 0, 0 ($background-square-half), ($background-square-half) (0 - $background-square-half), (0 - $background-square-half) 0px;

  img {

    display: block;
    max-width: 100%;
    max-height: 40rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;

  }

}

.c-upload-preview__remove {

  position: absolute;
  top: -.5rem;
  right: -.5rem;

  border: none;

  padding: .5rem;
  width: 2.5rem;
  height: 2.5rem;

  border-radius: 1.25rem;

  color: $color-text-strong;
  background-color: $color-accent-green;

  .o-label {

    @include hide-visually();

  }

  .o-icon {

    font-size: 1.5rem;

  }

}

.c-upload-preview + .o-input {

  input,
  input[type="text"] {

    border-top-left-radius: 0;
    border-top-right-radius: 0;

    text-align: center;

  }

}

