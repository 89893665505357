/*
 * Switchable
 *
 * Structured content that can be toggled on and off:
 * - header always visible
 * - collapse/expand with a toggle icon
 * - custom title when expanded/collapsed
 * - nested switchable items
 * - action at the end
 */

.c-switchable-list {

  margin-bottom: $unit-m;

}

.c-switchable {

  position: relative;

  margin-bottom: $unit-s;

  border-radius: $border-radius-small;
  
  .o-tooltip + .o-tooltip {
    margin-left: $unit-xxs;
  }

}

.c-switchable__header {

  position: relative;
  // z-index: $z-index-switchable + 1;

  display: flex;
  align-items: center;

  background-color: $color-grey-x-light;
  border-radius: $border-radius-small;
  transition: $transition-default;

}

.c-switchable__title {

  position: relative;

  display: flex;
  flex: 1 1 100%;
  margin: 0;
  padding: .25rem 1rem;

  font-size: 1rem;
  line-height: 1.5;
  font-weight: $semibold;
  font-family: $font-body;

  overflow: hidden;
  height: 2rem;
  
  padding-right: rem(36);

  &:before {

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 2rem;

    background-image: linear-gradient(to right, rgba($color-grey-x-light,0), rgba($color-grey-x-light,1));

    content: "";

    //  =Info: 2022-01-31 (Emanuel)
    //  This part is now hidden everywhere to allow for other color changes
    //  to work seamlessly (i.e. when "is-conditional"), as I haven't seen
    //  where is this used and in what capacity. If needed, it can be reinstated
    //  but we need to make sure it is really needed (and update conditional/future color
    //  coded questions accordingly)
    display: none;

  }

  .c-switchable__title-name {

    white-space: nowrap;

  }

  .c-switchable__title-addon-closed,
  .c-switchable__title-addon-opened {

    margin-left: .5rem;

    color: $color-text-weak;

    // overflow: hidden;
    // white-space: nowrap;

  }
  
  .c-switchable__title-number {
    background: $color-accent-blue;
    color: $color-primary-light;
    line-height: rem(24);
    min-width: rem(28);
    height: rem(24);
    text-align: center;
    border-radius: $border-radius-small;
    position: relative;
    top: rem(-1);
    margin-right: $unit-xs;
  }

}

.c-switchable__type {

  flex: 1 0 3.5rem;
  height: 3.5rem;
  max-width: 3.5rem;
  padding: 1.125rem 0;

  font-size: 1.25rem;
  line-height: 1;
  color: $color-text-weak;
  text-align: center;

  transition: all .1s ease-in;

  .o-icon {

    width: 2rem;

  }

  .o-icon-circle {}

  .o-icon-dot-circle {

    color: $color-text-strong;

  }

  .o-icon-check-circle {

    color: $color-success;
  }

  .o-icon-triangle {

    color: $color-danger;

  }

}

.c-switchable__handle {

  display: none;

}

.c-switchable__type + .c-switchable__title {

  margin-left: -1rem;

}

.c-switchable__toggle {

  position: relative;

  flex: 1 0 3.5rem;
  height: 3.5rem;
  max-width: 3.5rem;
  padding: 1.125rem 0;
  margin-left: auto;

  font-size: 1.25rem;
  line-height: 1;
  color: $color-text-weak;
  text-align: center;

  background: transparent;
  border: none;

  .o-label {

    @include hide-visually();

  }

  .o-icon {

    position: relative;

    margin-left: auto;
    margin-right: auto;

    transition: all .1s ease-in;

  }

  .o-icon-plus {

    display: block;

  }

  .o-icon-minus {

    display: none;

  }

  &:hover,
  &:focus,
  &:active {

    color: $color-link;
    outline: none;

    &:before {

      background-color: $color-link-tint;
      background-color: rgba($color-link, .1);

    }

  }

  &:before {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;

    background-color: rgba(255,255,255,0);
    transition: all .1s ease-in;

    content: "";

  }

}

.c-switchable__content {

  position: relative;
  z-index: $z-index-switchable;

  padding: 1rem;
  background-color: #fff;

  border-radius: 0 0 $border-radius-small $border-radius-small;

  @include breakpoint(medium) {

    padding: 2rem;

  }

}

.c-switchable__nested {}

.c-switchable__actions {

  margin-top: 1rem;

}


/*
 * Expand/collapsed states
 */

.c-switchable.is-closed {

  .c-switchable__title-addon-closed {

    display: block;

  }

  .c-switchable__title-addon-opened {

    display: none;

  }

  .c-switchable__content {

    display: none;

  }

}

.c-switchable.is-opened {

  position: relative;
  z-index: $z-index-switchable + 2;

  border-radius: $border-radius-small;

  > .c-switchable__header {

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    background-color: $color-edit-tint !important;
    
    z-index: $z-index-switchable + 1;

  }

  > .c-switchable__header .c-switchable__title:before {

    background-image: linear-gradient(to right, rgba($color-edit-tint,0), rgba($color-edit-tint,1)) !important;

  }

  > .c-switchable__header .c-switchable__title-addon-closed {

    display: none;

  }

  > .c-switchable__header .c-switchable__title-addon-opened {

    display: block;

  }

  > .c-switchable__header .c-switchable__toggle {

    .o-icon-plus {

      transform: rotate(45deg);

    }

  }

  > .c-switchable__content {

    display: block;

    box-shadow: $box-shadow-extra-large;

  }
  
  .c-switchable-list--polls & {
    
    .c-switchable__header:hover {
      
      //  ----------------------------
      //  Update 2024-04-22: Please read carefully
      //  After further testing for some issues with tooltips and assisted translations
      //  we figured out  there was still an issue in some cases, when tooltip
      //  is being shown in .c-switchable__header (e.g. for conditional logic information).
      //  To fix this, we have to make parent element use higher z-index value.
      //  We tried different approaches, but basically we are targeting
      //  .c-switchable__header *only* when it's hovered.
      //  We also tried to target :has(.o-tooltip__action:hover) but it was sluggish.

      z-index: calc($z-index-tooltip + 1) !important;
    }
    
    //  ----------------------------
    //  Info: Please read carefully!

    //  We had to change the styling for the "switchable" component
    //  since we had overlapping when using tooltips (i.e. for assisted 
    //  translations). See issue #1875
    //  https://github.com/sdesregistry/IH-Engage/issues/1875
    
    //  To overcome the issue, box-shadow has to be applied to "switchable"
    //  component, and not on its content element. With that in place, we could
    //  make the content element use higher z-index value so there's no 
    //  overlapping when the tooltip is active/hovered.

    box-shadow: $box-shadow-extra-large;
    
    > .c-switchable__content {
      box-shadow: none;
    }
  }
  
  > .c-switchable__content {
    z-index: $z-index-tooltip;
  }

}

/*
 * Nested
 */

.c-switchable--nested {

  margin: .25rem 0;

  .c-switchable__header {

    position: relative;

    background-color: transparent;

    &:before {

      position: absolute;

      top: -.125rem;
      left: 3.5rem;
      right: 0;
      height: .0625rem;

      background-color: $color-border-weak;

      content: "";

    }

  }

  .c-switchable__title {

    &:before {

      background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));

    }

  }

}

.c-switchable--nested.is-opened {

  &:before,
  &:after {

    display: none;

  }

}

.c-switchable--nested:first-child,
.c-switchable--nested.is-opened,
.c-switchable--nested.is-opened +.c-switchable--nested {

  .c-switchable__header:before {

    display: none;

  }

}

/*
 * Reorder
 */

.c-switchable--reorder {

  .c-switchable__header {
    //  =Note:
    //  Fix for the issue [High priority] Text Selection During Question Reordering #2304
    //  https://github.com/sdesregistry/IH-Engage/issues/2304
    
    //  This fix prevents switchable header from being selected (i.e. text won't be available for selection).
    //  This impacts elements that is part of the reorder group only. (won't impact other switchable headers).
    //  See further explanation a few lines below.
    
    user-select: none !important;
  }

  .c-switchable__handle {

    display: block;

    position: relative;
    z-index: $z-index-switchable;

    height: 3.5rem;
    padding: 1rem 2.75rem 1rem .25rem;
    border: none;

    text-align: center;
    color: rgba($color-text, .4);
    background-color: transparent;

    cursor: move;
    @include prefixer(cursor, grab, ("webkit", "moz"));
    
    //  =Note:
    //  Issue #2304 (continued)
    //  Adding this line is a better approach, since it doesn't impact header element
    //  but unfortunately it doesn't fix the issue in Safari so we are targeting header
    //  element instead. Leaving this here for clarity
    // user-select: none !important;

    transition: all .1s ease-in;

    .o-icon {

      position: relative;
      font-size: 1.5rem;
      width: 1.5rem;

    }

    .o-label {

      @include hide-visually;

    }

    &:hover,
    &:focus,
    &:active {

      color: $color-link;

    }

    &:active {

      .o-icon {

        top: .0625rem;

      }

    }

  }

  .c-switchable__handle + .c-switchable__type {

    margin-left: -3.5rem;

  }

}

.c-switchable--reorder.c-switchable--nested {

  &:before,
  &:after {

    left: 2.75rem;

  }

  .c-switchable__header:before {

    left: 4.5rem;

  }

}

/*
 * Modifiers
 */

.c-switchable.is-active,
.c-switchable.is-success {

  .c-switchable__header:before {

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: .125rem;

    background-color: $color-success;
    border-top-left-radius: $border-radius-small;
    border-bottom-left-radius: $border-radius-small;

    content: "";

  }

  &.is-opened .c-switchable__header:before {

    border-bottom-left-radius: 0;

  }

}

.c-switchable.is-active {

  .c-switchable__title:before {

    background-image: linear-gradient(to right, rgba($color-edit-tint,0), rgba($color-success-tint,1));

  }

}

.c-switchable.is-warning {

  .c-switchable__header:before {

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: .125rem;

    background-color: $color-warning;
    border-top-left-radius: $border-radius-small;
    border-bottom-left-radius: $border-radius-small;

    content: "";

  }

  &.is-opened .c-switchable__header:before {

    border-bottom-left-radius: 0;

  }

}

.c-switchable.is-danger {

  .c-switchable__header:before {

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: .125rem;

    background-color: $color-danger;
    border-top-left-radius: $border-radius-small;
    border-bottom-left-radius: $border-radius-small;

    content: "";

  }

  &.is-opened .c-switchable__header:before {

    border-bottom-left-radius: 0;

  }

}

.c-switchable.is-error {
  
  .c-switchable__header {

    background: $color-error !important;
    
    * {

      color: $color-white;

    }

  }
  
  .c-switchable__title:before {

    display: none;

  }
}

.c-switchable.is-error,
.c-switchable.is-conditional {
  
  .o-tooltip .o-icon {

    //  20px width
    width: 1.25em;
    height: 1.25em;
    top: rem(3);

  }
  
  .o-tooltip__description {

    background-color: transparent-color($color-black, .9);
    color: $color-white;

  }
  
  .o-tooltip--bottom-center .o-tooltip__description:before { border-bottom-color: transparent-color($color-black, .9); }
  .o-tooltip--top-center .o-tooltip__description:before { border-top-color: transparent-color($color-black, .9); }
  .o-tooltip--left-center .o-tooltip__description:before { border-right-color: transparent-color($color-black, .9); }
  .o-tooltip--right-center .o-tooltip__description:before { border-left-color: transparent-color($color-black, .9); }
}

.c-switchable.is-active {

  .c-switchable__header {

    background-color: $color-success-tint;

  }

  .c-switchable__title:before {

    background-image: linear-gradient(to right, rgba($color-edit-tint,0), rgba($color-success-tint,1));

  }

  &.is-opened .c-switchable__title:before {

    background-image: linear-gradient(to right, rgba($color-edit-tint,0), rgba($color-success-tint,1));

  }

}

.c-switchable.is-conditional {
  
  //  Here we're defining how the background changes based on if "conditional
  //  logic" is active or not. If it is active, it will have "is-conditional" class
  //  and when this happens, it will have a different background gradient.

  //  This gradient, for base color, uses the same color that is defined for
  //  $conditional-background-color in /components.conditional-logic.scss
  //  Remember to update this value if the value in above component changes.
  
  $diagonal-spacing: rem(4);
  $background-color: transparent-color($color-black, .06);
  
  .c-switchable__header {
    background-image: repeating-linear-gradient(45deg, transparent, transparent $diagonal-spacing, $background-color $diagonal-spacing, $background-color $diagonal-spacing*2);
  }
  
  &.is-opened .c-switchable__content .c-tabs-navigation:first-child {
    background-image: repeating-linear-gradient(45deg, transparent, transparent $diagonal-spacing, $background-color $diagonal-spacing, $background-color $diagonal-spacing*2);
    background-position: -5px 0;
  }
}

/*
 * Timeline
 *
 * Nested styles
 */

.c-switchable-list--timeline {

  .c-switchable--nested {

    // Small top/bottom borders for timeline

    &:before,
    &:after {

      position: absolute;
      left: 1.75rem;

      width: .0625rem;
      height: 1.25rem;

      background-color: $color-border-normal;

      content: "";

    }

    &:before {

      top: -.625rem;

    }

    &:after {

      bottom: -.625rem;

    }

  }

}

/*
 * Polls
 *
 * Nested styles
 */

.c-switchable-list--polls {

  .c-switchable__header:hover {

    background-color: $color-primary-light;

    .c-switchable__title:before {

      background-image: linear-gradient(to right, rgba($color-grey-x-light,0), rgba($color-grey-x-light,1));


    }

  }

}

/*
 * Switchable tabs
 *
 * This component is used to have tabs functionality as part of .c-switchable__content
 */

.c-switchable.is-opened {
  
  .c-switchable__content .c-tabs-navigation:first-child {
    
    //  Styles for tabs we are using in switchable component.
    //  Basic tabs style are defined in components.tabs.scss.
    //  These styles are for now used to have conditional logic functionality,
    //  but might be rolled out for other features in the future.
    
    background: $color-edit-tint;
    // padding: 0 $unit-l 0 rem(72);
    padding: 0 $unit-l;
    margin: -$unit-l;
    margin-bottom: $unit-l;
    
    .c-tabs-navigation__container {
      margin: 0;
      border: 0;
    }
    
    .c-tabs-navigation__item {
      
      button {
        border: 0 !important;
        background: transparent-color($color-black, .05);
        margin-bottom: 0 !important;
        
        &[aria-selected="true"] {
          background: $color-white;
        }
      }
    }
  }
}