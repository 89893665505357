/*
 * Filter dropdown
 *
 * Filter reports with specific data sets
 */

.c-filter-dropdown {

  position: absolute;
  top: 100%;
  left: 0;
  z-index: $z-index-dropdown;

  max-width: 24rem;
  margin-top: $unit-xs;

  border-radius: $border-radius-small;
  box-shadow: $box-shadow-large;
  background-color: #fff;
  
  min-width: 20rem;

}

.c-filter-dropdown__search {

  position: relative;

  padding: $unit-s;
  padding-bottom: 0;

  &:before {

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: $z-index-dropdown;

    height: $unit-s;
    background-image: linear-gradient(to bottom, #fff 50%, rgba(#fff, 0));

    content: "";

  }
  
  + .c-filter-dropdown__content .c-filter-select .c-filter-select__group:first-of-type .c-filter-select__title:before {
    display: block;
  }

}

.c-filter-dropdown__content {

  overflow-y: auto;
  max-height: 30rem;
  padding: $unit-s;
  
  .c-filter-select .c-filter-select__group:first-of-type .c-filter-select__title:before {
    display: none;
  }

  // For scrolling purposes
  &:after {

    display: block;
    height: $unit-s;
    margin-bottom: 0 - $unit-s;

    content: "";

  }

}

  
.c-filter-select__button {
  //  Loosely based on .c-filter-select__title (font-size, color, weight)

  font-size: 1rem;
  font-family: $font-body;
  color: $color-text;
  font-weight: $semibold;
  letter-spacing: 0;
  background-color: $color-primary-light;
  border: 0;
  width: 100%;
  display: flex !important;
  align-items: center;
  min-height: rem(44);
  border-radius: $border-radius-small;
  padding: $unit-xxs $unit-s;
  cursor: pointer;
  
  &.is-selected {
    background: $color-accent-green;
  }
}

.c-filter-select__image {
  
  img {
    max-width: rem(32);
    margin: 0 $unit-xs 0 0;
    position: relative;
  }
}

.c-filter-dropdown--reactions,
.c-filter-set--icons .c-filter-dropdown {
  
  .c-filter-select__choices {
    padding: 0;
    display: flex;
    flex-direction: column;
    grid-gap: $unit-xxs;
  }
  
  .c-filter-select__title .additional__info {
    color: transparent-color($color-text, .5);
    font-weight: $regular;
    font-size: rem(14);
    position: relative;
    padding-left: $unit-xs;
  }
}