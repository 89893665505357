
//  ---------------
//  Global rating rules

  $rating-size: 56px;
  $rating-horizontal-padding: 12px;
  $rating-vertical-padding: 4px;
 
.c-rating__group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-left: 0;
  margin-bottom: $unit-m;
  max-width: rem(500);
  z-index: 10;
  
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  
  > * {
    margin: auto;
  }
  
  .c-rating__control {
    margin-bottom: 0;
  }
  
  .c-rating__labels {
    margin-top: 0;
  }
}

.c-rating__labels {
  // min-width: 100%;
  
  .c-library & {
    max-width: 100%;
  }

  ol {
    padding: 0;
    list-style: none;
    margin: auto;
    justify-content: center !important;
    grid-gap: rem(4);
  }
  
  li {
    margin: 0;
    text-align: center;
    width: rem(44);
    background: rgba(0,0,0,.07);
    border-bottom-left-radius: $border-radius-small;
    border-bottom-right-radius: $border-radius-small;
    font-size: rem(12);
    padding: rem($rating-vertical-padding) 0;
    font-weight: $bold;
  }
  
  .c-rating__labels-container {
    display: flex;
    justify-content: space-between;
    padding: 0 rem($rating-horizontal-padding);
    
    > span {
      width: 48%;
      min-width: rem(120);
    }
  }
  
  span {
    font-size: rem(15);
    line-height: 1.2;
    padding: rem($rating-vertical-padding) 0;
    color: $color-text-weak;
  }
  
  .c-rating__label-item-low { 
    text-align: left;
  }
  
  .c-rating__label-item-high {
    text-align: right;
  }
}

 
.c-rating__control {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(172,236,255);
  background-image: 
    linear-gradient(90deg, rgba(129,192,238,1) 0%, rgba(129,192,238,0) 2%),
    linear-gradient(270deg, rgba(129,192,238,1) 0%, rgba(129,192,238,0) 2%),
    linear-gradient(180deg, rgba(172,236,255,1) 0%, rgba(172,236,255,1) 2%, rgba(248,255,255,1) 4%, rgba(154,219,255,1) 6%, rgba(142,206,255,1) 14%, rgba(137,200,248,1) 34%, rgba(135,198,246,1) 48%, rgba(129,192,238,1) 90%, rgba(125,187,232,1) 92%, rgba(122,184,227,1) 97%, rgba(125,188,232,1) 100%);
  border-radius: rem(6);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
  padding: rem($rating-vertical-padding) rem($rating-horizontal-padding);
  position: relative;
  z-index: 2;
  
  .c-library & {
    max-width: 100%;
  }
  // width: 100%;
  
  .rating-item--0 {
    display: none;
  }

  //  ------------------------------
  //  Styles for inputs/labels are as follows

  //  ------------------------------  
  //  PNG Sprite Background Positions
  //  Normal: 0 0
  //  Hover: 100% 0
  //  Checked: 0 100%;
  //  Checked Hover: 100% 100%;
  //  ------------------------------  

  label {
    display: block;
    width: rem(48);
    height: rem(48);
    background-repeat: no-repeat;
    background-size: 200% 200%;
    background-position: 0 100%;
    pointer-events: none;
  }
  
  input {
    
    &:checked {
      ~ label {
        background-position: 0 0;
      }

      + label {
        background-position: 0 100% !important;
      }
    }
  }
}