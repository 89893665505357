/*
 * Result slider
 *
 * slider result has a collection of different values:
 * - title
 * - votes
 * - image
 * - bar
 * - bar comparison
 */

 $result-slider-indicator-handle: 1rem;
 $result-slider-media: 8rem;
 $result-slider-percent: 5rem;

.c-result-slider {

  display: flex;
  align-items: center;
  margin-top: $unit-xs;

  &:first-child {

    margin-top: 0;

  }

}

.c-result-slider__media {

  flex: 1 0 auto;

  margin-top: .125rem;
  margin-bottom: .125rem;
  margin-right: $unit-m;

  img {

    height: 4rem;
    margin: 0;

    border-radius: $border-radius-small;

    @include breakpoint(medium) {

      height: $result-slider-media;

    }

  }

}

.c-result-slider__content {

  width: 100%;
  display: flex;
  flex-flow: row wrap;

}

.c-result-slider__title {

  margin-right: $unit-xs;
  margin-bottom: .25rem;

  font-size: 1rem;
  line-height: 1.5;
  font-weight: $semibold;

  @include breakpoint(medium) {

    font-size: 1.125rem;
    line-height: 1.5555555556;

  }

}

.c-result-slider__result,
.c-result-slider__compare {

  position: relative;

  display: flex;
  align-items: center;
  width: 100%;
  min-height: 1.5rem;
  padding-right: $result-slider-percent;

}

.c-result-slider__compare {

  display: none;

}

.c-result-slider__value {

  width: $result-slider-percent;

  font-size: 1rem;
  font-weight: $semibold;
  text-align: right;
  color: darken($color-accent-green, 10%);

  position: absolute;
  right: 0;
  bottom: 0;

  .c-result-slider__compare & {

    top: 0;
    bottom: auto;
    color: darken($color-accent-red, 10%);

  }

  @include breakpoint(small) {

    font-size: 1.125rem;
    line-height: 1.3333333333;

  }

  @include breakpoint(medium) {

    font-size: 1.25rem;
    line-height: 1.2;

  }

}

.c-result-slider__bar {

  width: 100%;
  margin: .0625rem 0;

  background-color: $color-primary-light;
  border-radius: .375rem;

}

.c-result-slider__bar-filled {

  position: relative;
  height: .375rem;

  background-color: $color-accent-green;
  border-radius: .375rem;

  -webkit-print-color-adjust: exact;

  &:before {

    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);

    width: 1rem;
    height: 1rem;

    background-color: $color-primary-dark;
    border-radius: 50%;

    content: "";

  }

  .c-result-slider__compare & {

    background-color: $color-accent-red;

  }

}

.c-result-slider.is-highlight {

  .c-result-slider__media img {

    box-shadow: 0 0 0 .25rem $color-accent-green;

  }

}

/*
 * Modifier: Emoji
 */

.c-result-slider--emoji {

  /*
   * Emojis
   *
   * 1. Define sizes for emojis based on a image sprite
   * 2. Define number of emoji states (first state is no action) NOTE: currently not in use
   */

  $emoji-number: 8;
  $emoji-size: 3rem;

  .c-result-slider__bar-filled:after {

    position: absolute;
    bottom: 100%;
    left: 100%;
    transform: translate(-50%, 0);

    width: 3rem;
    height: 3rem;
    margin-bottom: $unit-xs;

    background-image: assets-path('images/interface/slider-emoji.png');
    background-repeat: no-repeat;
    background-size: ($emoji-size * $emoji-number) $emoji-size;

    // background-color: $color-primary-dark;
    border-radius: 50%;

    content: "";

  }

  @for $emoji-number from 1 through $emoji-number {

    .c-result-slider__bar-filled.is-emoji-#{$emoji-number}:after { background-position: -($emoji-size * $emoji-number) 0; }

  }

}

/*
 * Compared active
 */

.c-result-slider--compare {

  .c-result-slider__compare {

    display: flex;

    .c-result-slider__bar-filled:after {

      top: 100%;
      bottom: auto;

      margin-bottom: 0;
      margin-top: $unit-xs;

    }

    .c-result-slider__value {

      font-size: 1.125rem;

    }

  }

}