/*
 * New community
 *
 * Onboarding process for creating
 * a new community project.
 *
 */


.c-community-new {

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  padding-top: 2rem;
  padding-bottom: 2rem;

  text-align: center;

}

.c-community-new__title {}

.c-community-new__description {

  max-width: 40em;

  // @include breakpoint(small) {

  //   font-size: 1.25rem;
  //   line-height: 1.6;

  // }

}

.c-community-new .l-form {

  margin-top: 2rem;
  width: 100%;
  max-width: 30rem;

  @include breakpoint(large) {

    width: span(5 of 9);

  }

}

.c-community-new .c-field--action .c-field__group {

  justify-content: center;

  .o-button {

    width: 100%;

  }

}