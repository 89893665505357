/*
 * Nav drawer
 *
 * Drawar navigation pattern styles
 */

.c-nav-drawer {}

.c-nav-drawer__items {

  list-style: none;
  margin: 0;
  padding: 0;

}

.c-nav-drawer__items + .c-nav-drawer__items {

  padding-top: 1rem;

}

.c-nav-drawer__item {

  margin: 0;

  font-size: 1.125rem;
  line-height: 1.7777777778;
  font-weight: $semibold;

}

.c-nav-drawer__link {

  display: flex;

  padding: .75rem 1.25rem; // 1rem 1.25rem

  color: $color-text-strong;
  border-radius: $border-radius-small;

  .o-icon {

    margin-top: auto;
    margin-bottom: auto;
    margin-right: .75rem;
    width: 1.5rem;

    font-size: 1.5rem;
    color: $color-text-weak;

    transition: all .1s ease-in;

  }

  .o-tag {

    margin-top: auto;
    margin-bottom: auto;

  }

  &:hover,
  &:focus,
  &:active {

    color: $color-link;
    background-color: $color-link-tint;

    .o-icon {

      color: $color-link;

    }

  }

}

.c-nav-drawer__item--notification  {

  > .c-nav-drawer__link:before {

    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);

    border-radius: .25rem;
    width: .5rem;
    height: .5rem;

    background-color: $color-notification;

    content: "";

  }

  &.is-active > .c-nav-drawer__link:before {

    opacity: 0;

  }

}

.c-nav-drawer__children {

  display: none;

  list-style: none;
  margin: 0;
  padding: 0;

  .c-nav-drawer__item {

    font-size: 1rem;
    line-height: 1.5;
    font-weight: $regular;

  }

  .c-nav-drawer__link {

    color: $color-text;

    padding-top: .5rem;
    padding-bottom: .5rem;

    &:hover,
    &:focus,
    &:active {

      color: $color-link;

    }

  }

}

.c-nav-drawer__item.is-active {

  > .c-nav-drawer__children {

    display: block;

  }

  > .c-nav-drawer__link {

    // color: $color-text-strong-invert;
    // background-color: $color-link;
    color: $color-text-strong;
    background-color: $color-accent-green;

    .o-icon {

      color: $color-text;
      // color: $color-text-strong-invert;

    }

  }

}

.c-nav-drawer__item.is-parent-active {

  > .c-nav-drawer__children {

    display: block;

  }

}

.c-nav-drawer__title {

  display: flex;
  align-items: center;

  min-height: 4rem;
  padding: 0 .4375rem;
  border: .0625rem solid $color-grey-x-light;
  margin-bottom: 1rem;

  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: $font-body;
  font-weight: $medium;
  color: $color-text-strong;

  border-radius: $border-radius-small;

  @include breakpoint(small) {

    margin-bottom: 1.5rem;

  }

}

.c-nav-drawer__avatar {

  width: 2.5rem;
  height: 2.5rem;

  border-radius: 50%;

  margin-right: .5rem;
  margin-bottom: 0;

}

.c-nav-drawer__back + .c-nav-drawer__avatar {

  margin-left: 0;

}

.c-nav-drawer__back {

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 2.5rem;
  min-height: 3rem;
  border-right: .0625rem solid $color-grey-x-light;

  margin-right: .5rem;

  color: $color-text-weak;
  border-radius: $border-radius-small;
  transition: all .1s ease-in;

  .o-label {

    @include hide-visually();

  }

  .o-icon {

    font-size: 1.5rem;
    margin-left: -.125rem;

    transition: all .1s ease-in;

  }

  &:hover,
  &:focus,
  &:active {

    color: $color-link;
    background-color: $color-link-tint;
    border-color: transparent;

    .o-icon {

      color: $color-link;

    }

  }

}

/*
 * Icons modifier
 *
 * Adds necessary spacing to adjust left padding
 * on first and second level items.
 */

.c-nav-drawer--icons {

  .c-nav-drawer__link {

    position: relative;

    padding-left: 3.5rem;

  }

  .c-nav-drawer__link .o-icon {

    position: absolute;
    top: 50%;
    left: 1.75rem;
    transform: translate(-50%, -50%);

    font-size: 1.5rem;

  }

}

@include breakpoint(medium) {

  .c-nav-drawer__items {

    // padding-right: 1.5rem;
    // border-right: .0625rem solid $color-grey-x-light;

  }

  .c-nav-drawer__items + .c-nav-drawer__items {

    padding-top: 1.5rem;

  }

}

/*
 * Mobile and tablet
 *
 * Screen takeover styles
 */

.c-nav-drawer__toggle {

  position: relative;

  display: flex;
  width: 100%;
  height: 3rem;

  // border: .0625rem solid $color-grey-x-light;
  border: none;
  padding: 0;

  background-color: transparent;
  background-color: $color-link-tint;
  border-radius: $border-radius-small;

  .o-icon {

    font-size: 1.5rem;
    color: $color-text;

  }

  @include breakpoint(medium) {

    height: 4rem;

  }

}

.c-nav-drawer__breadcrumbs {

  overflow: hidden;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  padding-left: .5rem;

  font-size: 1rem;
  font-family: $font-body;
  font-weight: $semibold;
  color: $color-text-strong;

  margin-top: auto;
  margin-bottom: auto;

  white-space: nowrap;

  .o-label:first-of-type {

    // font-weight: $semibold;
    // color: $color-text-strong;
    margin-right: .5rem;

  }

  .o-icon {

    font-size: 1.5rem;
    min-width: 1.5rem;
    margin-right: .75rem;

  }

  .c-nav-drawer__avatar {

    width: 2rem;
    height: 2rem;

  }

  @include breakpoint(medium) {

    padding-left: 1rem;

    .c-nav-drawer__avatar {

      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1rem;

    }

  }

}

.c-nav-drawer__toggle-indicator {

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 2.875rem;
  min-height: 2.875rem;
  height: 100%;
  margin-left: auto;

  background-image: linear-gradient(to right, rgba($color-link-tint,0), rgba($color-link-tint,1) 50%);

  .o-icon-times {

    display: none;

  }

  .o-icon-ellipsis-v,
  .o-icon-angle-down {

    display: block;

  }

  @include breakpoint(medium) {

    width: 4rem;

  }

}




@include breakpoint(to-large) {

  .c-nav-drawer {

    .c-nav-drawer__header,
    .c-nav-drawer__content {

      // display: none;

    }

  }

  .c-nav-drawer-opened {

    position: fixed;
    z-index: $z-index-modal;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-flow: column;

    background-color: #fff;

    &:before {

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: $z-index-modal + 1;

      height: 1rem;
      background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));

      content: "";

    }

    .c-nav-drawer__header {

      display: block;

      min-height: 4rem;

      box-shadow: inset 0 -.0625rem 0 0 $color-grey-x-light;

    }

    .c-nav-drawer__toggle {

      position: absolute;
      z-index: $z-index-modal + 1;
      top: 0rem;
      right: 0rem;
      width: auto;
      height: 4rem;
      border: none;

      border-radius: 0;
      background-color: transparent;

      &:hover,
      &:focus,
      &:active {

        .o-icon {

          color: $color-text-strong;

        }

      }

    }

    .c-nav-drawer__breadcrumbs {

      display: none;

    }

    .c-nav-drawer__toggle-indicator {

      display: none;

      min-width: 4rem;
      min-height: 4rem;
      background: transparent;

      .o-icon-times {

        display: block;

      }

      .o-icon-ellipsis-v,
      .o-icon-angle-down {

        display: none;

      }

    }

    .c-nav-drawer__title {

      border: none;
      margin: 0;
      margin-right: 4rem; // Make space for "close"

    }

    .c-nav-drawer__content {

      display: block;
      height: 100%;
      padding: 1rem;

      // overflow-y: auto;

      // Momentum scrolling
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

    }

  }

}

@include breakpoint(large) {

  .c-nav-drawer__toggle {

    display: none;

  }

}