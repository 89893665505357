/*
 * Filter set
 *
 * Filter options used in the reports
 */

.c-filter-set {

  position: relative;

  margin-bottom: $unit-m;

}

.c-filter-set__add {}

.c-filter-set__group {

  position: relative;

  padding: 0 $unit-xl;
  margin-bottom: $unit-s;

  border-radius: $border-radius-small;
  background-color: $color-primary-light;

}

.c-filter-set__info {

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: $unit-xs 0;
  min-height: $unit-xl;

  .o-icon {

    font-size: 1.25rem;

  }

}

.c-filter-set__type {

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  width: $unit-xl;
  height: $unit-xl;
  align-items: center;
  justify-content: center;
  padding: 0;

  .o-icon {

    min-width: 1.25rem;

  }

}

.c-filter-set__poll {

  margin-bottom: 0;
  margin-right: $unit-xs;

  font-size: 1rem;
  line-height: 1.5;
  font-family: $font-body;
  letter-spacing: 0;
  color: $color-text;

}

.c-filter-set__poll + .o-icon {

  margin-right: $unit-xs;

}

.c-filter-set__question {

  margin-bottom: 0;

  font-size: 1rem;
  line-height: 1.5;
  font-family: $font-body;
  letter-spacing: 0;
  color: $color-text;

}

.c-filter-set__choices {

  display: flex;
  flex-flow: row wrap;
  padding: ($unit-xs * 0.5) 0;

  box-shadow: inset 0 .0625rem 0 0 rgba($color-primary-dark, .05);

  .o-radio--button {

    margin-top: ($unit-xs * 0.5);
    margin-bottom: ($unit-xs * 0.5);

    label {

      background-color: #fff;

    }

  }

  .o-radio--button.o-radio--inline {

    margin-right: $unit-xs;

  }

}

.c-filter-set__clear {

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  width: $unit-xl;
  height: $unit-xl;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:hover,
  &:focus,
  &:active {

    background: none;

  }

  .o-label {

    @include hide-visually();

  }

}

.c-filter-dropdown {


  display: none;

}


//  Filter set: "Icons" variation
//  This filter set uses icons as an added value. 
//  Please note: There can be only one level of options for this filter set!

.c-filter-set--icons {
  
  .c-filter-set__add {
    
    button {
      width: 100%;
      display: flex;
      align-items: center;
      grid-gap: $unit-xxs;
    }
    
    .o-icon {
      margin-left: auto;
    }
  }
  
  .c-filter-select__image {
    
    img {
      max-height: rem(24);
    }
  }
  
  .l-form__item & {
    margin: 0;
    
    button {
      background-color: #fff;
      border-radius: $border-radius-small !important;
      overflow: hidden;
      box-shadow: inset 0 0 0 .0625rem $color-input-regular;
      height: rem(48);
      border: none;
      
      &:hover {
        box-shadow: inset 0 0 0 .125rem $color-input-focus;
      }
      
      &:focus {
        background-color: $color-grey-x-light;
      }
    }
    
    .c-filter-set__add .o-label {
      font-weight: $regular;
    }
    
    .c-filter-dropdown {
      max-width: none;
      width: 100%;
    }
  }
}
