/* =Custom mixins
   ===================================================== */

 /*
  * Object fit mixin
  *
  * This mixin can be used to set the object-fit:
  * @include object-fit(contain);
  * or object-fit and object-position:
  * @include object-fit(cover, top);
  *
  * Source: https://github.com/bfred-it/object-fit-images/blob/master/preprocessors/mixin.scss
 */

@mixin object-fit($fit: fill, $position: null){

  object-fit: $fit;

  @if $position {

    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';

  } @else {

    font-family: 'object-fit: #{$fit}';

  }

}



/*
 * List reset
 *
 * Resets list items
 */

@mixin list-reset() {
  list-style: none;
  margin: 0;
  padding: 0;
}