/*
 * Sentiment stats
 *
 * Showing positive, negative, neutral and mixed indicator
 * along with percentages and labels.
 */

.c-sentiment-stats {

  margin-bottom: $unit-m;

  display: flex;
  flex-flow: row wrap;
  align-items: center;

}

.c-sentiment-stats__label {

  margin-bottom: 0;
  margin-right: $unit-s;

  font-size: 1rem;
  line-height: 1.5;
  font-family: $font-body;
  letter-spacing: 0;

}

.c-sentiment-stats__items {

  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;

  &:first-child {

    margin-top: 0;

  }

  li {

    margin-top: 0;
    margin-right: $unit-xs;
    display: inline-block;

    font-size: .875rem;

  }

  .o-icon {

    position: relative;
    top: .125rem;
    margin-right: .25rem;

    font-size: 1rem;

  }

  // Fix for HTML2Canvas reports and the extra
  // div we add around the inline SVG icon
  li > .o-icon--inline {

    top: .125rem;
    margin-right: .25rem;

    .o-icon {

      top: 0;
      display: block;
      margin-right: 0;

    }

  }

  // Positive
  .o-icon-grin      { color: $color-sentiment-positive; }
  // Mixed
  .o-icon-meh       { color: $color-sentiment-mixed; }
  // Negative
  .o-icon-frown     { color: $color-sentiment-negative; }
  // Neutral
  .o-icon-meh-blank { color: $color-sentiment-neutral; }

}

/*
 * Print report
 *
 * Modifications for printed reports
 */

// .l-print {

//   .c-sentiment-stats .o-tooltip:before {

//     content: "(";

//   }

//   .c-sentiment-stats .o-tooltip:after {

//     content: ")";

//   }

// }