.c-header-toggle {


}

.c-header-toggle__menu,
.c-header-toggle__search {

  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  border: none;
  height: 48px;
  width: 48px;
  padding: 12px;

  font-size: 1.5rem;
  line-height: 1;
  color: $color-text-invert;
  background: transparent;

  &:hover,
  &:focus {

    color: $color-text-strong-invert;

  }

}

.c-header-toggle__label {

  @include hide-visually;

}

.c-header-toggle__menu {

  right: 8px;

}

.c-header-toggle__search {

  left: 8px;

}

@include breakpoint(large) {

  .c-header-toggle {

    display: none;

  }

}