/*
 * List settings
 *
 * List of items with several settings:
 * - Name (can be a checkbox)
 * - Options (e.g. radio buttons)
 */

.c-list-settings {

  list-style: none;
  margin: 0;
  padding: 0;

  box-shadow: $box-shadow-small;
  border-radius: $border-radius-medium;

}

.c-list-settings__item {

  margin-top: 0;

  padding: .25rem $unit-s;
  border-top: .0625rem solid $color-border-weak;

  &:first-child {

    border-top: none;

  }

  @include breakpoint(medium) {

    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .o-checkbox {

      min-width: 50%;

    }

  }

}

.c-list-settings__item--highlight {

  background-color: $color-primary-light;

}

.c-list-settings__name {

  margin-right: $unit-m;

}

.c-list-settings__options {

  margin-left: auto;

}

.c-list-settings__details {

  position: relative;
  padding-left: 2.25rem;

  margin-top: $unit-xs;
  margin-bottom: $unit-s;

  width: 100%;

  &:before {

    position: absolute;
    top: 0;
    left: .75rem;
    bottom: 0;

    border-left: .0625rem solid rgba($color-primary-dark, .1);

    content: "";
  }

  @include breakpoint(medium) {

    /* Remove margins */
    &:after {

      display: block;
      margin-bottom: 0 - $unit-m;

      content: "";

    }

    /* Always force margin bottom on the last child */
    .l-form__item:last-child {

      margin-bottom: $unit-m;

    }

  }

}