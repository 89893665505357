/*
 * Question: Fact
 */

.c-question-fact {

  img {

    margin: 0;
    width: 100%;

    border-radius: $border-radius-small;

  }

}

.c-question-fact__content {

  p {

    margin-bottom: 0;
    margin-top: $unit-xs;

    color: $color-text-strong;

    font-size: 1rem;
    line-heigh: 1.5;

    @include breakpoint(small) {

      font-size: 1.125rem;

    }

    &:first-child {

      margin-top: 0;

    }

  }

  margin-bottom: $unit-m;

  &:last-child {

    margin-bottom: 0;

  }

}

.c-question-fact__items {}

.c-question-fact__item {}

.c-question-fact--compare {

  .c-question-fact__items {

    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr;

    body.l-print & {

      //  This was added to counter the weird layout issues
      //  in print version for these question types.
      //  It's added so these layout changes are used only on print layouts.

      grid-template-columns: 48% 48%;
      grid-column-gap: 4%;
      grid-row-gap: 4%;

    }

    @include breakpoint(small) {

      grid-column-gap: 2rem;
      grid-row-gap: 2rem;

    }

    @include breakpoint(medium) {

      grid-template-columns: 1fr 1fr;

    }

  }

}

/*
 * Modifiers
 */

.c-question--library {

  .c-question-fact__content {

    p {

      @include breakpoint(small) {

        font-size: 1.125rem;

      }

    }

  }

  .c-question-fact__items {

    grid-gap: $unit-s;

    @include breakpoint(small) {

      grid-template-columns: 1fr 1fr;

    }

  }

}