/*
 * Filter select
 *
 * Toggle various groups to select the metric
 */

.c-filter-select {}

.c-filter-select__group {}

.c-filter-select__toggle {

  position: relative;

  display: flex;
  align-items: center;
  min-height: 3rem;
  padding: .25rem 0 .25rem 2rem;
  width: 100%;
  border: none;

  text-align: left;
  background: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {

    .c-filter-select__title {

      color: $color-text-strong;

    }

  }


}

.c-filter-select__icon {

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 2rem;
  min-height: 2rem;

  font-size: 1rem;
  color: $color-text-weak;

  .o-icon {

    position: relative;

    transition: $transition-default;

  }

  .o-icon-angle-right {

    .is-opened & {

      transform: rotate(90deg);

    }

  }

}

.c-filter-select__title {

  margin-bottom: 0;
  padding: .25rem 0;

  font-size: 1rem;
  font-family: $font-body;
  color: $color-text;
  font-weight: $semibold;
  letter-spacing: 0;

  transition: $transition-default;

  &:before {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: .0625rem;
    background-color: $color-border-weak;

    content: "";

  }

  .c-filter-select__toggle & {

    padding: 0;

  }

  .c-filter-select__group--nested & {

    font-size: .875rem;
    line-height: 1.4285714286;
    // line-height: 1.2857142857;
    color: $color-text-weak;

    &:before {

      left: $unit-l;

    }

  }

}

.c-filter-select__choices {

  padding-left: $unit-l;
  padding-bottom: $unit-xs;

  .o-radio--button {

    margin-top: .1875rem;

    label {

      display: block;
      background-color: $color-primary-light;

    }

  }

}

/*
 * States
 */

.c-filter-select__group {

  &.is-opened {}

  &.is-closed {

    .c-filter-select__group {

      display: none;

    }

  }

}

.c-filter-select__group--nested {

  &.is-opened {}

  &.is-closed {

    .c-filter-select__choices {

      display: none;

    }

  }

}