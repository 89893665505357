/*
 * Table validation
 *
 * Translation status overview
 */

.c-table-validation {

  border: .0625rem solid $color-border-weak;
  border-radius: $border-radius-small;

  .o-checkbox & {

    margin-bottom: .5rem;

  }

  table {


  }

  th,
  td {

    padding: .25rem .5rem;
    border: none;

    font-size: .75rem;
    line-height: 1.5;
    color: $color-text;

    vertical-align: middle;
    text-align: center;

    abbr {

      text-transform: uppercase;

      font-size: .75rem;
      font-variant: small-caps;

    }

  }

  th {

    color: $color-text-strong;
    font-weight: $semibold;

    &:first-child {

      text-align: left;

    }

  }

  td {

    &:first-child {

      text-align: left;

    }

  }

  tr:hover {

    th,
    td {

      color: $color-text-strong;
      background-color: darken($color-primary-light, 5%);

    }

  }

  td:first-child,
  th:first-child {

    width: auto;

  }

  .is-success,
  .is-warning,
  .is-danger {

    width: 2.5rem;

  }

  .is-success {

    background-color: $color-success-tint;

  }

  .is-warning {

    background-color: $color-warning-tint;

  }

  .is-danger {

    background-color: $color-danger-tint;

  }

  .o-icon-check {

    color: $color-success;

  }

  .o-icon-triangle {

    color: $color-warning;

  }

  .o-icon-times {

    color: $color-danger;

  }

}