/*
 * Upload caption
 *
 * Complex version of the upload with a an
 * editable caption available on the side
 */

.c-upload-caption {

  position: relative;

  max-width: 24rem;

  background-color: $color-border-weak;
  box-shadow: inset 0 0 0 .0625rem $color-border-weak;

  @include breakpoint(medium) {

    display: flex;
    align-items: center;

  }

  &.c-upload-caption--added {

    max-width: 100%;
    width: 100%;

  }

}

.c-upload-caption__media {

  max-width: 100%;

  @include breakpoint(medium) {

    .c-upload__content {

      //  2022-03-30
      //  This part is removed for time being, as it created problems
      //  with Fact Image question type. See issue #1876 for more info
      //  https://github.com/sdesregistry/IH-Engage/issues/1876

      // position: static;

    }

  }

}

.c-upload-caption__edit {

  padding: $unit-m $unit-m;

  width: 100%;

  background-color: #fff;
  box-shadow: inset 0 0 0 .0625rem $color-border-weak;

  @include breakpoint(medium) {

    padding: $unit-m;

  }

  .c-field {

    margin-bottom: $unit-s;

    &:last-child {

      margin-bottom: 0;

    }

  }

  textarea {

    height: 9rem; // 5.25 rem - this is what we had beforoe
    min-height: auto;

  }

}

.c-upload-caption__add {

  position: absolute;
  z-index: $z-index-main;
  left: .5rem;
  bottom: .5rem;

}