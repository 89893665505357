/*
 * Nav page
 *
 * Pagination
 */

.c-nav-page {

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: $unit-m;

  &:last-child {

    margin-bottom: 0;

  }

  @include breakpoint(medium) {

    flex-flow: row wrap;

  }

}

.c-nav-page__meta {

  display: flex;
  align-items: center;

  margin-bottom: $unit-s;

  @include breakpoint(medium) {

    margin-bottom: 0;

  }

}

.c-nav-page__total {

  margin-left: $unit-s;

  color: $color-text;

}

.c-nav-page__items {

  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-flow: row wrap;

  @include breakpoint(medium) {

    margin-left: auto;

  }

}

.c-nav-page__item {

  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 3rem;
  min-width: 2rem;
  margin-top: 0;

  text-align: center;

}

.c-nav-page__link {

  display: flex;
  align-items: center;
  justify-content: center;
  padding: $unit-xs;
  // height: 100%;
  // width: 100%;

  min-height: inherit;
  min-width: inherit;

  font-size: 1rem;
  line-height: 1.5;
  color: $color-text-weak;

  // border-radius: $border-radius-small;
  border-radius: 50%;

  &:hover,
  &:focus,
  &:active {

    color: $color-text-strong;

  }

}

.c-nav-page__item--active {

  padding: $unit-xs;

  color: $color-text-strong;
  background-color: $color-primary-light;
  border-radius: $border-radius-small;

}

.c-nav-page__item--prev {

  .o-icon {

    margin-right: $unit-xs;

    font-size: 1.25rem;

  }

}

.c-nav-page__item--next {

  .o-icon {

    margin-left: $unit-xs;

    font-size: 1.25rem;

  }

}