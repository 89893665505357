/* =Components: Condition
===================================================== */

/*
 * Condition
 *
 * Condition component which has several following elements:
 * - title (editable)
 * - handle (to drag and drop if needed)
 * - content for questions
 */

.c-condition {

  padding: $unit-m 0  $unit-m $unit-m;
  margin-top: $unit-m;
  margin-bottom: $unit-m;

  border-left: .125rem solid $color-border-weak;
  border-bottom: .125rem solid $color-border-weak;

  border-top-right-radius: $border-radius-small;
  border-top-left-radius: $border-radius-small;
  border-bottom-left-radius: $border-radius-small;

}

.c-condition__header {

  display: flex;
  align-items: center;

  margin-top: 0 - $unit-m;
  margin-left: 0 - $unit-m;
  margin-bottom: $unit-m;
  min-height: 3.5rem;

  // background-image: linear-gradient(to bottom, $color-grey-x-light, rgba(255,255,255,0));
  border-top-right-radius: $border-radius-small;
  border-top-left-radius: $border-radius-small;
  border-bottom-right-radius: $border-radius-small;
  background-color: $color-primary-light;

}

.c-condition__handle {

  position: relative;
  z-index: $z-index-switchable;

  display: flex;
  padding: 1rem .25rem;
  border: none;

  text-align: center;
  color: rgba($color-text, .4);
  background-color: transparent;

  cursor: move;
  @include prefixer(cursor, grab, ("webkit", "moz"));

  transition: all .1s ease-in;

  .o-icon {

    position: relative;
    font-size: 1.5rem;
    width: 1.5rem;

  }

  .o-label {

    @include hide-visually;

  }

  &:hover,
  &:focus,
  &:active {

    color: $color-link;

  }

  &:active {

    .o-icon {

      top: .0625rem;

    }

  }
}

.c-condition__title {

  position: relative;

  display: flex;
  align-items: center;
  flex: 1 1 100%;
  margin: 0;
  padding: .25rem 1rem;

  font-size: 1rem;
  line-height: 1.5;
  font-weight: $semibold;
  font-family: $font-body;
  color: $color-text-weak;

  overflow: hidden;

  .o-icon {

    position: relative;
    font-size: 1.25rem;
    width: 1.5rem;
    margin-right: $unit-s;

  }

}

.c-condition__handle + .c-condition__title {

  padding-left: 0;

}

.c-condition__actions {

  margin-top: $unit-s;

}

.c-condition__edit {

  margin-right: $unit-xs;
  white-space: nowrap;

}

.c-condition__rename {

  width: 100%;
  margin-right: $unit-xs;

  input[type="text"] {

    padding: 0 $unit-xs;

    font-size: 1rem;
    line-height: 1.5;
    font-weight: $semibold;

    height: 2rem;

  }

}

.c-condition__nested {

  &:empty {

    min-height: 3.5rem;
    border: .125rem dashed $color-border-normal;
    border-radius: $border-radius-small;

  }

}

/*
 * Condition indicator
 *
 * Visual indicator of connected conditions
 */

$condition-indicator-color: $color-primary-dark;
$condition-indicator-color-hover: $color-accent-red;
$condition-indicator-width: 4rem;

.c-condition-indicator {

  position: absolute;
  z-index: $z-index-modal + 100;
  top: 50%;
  right: 100%;

  width: $condition-indicator-width;

  opacity: .25;

  // background-image: url("data:image/svg+xml,%3Csvg width='64' height='256' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M64 0C22 26.333 1 69 1 128s21 101.667 63 128' stroke='#{encode-color($condition-indicator-color)}' stroke-width='2' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
  background-size: 100% 100%;

  pointer-events: none;

  &:before,
  &:after {

    position: absolute;
    left: 100%;
    transform: translate(-50%, -50%);

    width: .375rem;
    height: .375rem;
    display: block;

    border-radius: 50%;
    background: $condition-indicator-color;

    content: "";

  }

  &:before {

    top: 0;

  }

  &:after {

    top: 100%;

  }

  .c-list-settings & {

    right: calc(100% + 5.25rem);

  }

  .c-field--logic:hover & {

    opacity: 1;

    // background-image: url("data:image/svg+xml,%3Csvg width='64' height='256' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M64 0C22 26.333 1 69 1 128s21 101.667 63 128' stroke='#{encode-color($condition-indicator-color-hover)}' stroke-width='2' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E%0A");

    &:before,
    &:after {

      background: $condition-indicator-color-hover;

    }

  }

}