/*
 * Colors
 *
 * Defining base colors
 */

// Primary color
$color-primary-dark:       hsl(246, 100%, 14%); // 10% increments, 2 steps up and down
$color-primary-light:      hsl(213, 52%, 96%);  // 10% increments, 2 steps up and down

// Accent colors
$color-accent-green:       hsl(144, 57%, 67%);  // 10% increments, 2 steps up and down
$color-accent-yellow:      hsl(47, 100%, 69%);  // 10% increments, 2 steps up and down
$color-accent-red:         hsl(8, 96%, 67%);    // 10% increments, 2 steps up and down
$color-accent-blue:        hsl(205, 74%, 70%);  // 10% increments, 2 steps up and down

// Neutral colors
$color-grey:               hsl(185, 6%, 63%);   // 5% increments, 3 steps up and down

// $color-grey-x-dark:        darken($color-grey, 30%);
// $color-grey-dark:          darken($color-grey, 20%);
// $color-grey-mid-dark:      darken($color-grey, 10%);
// $color-grey-mid-light:     lighten($color-grey, 10%);
// $color-grey-light:         lighten($color-grey, 20%);
// $color-grey-x-light:       lighten($color-grey, 30%);

$color-grey-x-dark:        darken($color-primary-light, 25%);
$color-grey-dark:          darken($color-primary-light, 20%);
$color-grey-mid-dark:      darken($color-primary-light, 15%);
$color-grey-mid-light:     darken($color-primary-light, 10%);
$color-grey-light:         darken($color-primary-light, 5%);
$color-grey-x-light:       darken($color-primary-light, 0%);

// Default
$color-black: #111;
$color-white: #fff;

// Text
$color-text:               hsla(246, 100%, 14%, .75);
$color-text-weak:          hsla(246, 100%, 14%, .6);
$color-text-strong:        hsla(246, 100%, 14%, 1);
$color-text-invert:        hsla(0, 0%, 100%, .75);
$color-text-weak-invert:   hsla(0, 0%, 100%, .6);
$color-text-strong-invert: hsla(0, 0%, 100%, 1);

// Links
$color-link: $color-primary-dark;
$color-link-hover: rgba($color-primary-dark, .8);
// $color-link-tint: tint($color-link, 90%);
$color-link-tint: $color-primary-light;

// -s
$color-button-regular: $color-primary-light;
$color-button-regular-hover: darken($color-primary-light, 10%);

$color-button-primary: $color-primary-dark;
$color-button-primary-hover: rgba($color-primary-dark, .8);

$color-button-alternate: $color-primary-dark;
$color-button-alternate-hover: rgba($color-primary-dark, .8);

// Forms
$color-input-regular:  tint($color-primary-dark, 90%);
$color-input-focus:    tint($color-primary-dark, 60%);
$color-input-active:   tint($color-primary-dark, 60%);

// Borders
$color-border-weak:    $color-grey-x-light;
$color-border-normal:  $color-grey-light;
$color-border-strong:  $color-grey-mid-light;

// Various
$color-edit: #ffcb05;
$color-edit-tint: tint($color-edit, 90%);

$color-success: darken($color-accent-green, 10%); // #00a664
$color-success-tint: tint($color-success, 90%);

$color-warning: $color-accent-yellow; // #ffcb05
$color-warning-tint: tint($color-warning, 90%);

$color-danger: $color-accent-red; // #f04e3e
$color-danger-tint: tint($color-danger, 90%);

$color-error: hsl(8, 80%, 50%);
$color-error-tint: tint($color-error, 90%);

$color-coins: #F8E71C;
$color-coins-tint: tint($color-coins, 90%);

$color-highlight: #ffcb05;
$color-highlight-tint: tint($color-highlight, 90%);

$color-notification: #f04e3e;
$color-notification-tint: tint($color-notification, 90%);

$color-note: $color-accent-yellow;
$color-note-tint: tint($color-note, 90%);
$color-note-subtle: mix($color-note, $color-white, 20%);

$color-information: $color-link;
$color-information-tint: $color-primary-light;
// $color-information-tint: tint($color-information, 90%);

$color-sentiment-positive: darken($color-accent-green, 10%);
$color-sentiment-mixed: darken($color-accent-yellow, 10%);
$color-sentiment-negative: darken($color-accent-red, 10%);
$color-sentiment-neutral: darken($color-accent-blue, 10%);

/*
 * Social colors
 */

$color-facebook:  #3B5998;
$color-twitter:   #1DA1F2;
$color-linkedin:  #0077b5;
$color-instagram: #405de6;
$color-whatsapp:  #25D366;
$color-messenger: #0084FF;

/*
 * Switchable colors
 */

$color-switchable-01: #C3E6F6;
$color-switchable-02: #BFB2F9;
$color-switchable-03: #F1B5F2;
$color-switchable-04: #FAAEAE;
$color-switchable-05: #C1C1C1;
$color-switchable-06: #FFE469;
$color-switchable-07: #F8EFBD;
$color-switchable-08: #BDF8DE;
