/*
 * Nav filter
 *
 * Filter between items
 */

.c-nav-filter {

  margin-bottom: 1.5rem;

  @include breakpoint(extra-large) {

    display: flex;
    flex-flow: row wrap;

  }

}

.c-nav-filter__items {

  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  overflow-x: auto;

  li {

    margin-top: 0;

  }

  @include breakpoint(extra-large) {

    overflow-x: visible;

    border-right: .0625rem solid $color-border-normal;
    margin-right: $unit-s;

    &:last-child {

      border-right: none;
      margin-right: 0;

    }

  }

}

.c-nav-filter__item {

  display: flex;
  align-items: center;

  margin-right: $unit-s;

  font-size: 1rem;
  line-height: 1.5;
  white-space: nowrap;

  .o-icon {

    position: relative;
    top: .125rem;
    min-width: 1.25rem;

    font-size: 1rem;

  }

  // Positive
  .o-icon-grin      { color: $color-sentiment-positive; }
  // Mixed
  .o-icon-meh       { color: $color-sentiment-mixed; }
  // Negative
  .o-icon-frown     { color: $color-sentiment-negative; }
  // Neutral
  .o-icon-meh-blank { color: $color-sentiment-neutral; }

}

.c-nav-filter__item--label {

  display: none;

  color: $color-text-weak;

}

.c-nav-filter__action {

  display: block;
  padding: .5rem 0;
  border: none;

  font-size: 1rem;
  line-height: 1.5;
  color: $color-text;

  background-color: transparent;

  &.is-active {

    color: $color-link;
    box-shadow: inset 0 -.125rem 0 0 $color-link;

  }

  &:hover,
  &:focus,
  &:active {

    color: $color-text-strong;

    box-shadow: inset 0 -.125rem 0 0 $color-border-weak;

  }

}