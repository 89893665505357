/*
 * Nav tabs
 *
 * Tab navigation pattern styles
 */

.c-nav-tabs {

  // width: 100%;

}

.c-nav-tabs__items {

  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;

}

.c-nav-tabs__item {

  margin: 0;
  margin-right: $unit-xs;

  font-size: 1rem;
  line-height: 1.2;
  font-weight: $medium;

  &:last-child {

    margin-right: 0;

  }

}

.c-nav-tabs__item--align-end {

  margin-left: auto;
  margin-right: 0;

}

.c-nav-tabs__link {

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  min-height: 3rem;

  color: $color-text-weak;
  background-color: transparent;

  .o-icon {

    margin-left: .5rem;
    min-height: 1.25rem;

    &:first-child {

      margin-left: 0;
      margin-right: $unit-xs;

    }

    &:last-child {

      margin-right: 0;
      margin-left: $unit-xs;

    }

  }

  &:hover,
  &:focus,
  &:active {

    color: $color-link;

  }

}

.c-nav-tabs__item.is-active {

  .c-nav-tabs__link {

    color: $color-text-strong;

    box-shadow: inset 0 -.1875rem 0 0 $color-accent-green;

  }

}

/*
 * Modifiers
 */

.c-nav-tabs--large {

  @include breakpoint(medium) {

    .c-nav-tabs__item {

      margin-right: $unit-m;

      font-size: 1.125rem;

      &:last-child {

        margin-right: 0;

      }

    }

    .c-nav-tabs__link {

      min-height: 4rem;

    }

  }

}

.c-nav-tabs--icons-mobile {

  @include breakpoint(to-small) {

    .c-nav-tabs__link {

      min-width: 3rem;

    }

    .o-icon:last-child,
    .o-icon:first-child {

      margin-left: 0;
      margin-right: 0;

    }

    .o-label {

      @include hide-visually();

    }

  }

}

/*
 * Scrollable tabs
 *
 * Making tabs scrollable if they overflow
 * the available space in a grid
 */

.c-nav-tabs--scroll {

  position: relative;
  min-width: 0;
  width: auto;

  &:after {

    position: absolute;
    z-index: $z-index-modal;
    top: 0;
    left: 100%;
    height: calc(100% - .0625rem);
    width: 1rem;

    background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));

    content: "";

    pointer-events: none;

  }

  &:before {

    position: absolute;
    z-index: $z-index-modal;
    top: 0;
    right: 100%;
    height: calc(100% - .0625rem);
    width: 1rem;

    background-image: linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,1));

    content: "";

    pointer-events: none;

  }

  .c-nav-tabs__items {

    position: relative;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    height: 4rem;

    margin-left: -1rem;
    margin-right: -1rem;

  }

  .c-nav-tabs__item:first-child {

    padding-left: 1rem;

  }

  .c-nav-tabs__item:last-child {

    padding-right: 1rem;

  }

}