/*
 * Filter Overview list
 *
 * List of active or inactive filter (based on context)
 *
 * We used this in Browse Feedback modal to show
 * active primary-filters.
 */

@use "sass:math";

.c-filter-overview-list {
  padding: $unit-s;
  background: $color-border-weak;
  border-radius: $border-radius-small;
  
  &.c-filter-overview-list--minimalistic {
    padding: $unit-xxs $unit-xs;
    border: 1px solid $color-border-weak;
    background: none;
  }

  ol {
    @include list-reset();
    display: flex;
    flex-direction: column;
    grid-gap: $unit-xs;
  }
  
  li {
    margin: 0;
  }
  
  @include breakpoint(medium) {
    
    ol {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  
  @include breakpoint(extra-large) {
    display: flex;
    align-items: center;
    grid-gap: $unit-s;
  }
}

.c-filter-overview-list__header {
  display: flex;
  grid-gap: $unit-s;
  margin-bottom: $unit-s;

  .o-button--link .o-icon {
    //  This is added for cases where we have additional action inside
    //  a header (i.e. "additional link to clear filters")

    margin-left: $unit-xs;
    top: rem(3);
  }
  
  @include breakpoint(extra-large) {
    margin-bottom: 0;
  }
}

.c-filter-overview-list__title {
  //  This style is based on .c-field__label

  font-family: $font-body;
  font-size: 1rem;
  line-height: 1.5;
  color: $color-text;
  font-weight: $semibold;
  margin: 0;
  
  .o-tooltip {
    margin-left: $unit-xs;
  }
  
}

.c-filter-overview-list__item {
  // border: 1px solid transparent-color($color-black, .05);
  border-radius: $border-radius-small;
  padding: $unit-xs math.div($unit-s, 1.5);
  background: $color-white;
  display: flex;
  align-items: center;
  grid-gap: $unit-xs;
  
  span {
    display: block;
  }
  
  br {
    display: none;
  }
  
  .o-filter-label {
    display: inline-block;
  }
}