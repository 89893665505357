/* =Horizontal rule
===================================================== */

// Horizontal rule
//
// Horizontal rule example
//
// Markup:
// <hr>
//
// Style guide: 4.hr

hr {

  padding: 0;
  margin: 0;
  border: 0;
  border-top: 1px solid $color-border-normal;

  margin-top: $unit-default;
  margin-bottom: $unit-default;
  clear: both;

}