/*
 * Block card
 *
 * Simple overview block card with toggle controls
 */

.c-block-card {

  position: relative;

  display: flex;
  flex-flow: row wrap;

  align-items: center;

  width: 100%;
  padding: $unit-s $unit-s;

}

.c-block-card__title {

  margin-top: 0;
  margin-bottom: 0;
  margin-right: $unit-xs;

  font-size: 1.125rem;
  line-height: 1.4444444444;

  transition: all .1s ease-in;

  .is-hidden & {

    opacity: .5;

  }

}

.c-block-card__description {

  margin-top: 0;
  margin-bottom: 0;
  width: 100%;

  font-size: .875rem;

  transition: all .1s ease-in;

  .is-hidden & {

    opacity: .5;

  }

}

.c-block-card__action {

  // margin-top: .25rem;

  @include breakpoint(small) {

    margin-left: auto;

  }

}