/*
 * Media
 *
 * Media contains:
 * - Aside
 * - Content
 */

.c-media {

  position: relative;

  display: flex;

  overflow: hidden;

  /*
   * Compensate for container padding
   */

  .c-modal & {

    margin: 0 - $unit-m;

    @include breakpoint(medium) {

      margin: 0 - $unit-l;

    }

  }

}

/*
 * Media options
 *
 * Sidebar with side navigation
 */

$media-options-width: 16rem;
$media-options-width-wide: 18rem;

.c-media__options {

  position: relative;
  z-index: $z-index-modal + 10;

  width: $media-options-width;
  padding: $unit-m;

  overflow-y: auto;

  background-color: #fff;
  box-shadow: $box-shadow-small;
  box-shadow: inset -.0625rem 0 0 0 $color-primary-light;
  transition: all .1s ease-in;

  // @include breakpoint(to-medium) {

  //   position: absolute;
  //   z-index: $z-index-modal + 10;
  //   top: 0;
  //   left: -100%;
  //   bottom: 0;

  //   box-shadow: $box-shadow-medium;

  // }

  @include breakpoint(extra-small) {

    width: $media-options-width-wide;

  }


  @include breakpoint(medium) {

    flex: 1 0 $media-options-width-wide;
    padding: $unit-l;

  }

  // For scrolling purposes
  &:after {

    display: block;
    height: $unit-m;
    margin-bottom: 0 - $unit-m;

    content: "";

  }

  .is-options-opened & {

    left: 0;

  }

}

/*
 * Media content
 */

.c-media__content {

  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $unit-m;
  overflow-y: auto;

  // Fixes an issue where a child (e.g. button-list)
  // will expand the container outside of boundaries
  min-width: 0;

  // For scrolling purposes
  // &:after {

  //   display: block;
  //   height: $unit-m;
  //   margin-bottom: 0 - $unit-m;

  //   content: "";

  // }

  @include breakpoint(medium) {

    padding: $unit-l;

  }

}

/*
 * Media search and filter
 */

.c-media__filter,
.c-media__search {

  margin-bottom: $unit-l;

}