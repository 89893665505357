/*
 * Poll preview
 */

.c-poll-preview {

  width: 0;
  min-width: 100%;

  position: relative;

  box-shadow: $box-shadow-large;
  border-radius: $border-radius-small;
  background-color: #fff;

}

.c-poll-preview__actions {

  display: flex;
  align-items: center;
  padding: $unit-s $unit-m;
  // Avoid inset shadow due to HTML2Canvas glitches
  border-bottom: .0625rem solid $color-primary-light;

}

.c-poll-preview__label {

  margin-right: $unit-xs;

}

.c-poll-preview__items {



}

.c-poll-preview__item {

  padding: 1rem;

  // Avoid inset shadow due to HTML2Canvas glitches
  border-top: .0625rem solid $color-primary-light;
  background-color: #fff;

  &:first-child {

    border-top: none;
    border-top-left-radius: $border-radius-small;
    border-top-right-radius: $border-radius-small;

  }

  &:last-child {

    border-bottom-left-radius: $border-radius-small;
    border-bottom-right-radius: $border-radius-small;

  }

  @include breakpoint(small) {

    padding: 2rem;

  }

}

.c-poll-preview__actions + .c-poll-preview__items {

  .c-poll-preview__item:first-child {

    border-top-left-radius: 0;
    border-top-right-radius: 0;

  }

}

.c-poll-preview__item--fact {

  background-color: $color-primary-light;

}

.c-poll-preview__item--fact + .c-poll-preview__item--fact {

  margin-top: .0625rem;

}