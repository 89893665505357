/*
 * Info window
 *
 * Used to show feedback info in maps
 */

.c-info-window {
  background: $color-white;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-large;
  overflow: hidden;
  max-width: rem(400);
  position: relative;
  
  .c-feedback {
    border: none !important;
    box-shadow: none !important;
  }
}

.c-info-window--multi {
  //  Info window that displays multiple feedback items
  
  padding-top: rem(48);
  
  .c-feedback:not(.is-visible) {
    display: none;
  }
}

.c-info-window-navigation {
  position: absolute;
  top: $unit-m;
  left: $unit-m;
  right: $unit-m;
  margin: 0;
  @include list-reset();
  display: flex;
  justify-content: space-between;
  grid-gap: $unit-xxs;
  
  li {
    margin: 0;
  }
  
  a {
    font-weight: $bold;
    font-size: rem(13);
    text-transform: uppercase;
    padding: rem(4) rem(8);
    background: $color-grey-x-dark;
    color: $color-white;
    border-radius: $border-radius-small;
    
    &:not(.is-disabled):hover,
    &:not(.is-disabled):focus {
      background: $color-primary-dark;
      color: $color-white;
    }
    
    &.is-disabled {
      opacity: .25;
      pointer-events: none;
      cursor: none;
    }
  }
}