/*
 * Question: Quick answer
 */

.c-question-quick-answer {

  // display: flex;

}

.c-question-quick-answer__lead {

  img {

    margin: 0;
    width: 100%;

    border-radius: $border-radius-small;

  }

}

.c-question-quick-answer__info {

  margin-bottom: $unit-s;
  margin-top: $unit-s;

  color: $color-text-strong;

  font-size: 1rem;
  line-heigh: 1.5;

  &:first-child {

    margin-top: 0;

  }

  &:last-child {

    margin-bottom: 0;

  }

}