/* Import */
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700;900&display=swap');

/* System font stack */
$system-ui: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

/* Fonts */
$font-brand-primary:              'Work Sans', $system-ui;
$font-brand-secondary:            $system-ui;

/* Font use case */
$font-body:                       $font-brand-secondary;
$font-headline:                   $font-brand-primary;

/* Font weights */
$hairline:                        100;
$thin:                            200;
$light:                           300;
$regular:                         400;
$medium:                          500;
$semibold:                        600;
$bold:                            700;
$black:                           900;