/*
 * Visualisation
 *
 * Space for graphs and legends
 */

.c-visualisation {

  padding: .25rem 0;

}

.c-visualisation__content {

  padding: .25rem 0;

  svg {

    display: block;

  }

}

.c-visualisation__legend {

  margin-top: .25rem;

  // font-size: 1rem;
  // line-height: 1.5;
  font-size: .875rem;
  line-height: 1.4285714286;
  color: $color-text-weak;

}

.c-visualisation__legend-item {

  margin-top: .125rem;

  &:first-child {

    margin-top: 0;

  }

  strong {

    font-weight: $semibold;
    color: $color-text;

  }

}

.c-visualisation__legend-circle {

  display: inline-block;
  width: .75rem;
  height: .75rem;
  margin-right: .25rem;

  background-color: $color-grey-dark;

  border-radius: 50%;

}

/*
 * Print report
 *
 * Modifications for printed reports
 */

.l-print {

  .c-visualisation__legend {

    margin-top: .125rem;

    font-size: .75rem;
    line-height: 1.25;

  }

  .c-visualisation__legend-circle {

    -webkit-print-color-adjust: exact;

  }

}