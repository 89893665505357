/*
 * Table
 */

.c-table {

  margin-bottom: $unit-xl;

  &:last-child {

    margin-bottom: 0;

  }

  th {

    font-size: .875rem;
    text-transform: uppercase;

  }

  th,
  td {

    height: 3rem;

  }

  .is-disabled th,
  .is-disabled td, {

    color: $color-text-weak;

  }

  td.c-table__name {

    color: $color-text-strong;
    font-weight: $semibold;

  }

  td.c-table__actions {

    text-align: right;

  }

}

/*
 * Modifiers
 */

.c-table--scroll {

  overflow-x: auto;

}

/*
 * Stats
 *
 * Table used for displaying stats
 */

.c-table--stats {

  tr:hover {

    th,
    td {

      color: $color-text-strong;
      // background-color: $color-primary-light;

      // &:first-child {

      //   background: linear-gradient(to left, $color-primary-light, rgba($color-primary-light, 0));

      // }

    }

  }

  .c-table__date {

    font-size: 1rem;
    color: $color-text-weak;
    text-transform: none;
    font-weight: $regular;

  }

  .c-table__project {

    min-width: 10rem;
    font-size: 1rem;
    color: $color-text;
    text-transform: none;

  }

  .c-table__group {

    th:first-child {

      width: 24rem;

    }

    th,
    td {

      height: 3.5rem;

    }

    th {

      position: relative;

      padding-left: 2.5rem + $unit-xs;

      .c-table__icon {

        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

      }

    }

  }

  .c-table__icon {

    display: inline-flex;
    align-items: center;
    justify-content: center;

    min-width: 2.5rem;
    min-height: 2.5rem;

    vertical-align: middle;
    background-color: $color-accent-green;
    border-radius: 50%;

    .o-icon {

      font-size: 1.25rem;
      width: 2rem;

      display: block;

    }

  }

  .c-table__icon--alt {

    background-color: $color-primary-light;

  }

  th[scope="row"] {

    font-size: 1rem;

    text-transform: none;
    // background: red;

  }

  .c-table__choice {

    th,
    td {

      border-top: none;
      padding-top: .25rem;
      padding-bottom: .25rem;

      height: auto;

      font-size: .875rem;
      color: $color-text-weak;

    }

    th[scope="row"] {

      padding-left: 2.5rem + $unit-xs;

      font-weight: $regular;

    }

  }

  .c-table__choice--last {

    th,
    td {

      padding-bottom: $unit-s;

    }

  }

  /*
   * Highlighted number
   */

  .c-table__highlight {

    padding: .25rem .5rem;
    margin-left: 0 - .5rem;

    background-color: tint($color-success, 80%);
    border-radius: 1.5rem;

  }

  /*
   * Bar
   */

  .c-table__bar {

    position: relative;

    display: inline-block;
    height: .375rem;
    width: 5rem;
    margin-left: 2.5rem;

    background-color: $color-border-weak;
    vertical-align: middle;


  }

  .c-table__bar-value {

    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);

    margin-top: -.125rem;
    width: 2.5rem;

    line-height: 1.5;
    text-align: left;

  }

  .c-table__bar-filled {

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    background-color: $color-accent-green;

  }

}