/*
 * Login
 *
 * Login form with other elements
 */

.c-login {

  display: flex;
  flex-flow: column;
  margin-left: auto;
  margin-right: auto;
  padding: $unit-l;

  margin-top: $unit-l;
  margin-bottom: $unit-l;

  border-radius: $border-radius-small;
  box-shadow: $box-shadow-large;
  background-color: #fff;
  @include breakpoint(large) {

    width: span(6 of 12);

  }

}

.c-login__title {}

.c-login__description {}

.c-login .l-form {


}