/* =Page
===================================================== */

// Page
//
// Different text elements
//
// Style guide: 4.page

// Paragraph
//
// Paragraph example. List items share the font size and line height with paragraph text.
//
// Markup:
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minus laudantium maiores nobis voluptatibus commodi, aspernatur animi optio dicta ullam hic, nisi, assumenda facere tenetur explicabo architecto repellendus, minima. Aut, eligendi.</p>
//
// Style guide: 4.page.paragraph

p {

  margin-top: 0;
  margin-bottom: 1rem;

  color: $color-text;
  font-weight: $regular;

}

// Strong
//
// Markup:
// <strong>Strong text</strong>
//
// Style guide: 4.page.strong

strong {

  font-weight: $semibold;
  // color: darken($color-text, 20%);

}

// Emphasis
//
// Markup:
// <em>Emphasized text</em>
//
// Style guide: 4.page.emphasis

em {

  font-stretch: italic;

}

// Sup
//
// Markup:
// <p>Example<sup>text</sup></p>
//
// Style guide: 4.page.sup

sup {

  vertical-align: text-top;
  font-size:75%;

}

// Sub
//
// Markup:
// <p>Example<sub>text</sub></p>
//
// Style guide: 4.page.sub

sub {

  vertical-align: bottom;
  font-size:75%;

}