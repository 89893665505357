/*
 * Date picker
 *
 * Styling date picker. Used on:
 * - News (single)
 * - Reports (range)
 * ...
 */

.c-date-picker {

  position: relative;

  display: flex;

  .o-input {

    display: flex;
    flex: 100%;

    input[type="text"]:focus {

      position: relative;
      z-index: $z-index-main;

    }

    .o-icon {

      z-index: $z-index-main + 10;

    }

  }

  .o-input:first-of-type {

    input[type="text"] {

      border-right: 0;

      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

    }

  }

 .o-input:last-of-type {

    input[type="text"] {

      margin-left: -.0625rem;

      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

    }

  }

  .o-button {

    margin-left: $unit-s;

  }

}

$date-picker-time: 6rem;
$date-picker-actions: 4rem;

.c-date-picker__presets {

  // position: relative;

  display: flex;
  flex-direction: column;
  padding: $unit-xs;
  min-width: $date-picker-time;

  background-color: $color-primary-light;

  .o-button {

    display: block;
    margin: 0;

    font-size: .875rem;
    line-height: 1.5;
    text-align: left;

    white-space: nowrap;

  }

  .o-button--danger {

    margin-top: auto;

  }

}

.c-date-picker__time {

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: $date-picker-time;

  overflow-y: auto;

  list-style: none;
  margin: 0;
  padding: $unit-xs;

  &:before {

    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;

    height: $unit-xs;
    background: linear-gradient(to top, $color-primary-light, rgba($color-primary-light, 0));

    content: "";

  }

  li {

    margin-top: 0;

    font-size: .875rem;
    line-height: 1.5;
    text-align: center;

    &:last-child {

      margin-bottom: $unit-xs;

    }

  }

  .o-button {

    display: block;
    padding: .25rem $unit-xs;
    width: 100%;
    border: none;

    text-align: center;
    border-radius: $border-radius-small;

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong;
      background-color: $color-button-regular-hover;

    }

  }

  .is-active .o-button {

    color: $color-text-strong;
    background-color: $color-accent-green;

  }

}

.c-date-picker__action {

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  height: $date-picker-actions;
  padding: $unit-xs $unit-s;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  border-top: .0625rem solid $color-border-weak;

  .o-button {

    margin-top: 0;
    margin-right: $unit-xs;

  }

  // .o-button--danger {

  //   margin-left: auto;

  // }

}


/*
 * React Day Picker (Used from September, 2021)
 *
 * https://reactdatepicker.com/
 */
 
$datepicker__background-color: #fff;
$datepicker__border-color: $color-border-weak;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: $color-grey;
$datepicker__selected-color: $color-grey-dark;
$datepicker__text-color: $color-text;
$datepicker__header-color: $color-text;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: $border-radius-small;
$datepicker__day-margin: rem(1);
$datepicker__font-size: 1rem;
$datepicker__item-size: rem(36);
$datepicker__margin: $unit-xs;
$datepicker__navigation-size: rem(6) !default;
$datepicker__triangle-size: rem(8);

.react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
  
  .c-date-picker &:first-of-type input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .c-date-picker &:not(:first-of-type) input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }
}

.react-datepicker {
  font-family: $font-brand-primary;
  font-size: $datepicker__font-size;
  background-color: #fff;
  color: $datepicker__text-color;
  border: 1px solid $datepicker__border-color;
  border-radius: $datepicker__border-radius;
  display: inline-block;
  position: relative;
  box-shadow: $box-shadow-extra-large;
  z-index: $z-index-modal;
  display: flex;
  
  .c-date-picker__presets {
    width: rem(140);
  }
}

.react-datepicker-popper {
  z-index: $z-index-modal;
}

.react-datepicker__header {
  text-align: center;
  border-bottom: 1px solid $datepicker__border-color;
  padding-top: $unit-xs;
  position: relative;
}

.react-datepicker__current-month {
  margin-top: 0;
  color: $datepicker__header-color;
  font-weight: $medium;
  font-size: rem(20);
  letter-spacing: -.5px;
}

.react-datepicker__navigation {
  background: none;
  line-height: $datepicker__item-size;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: rem(18);
  width: 0;
  padding: 0;
  border: $datepicker__navigation-size solid transparent;
  z-index: 1;
  
  span {
    @include hide-visually();
  }

  &--previous {
    left: rem(10);
    border-right-color: $datepicker__muted-color;

    &:hover {
      border-right-color: darken($datepicker__muted-color, 10%);
    }

    &--disabled,
    &--disabled:hover {
      border-right-color: $datepicker__navigation-disabled-color;
      cursor: default;
    }
  }

  &--next {
    left: rem(260);
    border-left-color: $datepicker__muted-color;

    &:hover {
      border-left-color: darken($datepicker__muted-color, 10%);
    }

    &--disabled,
    &--disabled:hover {
      border-left-color: $datepicker__navigation-disabled-color;
      cursor: default;
    }
  }

  &--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &-previous {
      top: rem(4);
      border-top-color: $datepicker__muted-color;

      &:hover {
        border-top-color: darken($datepicker__muted-color, 10%);
      }
    }

    &-upcoming {
      top: rem(-4);
      border-bottom-color: $datepicker__muted-color;

      &:hover {
        border-bottom-color: darken($datepicker__muted-color, 10%);
      }
    }
  }
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: $datepicker__margin;
  text-align: center;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid $datepicker__border-color;
  width: rem(100);
  font-family: $system-ui;
  
  .react-datepicker__header--time {
    @include hide-visually();
  }

  .react-datepicker__time {
    position: relative;

    .react-datepicker__time-box {
      width: 100%;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
      background-color: $color-primary-light;
      
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        overflow-y: scroll;
        height: rem(307) !important;
        padding: $unit-xs;

        li {
          display: block;
          border-radius: $border-radius-small;
          margin: 0;
          text-align: left;
          font-size: .875rem;
          padding: .375rem .5rem;
          line-height: 1.5;
          font-weight: 600;
          white-space: nowrap;
          cursor: pointer;
          color: $color-link-hover;
          
          &:hover, &[class*="selected"] {
            color: $color-link;
            background-color: #c9d9ee;
          }
        }
      }
    }
  }
}

.react-datepicker__week-number {
  color: $datepicker__muted-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
  &.react-datepicker__week-number--clickable {
    cursor: pointer;
    &:hover {
      border-radius: $datepicker__border-radius;
      background-color: $datepicker__background-color;
    }
  }
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: $datepicker__text-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
}

.react-datepicker__day-name {
  color: $color-grey;
  font-size: rem(14);
  line-height: rem(20);
}

.react-datepicker__day {
  cursor: pointer;
  border-radius: 100%;

  &:hover {
    background-color: $color-grey-x-light;
    color: $color-text-strong;
  }

  &--today {
    font-weight: $bold;
  }

  &--highlighted {
    border-radius: $datepicker__border-radius;
    background-color: $color-grey-light;
    color: $color-text-strong;

    &-custom-1 {
      color: magenta;
    }

    &-custom-2 {
      color: green;
    }
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    background-color: $datepicker__selected-color;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--keyboard-selected {
    border-radius: $datepicker__border-radius;
    background-color: lighten($datepicker__selected-color, 10%);
    color: #fff;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($datepicker__selected-color, 0.5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: $datepicker__background-color;
      color: $datepicker__text-color;
    }
  }

  &--disabled {
    cursor: default;
    color: $datepicker__muted-color;

    &:hover {
      background-color: transparent;
    }
  }
  
  &--outside-month {
    color: $color-grey-light;
  }
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}


.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: $datepicker__background-color;
  position: absolute;
  width: 50%;
  left: 25%;
  top: rem(30);
  z-index: 1;
  text-align: center;
  border-radius: $datepicker__border-radius;
  border: 1px solid $datepicker__border-color;

  &:hover {
    cursor: pointer;
  }

  &--scrollable {
    height: rem(150);
    overflow-y: scroll;
  }
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: rem(20);
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:first-of-type {
    border-top-left-radius: $datepicker__border-radius;
    border-top-right-radius: $datepicker__border-radius;
  }

  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: $datepicker__border-radius;
    border-bottom-right-radius: $datepicker__border-radius;
  }

  &:hover {
    background-color: $datepicker__muted-color;

    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: darken($datepicker__muted-color, 10%);
    }

    .react-datepicker__navigation--years-previous {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--selected {
    position: absolute;
    left: rem(15);
  }
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 0;
  outline: 0;
  padding: 0;
  width: rem(24);
  height: rem(24);
  position: absolute;
  right: rem(8);
  top: 50%;
  transform: translateY(-50%);

  &:after {
    background-color: $color-grey-x-light;
    border-radius: 50%;
    color: $color-grey-x-dark;
    content: "\00d7";
    font-size: rem(18);
    line-height: rem(22);
    height: rem(24);
    width: rem(24);
    display: block;
    transition: $transition-fast;
  }
  
  &:hover:after, &:focus:after {
    background-color: $color-grey-light;
    color: $color-primary-dark;
  }
}