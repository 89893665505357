/*
 * Question: Slider
 */

.c-question-slider {}

.c-question-slider__lead {

  margin-bottom: $unit-m;

  img {

    margin: 0;
    width: 100%;

    border-radius: $border-radius-small;

  }

}

.c-question-slider__meta {

  list-style: none;
  margin: 0;
  padding: 0;

  margin-top: $unit-s;
  margin-bottom: $unit-s;

  &:first-child {

    margin-top: 0;

  }

  &:last-child {

    margin-bottom: 0;

  }

  li {

    color: $color-text-strong;
    font-size: 1rem;
    line-height: 1.5;

  }

  @include breakpoint(small) {

    display: flex;
    flex-flow: row wrap;

    li {

      margin-top: 0;
      margin-right: $unit-xs;
      padding-right: $unit-xs;
      border-right: .0625rem solid $color-border-normal;

      &:last-child {

        border-right: none;

      }

    }

  }

}

.c-question-slider__feedback {

  margin-top: $unit-m;

  p {

    display: inline-block;

  }

}

/*
 * Modifiers
 */

.c-question--library {

  .c-question-slider {

    @include breakpoint(small) {

      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $unit-s;

      align-items: center;

    }

  }

  .c-question-slider__lead {

    @include breakpoint(small) {

      margin-bottom: 0;

    }

  }

}