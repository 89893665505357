/* Z-index */
$z-index-header:                  400;
$z-index-main:                    300;
$z-index-navigation:              500;
$z-index-footer:                  100;
$z-index-modal:                   600;
$z-index-dropdown:                400;
$z-index-tooltip:                 600;
$z-index-suggest:                 400;
$z-index-library:                 500;
$z-index-switchable:              350;
$z-index-upload:                  300;
// $z-index-modal-close:             510;
// $z-index-skip-links:              600;

/* Units */
$unit-xxs:                      .25rem;   //  4px
$unit-xs:                       .5rem;    //  8px
$unit-s:                        1rem;     //  16px
$unit-m:                        1.5rem;   //  24px
$unit-l:                        2rem;     //  32px
$unit-xl:                       3rem;     //  48px
$unit-xxl:                      6rem;     //  96px
$unit-default:                  1.5rem;   //  24px;

/* Container padding */
$container-unit-small:          $unit-s;
$container-unit-medium:         $unit-m;
$container-unit-large:          $unit-l;

/* Container max width */
$container-max-width:           74rem;
$container-max-width-wide:      90rem;
$container-max-width-narrow:    36rem;

/* Contextual help width */
$contextual-help-width:         18rem;

/* Border radius */
$border-radius-small:           .1875rem;
$border-radius-medium:          .375rem;
$border-radius-large:           .875rem;

/* Box shadow */
$box-shadow-small:              0 .0625rem .1875rem 0 rgba($color-primary-dark, .15);
$box-shadow-medium:             0 .125rem .375rem 0 rgba($color-primary-dark, .15);
$box-shadow-large:              0 .5rem 2rem 0 rgba($color-primary-dark, .2);
$box-shadow-extra-large:        0 1rem 3rem 0 rgba($color-primary-dark, .2);

/* Transition */
$transition-default:            all .1s cubic-bezier(0.2, 0.6, 0.3, 1);
$transition-timing-cubic:       cubic-bezier(0.2, 0.6, 0.3, 1);
$transition-fast:               all ease-in .1s;

/* Media queries */
$extra-small-value:             24em; // 384px
$small-value:                   36em; // 576px
$medium-value:                  48em; // 768px
$large-value:                   62em; // 992px
$extra-large-value:             80em; // 1280px