/*
 * Tabs
 *
 * Tabs component (navigation & content)
 */

.c-tabs {

}



/*
 * Tabs Navigation
 */

.c-tabs-navigation {
  
}

.c-tabs-navigation__container {
  @include list-reset();
  margin-bottom: $unit-m;
  display: inline-flex;
  border-bottom: 1px solid $color-border-normal;
  width: 100%;
}

.c-tabs-navigation__item {
  margin: 0;
  
  &:first-child button {
    margin-left: 0;
  }
  
  button {
    border: 1px solid $color-border-normal;
    background: none;
    font-family: $system-ui;
    font-size: rem(16);
    line-height: 1.5;
    font-weight: $semibold;
    cursor: pointer;
    padding: rem(8) rem(16);
    color: $color-text-weak;
    margin: 0 0 rem(-1) rem(3);
    border-radius: $border-radius-small;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    
    &[aria-selected="true"] {
      border-bottom: 1px solid $color-white;
      color: $color-primary-dark;
    }
  }
}



/*
 * Tabs Content
 */

.c-tabs-content {
  width: 100%;
}

.c-tabs-content__container {
  margin-bottom: $unit-m;
  // width: 100%;
}