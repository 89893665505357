/*
 * Report print
 *
 * Report needs to be adjusted before being printed
 * to PDF so this is the file with overrides
 * or some layout structuring for the reports
 * that does not appear in the interface in the app.
 */

$report-page-margin: 2cm;
$report-logo-size-sm: 2rem;
$report-logo-size-md: 3rem;
$report-logo-size-lg: 6rem;

@page {
  
  size: A4;

  margin: 0;

  background-color: #fff;

}


@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
}

.l-print {

  background-color: #fff;

}

/*
 * Overrides
 *
 * Following overrides tweak the styling of
 * the components so that they look better
 * for print and fit to A4 paper size PDF.
 */

.c-report-print {

  // Making it look good in preview
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  // Force three columns
  .l-stat-list--three {

    grid-template-columns: 1fr 1fr 1fr;

  }

  // Hide actionable elements
  .c-question__actions {

    display: none;

  }

  // Hide buttons
  .o-button {

    display: none;

  }

  // Hide checkbox toggle on results
  .c-result-choice__toggle {

    display: none;

  }

  // Make emojis on rows smaller
  .c-result-choice__media img {

    height: 3rem;

  }

  // Limit the size of the map and image
  // on the annotation question
  .c-result-annotate__content,
  .o-map__content {

    height: 18rem;
    max-height: 18rem;

    //  The following line is added to prevent minimum height 
    //  breaking content into two pages
    min-height: auto;

  }

  // Hide sentiment stats
  .c-sentiment-stats {

    display: none;

  }

  //  Style overrides specific to annotate questions.
  //  This will allow up to 10 questions per A4 page.
  .c-result-annotate {
    
    + .c-result-group {
      
      .c-result-choice__media img {
  
        height: 2.5rem;
        
      }
      
      .c-result-choice__value,
      .c-result-choice__compare {

        min-height: 1.25rem;

      }

    }

  }

}


/*
 * Report page
 */

.c-report-print__page {

  page-break-before: always;

  padding: $report-page-margin;
  
  break-inside: avoid;
  page-break-inside: avoid;
  
  * {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}

.c-report-print__intro,
.c-report-print__outro,
.c-report-print__page--cover {

  page-break-before: always;

  min-height: 100vh;

}

/*
 * Report header
 */

.c-report-print__header {

  position: relative;

  display: flex;
  align-items: center;
  margin-top: 0 - $report-page-margin;
  margin-left: 0 - $report-page-margin;
  margin-right: 0 - $report-page-margin;
  margin-bottom: $report-page-margin * 0.5;

  padding: $unit-s $report-page-margin;

  background-color: $color-primary-light;

  &:before {

    position: absolute;
    top: 50%;
    left: $report-page-margin;
    transform: translateY(-50%) translateX(0 - ($report-logo-size-sm + $unit-xs));

    display: block;
    width: $report-logo-size-sm;
    height: $report-logo-size-sm;

    background: assets-path("images/gmv-symbol-dark.svg") no-repeat;
    background-size: $report-logo-size-sm $report-logo-size-sm;

    content: "";

  }

}

.c-report-print__header-title {

  margin-bottom: 0;

  font-size: 1rem;
  line-height: 1.5;
  font-weight: $semibold;

}

.c-report-print__header-meta {

  margin-bottom: 0;
  margin-left: $unit-xs;

  font-size: .875rem;
  line-height: 1.5;
  font-family: $font-headline;
  font-weight: $regular;
  color: $color-text-weak;
  // text-transform: uppercase;

}

.c-report-print__header-page {

  margin-bottom: 0;
  margin-left: auto;

  font-size: .875rem;
  line-height: 1.5;
  font-family: $font-headline;
  font-weight: $regular;
  color: $color-text-weak;
  // text-transform: uppercase;

}

/*
 * Report intro
 */

.c-report-print__intro {

  position: relative;

  padding: $report-page-margin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  background: $color-primary-dark;

  &:before {

    position: relative;
    z-index: $z-index-main;

    display: block;
    width: $report-logo-size-lg;
    height: $report-logo-size-lg;
    margin-bottom: $unit-l;

    background: assets-path("images/gmv-symbol-white.svg") no-repeat;
    background-size: $report-logo-size-lg $report-logo-size-lg;

    content: "";

  }

}

.c-report-print__intro-media {

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  display: none;

  img {

    display: block;
    margin: 0;

    position: absolute;
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: 50% 50%;

    filter: blur($unit-xs);

    opacity: .5;

  }

}

.c-report-print__intro-title {

  position: relative;

  font-size: 2.25rem;
  line-height: 1.5;

  text-align: center;
  color: $color-text-strong-invert;

}

.c-report-print__intro-meta {

  position: relative;

  list-style: none;
  margin: 0;
  padding: 0;

  li {

    margin-top: $unit-xs;

    font-size: 1.125rem;
    font-size: 1.5rem;
    line-height: 1.5;
    font-family: $font-headline;

    color: $color-text-strong-invert;
    text-align: center;

  }

}

/*
 * Report outro
 */

.c-report-print__outro {

  padding: $report-page-margin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: $color-primary-light;

  &:before {

    position: relative;
    z-index: $z-index-main;

    display: block;
    width: $report-logo-size-lg;
    height: $report-logo-size-lg;
    margin-bottom: $unit-l;

    background: assets-path("images/gmv-symbol-dark.svg") no-repeat;
    background-size: $report-logo-size-lg $report-logo-size-lg;

    content: "";

  }

}

.c-report-print__outro-text {

  margin-top: auto;

  font-size: 1.125rem;
  line-height: 1.7;
  text-align: center;

  strong {

    display: inline-block;

  }

}

.c-report-print__outro-media {

  margin-top: auto;

}