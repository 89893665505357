/*
 * Reactions
 *
 * List of reactions users can pick from
 */

.c-reactions {

  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-gap: $unit-xs;
  grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));

  @include breakpoint(small) {

    grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));

  }

}

.c-reactions__item {

  margin-top: 0;

  input[type="checkbox"] {

    position: absolute;

    opacity: 0;

  }

  label {

    position: relative;

    display: block;
    margin-top: .125rem;
    height: 100%;

    font-size: .875rem;
    line-height: 1.4285714286;
    color: $color-text;
    font-weight: $semibold;
    text-align: center;

    transition: $transition-default;

    @include breakpoint(medium) {

      font-size: 1rem;
      line-height: 1.5;

    }

    position: relative;

    display: block;
    padding: $unit-xs;

    border-radius: $border-radius-small;

    &:hover,
    &:focus,
    &:active {

      background-color: $color-link-tint;

    }

  }

  input[type="checkbox"]:hover + label,
  input[type="checkbox"]:focus + label {

    background-color: $color-link-tint;

  }

  input[type="checkbox"]:checked + label {

    background-color: $color-accent-green;
    color: $color-text-strong;

    .c-reactions__figure img {

      filter: drop-shadow(0 .25rem .5rem rgba($color-primary-dark, .3));

    }

    .c-reactions__indicator {

      transform: scale(1);

      opacity: 1;

    }

  }

}

.c-reactions__figure {

  position: relative;

  display: block;
  margin-bottom: 0;

  img {

    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;

    filter: drop-shadow(0 .25rem .5rem rgba($color-primary-dark, .15));
    transition: $transition-default;

  }

}

.c-reactions__name {

  display: block;
  margin-top: .125rem;

  font-size: .875rem;
  line-height: 1.4285714286;
  color: $color-text;
  font-weight: $semibold;
  transition: $transition-default;

  // @include breakpoint(medium) {

  //   font-size: 1rem;
  //   line-height: 1.5;

  // }

}

$reaction-indicator: 2rem; // 2.5rem

.c-reactions__indicator {

  position: absolute;
  // top: 50%;
  // left: 50%;
  top: $unit-s;
  left: $unit-s;
  margin-left: (0 - ($reaction-indicator * 0.5));
  margin-top: (0 - ($reaction-indicator * 0.5));
  transform: scale(.5);

  display: flex;
  align-items: center;
  justify-content: center;
  width: $reaction-indicator;
  height: $reaction-indicator;

  font-size: 1rem;
  line-height: 1.5;
  color: $color-text-strong;

  opacity: 0;

  border-radius: 50%;
  background-color: #fff;
  box-shadow: $box-shadow-medium;
  transition: $transition-default;

}

/*
 * Modifiers
 */

.c-reactions__item.is-disabled {

  pointer-events: none;

  img {

    opacity: .3;

  }

  .c-reactions__name {

    color: $color-text-weak;

    opacity: .3;

  }

}

.c-reactions__item.is-added {

  input[type="checkbox"]:checked + label {

    background-color: $color-link-tint;

    .c-reactions__indicator {

      opacity: 0;

    }

    .c-reactions__name {

      color: $color-text;
      opacity: 1;

    }

  }

}