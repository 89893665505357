/*
 * Button group
 *
 * More than one button in a row
 */

.c-button-group {

  margin-bottom: 1rem;
  margin-left: -.25rem;
  margin-right: -.25rem;

  .o-button {

    margin: .25rem;

    min-width: 12rem;

  }

  @include breakpoint(small) {

    margin-left: -.5rem;
    margin-right: -.5rem;

    .o-button {

      margin: .5rem;

    }

  }

}