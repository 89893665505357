/*
 * Question: Annotation
 */

.c-question-annotation {

  // background: red;

}

.c-question-annotation--image {

}

.c-question-annotation--map {

}

.c-question-annotation__lead {

  position: relative;
  margin-bottom: $unit-xs;

  background-color: $color-link-tint;

  $background-square: 1rem;
  $background-square-color: tint($color-primary-dark, 85%);
  $background-square-half: $background-square * 0.5;

  background-image: linear-gradient(45deg, $background-square-color 25%, transparent 25%), linear-gradient(-45deg, $background-square-color 25%, transparent 25%), linear-gradient(45deg, transparent 75%, $background-square-color 75%), linear-gradient(-45deg, transparent 75%, $background-square-color 75%);
  background-size: $background-square $background-square;
  background-position: 0 0, 0 ($background-square-half), ($background-square-half) (0 - $background-square-half), (0 - $background-square-half) 0px;

  img {

    display: block;
    max-width: 100%;
    max-height: 24rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;

  }

}

.c-question-annotation__note {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  font-size: .875rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: $color-text-weak;

}

.c-question-annotation__items {

  display: grid;
  grid-gap: $unit-xs;
  grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));

}

.c-question-annotation__item {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  text-align: center;

}

.c-question-annotation__label {

  margin-top: 0;
  margin-bottom: 0;

  font-family: $font-body;
  letter-spacing: 0;
  font-size: .875rem;
  font-size: .75rem;

}

.c-question-annotation__media {

  margin-bottom: $unit-xs;
  width: 100%;

  img {

    margin-bottom: 0;

    width: 100%;
    max-width: unset;

    // border-radius: $border-radius-small;

  }

}