.c-footer-nav {

  display: block;

}


.c-footer-nav__list {

  list-style: none;
  margin: 0;
  padding: 0;

  margin-bottom: 1rem;

  display: flex;
  flex-flow: column;
  align-items: center;

}

.c-footer-nav__item {

  margin: 0 .5rem;

  font-size: 1rem;
  line-height: 1.5;

}

.c-footer-nav__link {

  display: block;

  padding: .25rem 0;

}

.c-footer-nav__item--actions {

  display: flex;
  justify-content: center;
  width: 100%;

  &:not(:first-child) {

    margin-top: 1rem;

  }

}

.c-footer-nav__item--social {

  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;

  font-size: 1.5rem;
  line-height: 1.5rem;

  .c-footer-nav__link {

    padding: 0;

    color: $color-text-weak;

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong;

    }

  }

  .c-footer-nav__link + .c-footer-nav__link {

    margin-left: 1rem;

  }

  .c-footer-nav__label {

    @include hide-visually;

  }

}

.c-footer-nav__action {

  display: inline-block;

  padding: .125rem .75rem;
  border: .125rem solid $color-primary-light;

  font-size: 1rem;
  line-height: 1.5;
  text-align: center;

  border-radius: $border-radius-small;

}

.c-footer-nav__action:not(:first-child) {

  margin-left: 1rem;

}

.c-footer-nav__action--primary {

    color: $color-text-strong;
    background-color: $color-primary-light;

}

.c-footer-nav__action--secondary {

  color: $color-text-strong;

}

.c-footer-nav__label {}

.c-footer-nav__list--inline {

  flex-flow: row wrap;
  justify-content: center;

  .c-footer-nav__item {


  }

}

.c-footer-nav__list--icons {

  display: flex;
  flex-flow: row;
  justify-content: center;

  .c-footer-nav__item {

    font-size: 1.5rem;
    line-height: 2rem;

  }

  .c-footer-nav__item + .c-footer-nav__item {

    margin-left: 1rem;

  }

  .c-footer-nav__link {

    padding: 0;

    color: $color-text-weak;

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong;

    }

  }

  .c-footer-nav__label {

    @include hide-visually;

  }

}

@include breakpoint(medium) {

  .c-footer-nav__list {

    margin-bottom: .5rem;

  }

  .c-footer-nav__item--social {

    width: auto;
    margin-top: 0;

  }

  .c-footer-nav__list--inline {

  }

}

@include breakpoint(large) {

  .c-footer-nav__item {

      margin: 0;

  }

  .c-footer-nav__list {

    flex-flow: row wrap;

  }

  .c-footer-nav__list--inline {

    display: inline-flex;

    justify-content: flex-start;

    .c-footer-nav__item {


    }

  }

  .c-footer-nav__list--icons {

    display: inline-flex;

    margin-left: 2rem;

  }

  .c-footer-nav__item {

    &:not(:first-child) {

      margin-left: 1rem;

    }

  }

  .c-footer-nav__item--actions {

    width: auto;

    &:not(:first-child) {

      margin-top: 0;
      margin-left: auto;

    }

  }

}