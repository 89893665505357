/*
 * Question: Image
 */

.c-question-image {}

.c-question-image__items {

  margin-bottom: $unit-m;

  &:last-child {

    margin-bottom: 0;

  }

}

.c-question-image__item {

  .o-button {

    margin-top: $unit-xs;

  }

}

.c-question-image__lead {

  margin-bottom: 2rem;

  img {

    margin: 0;
    width: 100%;

    border-radius: $border-radius-small;

  }

}

.c-question-image__media {

  border-radius: $border-radius-small;
  box-shadow: $box-shadow-large;

  overflow: hidden;

  img {

    display: block;
    width: 100%;
    margin: 0;

  }

}

.c-question-image__label {

  margin-top: 1rem;
  margin-bottom: 0;

  font-family: $font-body;
  letter-spacing: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: $color-text-strong;
  width: $regular;

}

.c-question-image__score {

  margin-top: 0;
  margin-bottom: 0;

  font-size: 1rem;
  line-height: 1.5;
  color: $color-text-weak;
  font-weight: $regular;

  em {

    display: block;

    font-style: normal;

    font-size: .875rem;
    line-height: 1.4285714286;

  }

}

.c-question-image__item--winner {

  .c-question-image__media {

    box-shadow: 0 0 0 .375rem $color-success;

  }

  .c-question-image__label {

    color: $color-text-strong;

  }

  .c-question-image__score {

    font-weight: $semibold;
    color: $color-success;

  }

}

/*
 * Modifier
 */

.c-question-image--two {

  .c-question-image__items {

    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr;

    @include breakpoint(small) {

      grid-column-gap: 2rem;
      grid-row-gap: 2rem;

    }

    @include breakpoint(medium) {

      grid-template-columns: 1fr 1fr;

    }

  }

}

.c-question-image--three {

  .c-question-image__items {

    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;

    @include breakpoint(small) {

      grid-column-gap: 2rem;
      grid-row-gap: 2rem;

    }

    @include breakpoint(medium) {

    }

  }

}

.c-question-image--four {

  .c-question-image__items {

    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr 1fr;

    @include breakpoint(small) {

      grid-column-gap: 2rem;
      grid-row-gap: 2rem;

    }

    @include breakpoint(medium) {

      grid-template-columns: 1fr 1fr 1fr 1fr;

    }

  }

}

/*
 * Modifier
 */

.c-question--library {

  .c-question-image__lead {

    margin-bottom: $unit-s;

  }

  .c-question-image__items {

    grid-gap: $unit-s;

  }

  .c-question-image__label {

    margin-top: $unit-xs;

    font-size: .875rem;
    line-height: 1.428571428571429;
    font-family: $font-body;
    letter-spacing: 0;
    text-align: center;

  }

  /*
   * Two image question
   */

  .c-question-image--two {

    .c-question-image__items {

      @include breakpoint(small) {

        grid-template-columns: 1fr 1fr;

      }

    }

  }

  /*
   * Three image question
   */

  .c-question-image--three {

    @include breakpoint(small) {

      display: flex;

      .c-question-image__lead {

        margin-right: $unit-s;
        margin-bottom: 0;

      }

    }

  }

}