.c-header-notifications {

  list-style: none;
  margin: 0;
  padding: 0;

  margin: 0 1rem;

  &:not(:last-child) {

    margin-bottom: .5rem;

  }

}

.c-header-notifications__item {

  margin-top: 0;
  border-bottom: 1px solid $color-border-normal;

  font-size: .875rem;
  line-height: 1.4;

  &:first-child {

    border-top: 1px solid $color-border-normal;

  }

  // &:first-child .c-header-notifications__link {

  //   border-top-left-radius: $border-radius-small;
  //   border-top-right-radius: $border-radius-small;

  // }

}

.c-header-notifications__item--highlight {

  .c-header-notifications__link {

    background-color: $color-primary-light;

  }

}

.c-header-notifications__item--company {

  .c-header-notifications__link {

    // background-color: $color-grey-weak;

  }

}

.c-header-notifications__link {

  position: relative;

  display: block;
  padding: .75rem 1rem .75rem 3rem;
  margin: 0 -1rem;

  color: $color-text;

  strong {

    color: $color-text-strong;
    font-weight: $regular;
  }

  .o-icon {

    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);

    width: 1.5rem;
    height: 1.5rem;
    margin-right: .25rem;
    color: $color-text-weak;

  }

  &:hover,
  &:focus,
  &:active {

    color: $color-text-strong;
    background-color: tint($color-link, 80%);

    strong {

      color: inherit;

    }

  }

}

.c-header-notifications__label {

  display: block;

  font-size: .75rem;
  line-height: 1.5;
  font-weight: $semibold;
  font-family: $font-headline;
  text-transform: uppercase;
  letter-spacing: .05em;
  color: $color-text-strong;

}

@include breakpoint(large) {

  .c-header-notifications {

    margin: 0;

   }

  .c-header-notifications__item {

    font-size: .75rem;
    line-height: 1.5;

  }

  .c-header-notifications__link {

    margin: 0;

  }

}

.c-header-notifications__archive {

  display: block;

  padding: .5rem 1rem;

  font-size: 1rem;
  line-height: 1.5;

  &:hover,
  &:focus,
  &:active {

    color: $color-link;
    background-color: tint($color-link, 80%);

  }

}

/*
 * Modifier
 *
 * Component description
 */

 .c-header-notifications--scroll {

  overflow-y: auto;
  max-height: calc(100vh - 20rem);

 }