/*
 * List icons
 *
 * Simple list of labels with icons
 */

.c-list-icons {

  list-style: none;
  margin: 0;
  padding: 0;

  margin-bottom: 1.5rem;

  a {


  }

  .o-icon {

    width: 2rem;
    margin-right: .5rem;
    vertical-align: middle;

    font-size: 1.5rem;

    color: $color-text-weak;

  }

}

.c-list-icons__item {

  margin-top: 0;
  padding: .25rem 0;

  color: $color-text;

}
