/*
 * Nav list
 *
 * Simple list navigation
 */

.c-nav-list {

  //

}

.c-nav-list__items {

  list-style: none;
  margin: 0;
  padding: 0;

}

.c-nav-list__item {

  margin-top: .0625rem;

}

.c-nav-list__link {

  display: block;
  padding: .75rem $unit-m;

  color: $color-text-weak;

  border-radius: $border-radius-small;

  &:hover,
  &:focus,
  &:active {

    color: $color-text-strong;
    // background-color: $color-link-tint;
    background-color: $color-primary-light;

  }

}

.c-nav-list__item.is-active {

  // background-color: $color-link-tint;
  background-color: $color-primary-light;

  .c-nav-list__link {

    color: $color-text-strong;

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong;
      background: transparent;

    }


  }

}

/*
 * Modifiers
 */

.c-media__options {

  .c-nav-list__item {

    margin-left: 0 - $unit-m;
    margin-right: 0 - $unit-m;

  }

  .c-nav-list__link {

    padding-left: $unit-m;
    padding-right: $unit-m;

  }

  @include breakpoint(medium) {

    .c-nav-list__item {

      margin-left: 0 - $unit-l;
      margin-right: 0 - $unit-l;

    }

    .c-nav-list__link {

      padding-left: $unit-l;
      padding-right: $unit-l;

    }

  }

}