/*
 * Result annotate
 *
 * Annotation image or map with markers on top
 */

.c-result-annotate {

  position: relative;

  margin-bottom: $unit-m;
  overflow: hidden;

  background-color: $color-primary-light;

  &.c-result-annotate--map {

    body.l-print & {

      max-height: 12.5rem; // 200px

    }

  }

  &.c-result-annotate--image {

    body.l-print & {

      max-height: 12.5rem; // 200px

    }

  }

}

.c-result-annotate__content {

  display: flex;
  height: 40rem;

}

/*
 * Annotate controls
 *
 * Controls for interacting with the map/image
 * on desktop: zoom in, zoom out, center.
 */

.c-result-annotate__actions {

  position: absolute;
  z-index: $z-index-main;
  top: $unit-m;
  right: $unit-m;

  display: flex;
  flex-direction: column;

  .o-button__label {

    @include hide-visually;

  }

  .o-button {

    padding: 0;

    min-width: 2.5rem;
    min-height: 2.5rem;

    font-size: 1rem;

    background-color: #fff;
    box-shadow: $box-shadow-small;

    &:focus,
    &:hover {

      background-color: $color-button-regular-hover;

    }

  }

}

.c-result-annotate__zoom-in {

  border-radius: 1.25rem 1.25rem 0 0;

}

.c-result-annotate__zoom-out {

  border-radius: 0 0 1.25rem 1.25rem;

}
.c-result-annotate__center {

  margin-top: $unit-s;

  border-radius: 1.25rem;

}