/*
 * Contextual help
 */
 
$help-main-color: #1cb;
$help-background-color: mix($help-main-color, $color-white, 20%);
$help-border-color: darken($help-background-color, 10%);
$help-margin: 32px;

.l-page--show-help .c-field:has(.c-help) .c-field__count {
  right: rem(28);
}

*:has(> .c-help):not(.c-field__group) {
  position: relative;
}

.c-help {
    
    .c-heading-section > & {
      
      .c-help__action {
        top: rem(8);
      }
    }
    
    .c-tabs-navigation__item:has(&) {
      
      button {
        padding-right: calc($unit-m*1.5);
      }
      
      .c-help__action {
        top: rem(10);
        right: $unit-xs;
      }
    }

  &__action {
    position: absolute;
    top: rem(2);
    right: 0;
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
    
    .c-switchable.is-opened & {
      z-index: 999;
    }
    
    .o-icon {
      font-size: 1.25rem;
      color: $help-main-color;
      margin: 0 !important;
      position: absolute;
      top: 0;
      left: 0;
    }
    
    .o-label {
      @include hide-visually();
    }
    
    //  -----
    //  Absolute positioning for switchable components
    
    .c-switchable > .c-help > & {
      top: rem(18);
      right: rem(48);
    }
    
    .c-switchable.is-conditional > .c-help > &,
    .c-switchable.is-error > .c-help > & {
      right: rem(80);
    }
    
    .c-switchable.is-error.is-error--conditional > .c-help > & {
      right: rem(106);
    }
    
    .c-switchable.is-error > .c-help > & {
      
      .o-icon {
        color: $color-white;
      }
    }
  }
  
  &__title {
    font-size: 1rem !important;
    font-weight: bold;
  }
  
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    width: rem(32);
    height: rem(32);
    color: $color-text-strong;
    background: none;
    cursor: pointer;
  
    .o-label {
      @include hide-visually();
    }
  
    .o-icon {
      font-size: rem(16);
    }
  }
  
  &__description {
    position: absolute;

    //  With the following two lines, we're counteracting page/container paddings
    //  and positions, so we need to have some additional position on the right and 
    //  negative margin on top.
    
    right: rem(32);
    margin-top: rem(-48 * 2);
    
    z-index: 9999;
    width: $contextual-help-width;
    background-color: $help-background-color;
    border: 1px solid $help-border-color;
    border-radius: $border-radius-small;
    padding: $unit-s;
    display: grid;
    grid-gap: $unit-xs;
    opacity: 0;
    visibility: hidden;
    transition: $transition-fast;
    
    .c-help__markdown {
      display: grid;
      grid-gap: $unit-xs;
      font-family: $font-stack-system !important;
      line-height: 1.5 !important;
      background: none !important;
    }
    
    @include breakpoint(to-extra-large) {
      position: fixed !important;
      bottom: $unit-s !important;
      left: $unit-s !important;
      right: $unit-s !important;
      top: auto !important;
      width: auto;
      max-height: rem(220);
      overflow-y: scroll;
    }
    
    * {
      word-break: break-word;
    }

    *:not(a) {
      margin: 0;
      padding: 0;
      font-size: .875rem;
    }
    
    &.is-visible {
      visibility: visible;
      opacity: 1;
    }
    
    
    //  Help styles
    ul {
      margin-left: rem(16) !important;
    }
    
    a[href*="loom"] {
      //  Style as a button
      font-size: .875rem;
      line-height: 1.25rem;
      padding: $unit-xxs $unit-xs;
      border-radius: $border-radius-small;
      font-weight: $bold;
      color: $color-white;
      background: $color-primary-dark;
      display: inline-block;
      
      &:after {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%23ffffff' d='M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9V344c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z'/%3E%3C/svg%3E");
background-repeat: no-repeat no-repeat;
background-position: center center;
background-size: cover;
        width: rem(14);
        height: rem(14);
        display: inline-block;
        position: relative;
        top: rem(2);
        margin-left: $unit-xxs;
      }
    }
  }
  
  
  //  Action bar
  .c-action-bar:has(&) {
    position: relative;
    
    .c-action-bar__action:not(:has(*)) {
      //  We are targeting action bar action, when we it's empty without elements
      display: none;
    }
    
    .c-help {
      position: unset;
      
      &__action {
        top: 0;
        left: 0;
        position: relative;
        display: flex;
        grid-gap: $unit-xs;
        padding: $unit-xxs;
        padding-left: $unit-xs;
        width: auto;
        height: auto;
        border-radius: rem(100);
        border: 1px solid $color-border-normal;
      }
      
      .o-label {
        @include hide-visually('unhide');
        font-size: 0.875rem;
        font-weight: bold;
        text-transform: unset;
      }
      
      
      &__action.is-text-only {
        background: $help-main-color;
        border: 0;
        padding-right: $unit-xs;
        
        .o-label {
          color: $color-white;
        }

        .o-icon {
          display: none;
        }
      }
    }
    
  }
  
  
  
  //  Contextual help hidden
  .l-page:not(.l-page--show-help) & {
    display: none !important;
  }
}