/*
 * Annotate image
 *
 * Component combining image and pins/clusters
 */

@use "sass:math";

.c-annotate-image {

  // Important to show the whole image within
  // the overflown container
  position: absolute;

  // Make sure the pan/zoom on the image is calculated
  // correctly and consistently
  transform-origin: 0 0;

  // Apply a move cursor style on the whole area
  cursor: move;

  // Visual cue
  animation: annotation-image-move 2s ease 1 1s;

}

.c-annotate-image__group {

  position: relative;

  background: $color-primary-dark;
  background-image: linear-gradient(30deg, $color-accent-red, $color-accent-blue, $color-accent-green);
  background-size: cover;

  box-shadow: 0 0 $unit-xl 0 rgba($color-primary-dark, .25);

  .c-feedback & {
    box-shadow: none;
  }

}

.c-annotate-image__proportion {

  display: block;
  margin: 0;
  // max-width: initial;

  visibility: hidden;

}

/*
 * Reactions
 *
 * Reactions are always centered based on the reaction
 * size, not on the reaction + value group width.
 */

$reaction-size-small: 2rem;
$reaction-size-medium: 3rem;

.c-annotate-image__reaction {

  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0 - ($reaction-size-small * 0.5);
  margin-top: 0 - ($reaction-size-small * 0.5);

  pointer-events: none;

  img {

    position: relative;

    display: block;
    width: $reaction-size-small;
    height: $reaction-size-small;
    min-width: $reaction-size-small;
    min-height: $reaction-size-small;
    margin-bottom: 0;

    filter: drop-shadow(0 .125rem .25rem rgba(0,0,0,.8));
    pointer-events: none;

  }

}

.c-annotate-image__value {

  padding: 0 .25rem 0 .75rem;
  margin-left: -.75rem;

  background-color: #fff;
  font-size: .75rem;
  font-weight: bold;
  box-shadow: $box-shadow-medium;
  border-radius: $border-radius-small;

}

/*
 * Cluster
 */

.c-annotate-image__cluster {

  position: absolute;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  .o-icon {

    transition: $transition-default;

  }

  &.is-opened {

    z-index: $z-index-modal;

    .o-icon {

      transform: rotate(180deg);

    }

  }

}

.c-annotate-image__cluster-image {

  display: flex;

  pointer-events: none;

  img {

    position: relative;
    width: $reaction-size-small;
    margin-bottom: 0;

    filter:
      drop-shadow(.075rem 0 0 #fff)
      drop-shadow(-.075rem 0 0 #fff)
      drop-shadow(0 .075rem 0 #fff)
      drop-shadow(0 -.075rem 0 #fff);

    pointer-events: none;

    &:nth-child(1) { z-index: 20; }
    &:nth-child(2) { z-index: 15; }
    &:nth-child(3) { z-index: 10; }
    &:nth-child(4) { z-index: 5; }

    &:last-child {

      // filter: none;

    }

  }

  img + img {

    margin-left: 0 - math.div($reaction-size-small, 3);

  }

}

.c-annotate-image__cluster-value {

  padding: .5rem .5rem .25rem .5rem;
  margin-top: -.625rem;
  border: none;

  background-color: #fff;
  font-size: .75rem;
  line-height: 1.5;
  font-weight: bold;
  box-shadow: $box-shadow-medium;
  border-radius: $border-radius-small;

  .o-icon {

    margin-left: .25rem;

  }

}

button.c-annotate-image__cluster-value {

  &:hover,
  &:focus,
  &:active {

    background: $color-link;
    color: $color-text-strong-invert;

  }

}

.c-annotate-image__cluster-details {

  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: $z-index-dropdown;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: .375rem;
  padding: $unit-xs;
  margin-top: $unit-m;

  opacity: 0;
  transition: $transition-default;
  background-color: #fff;
  box-shadow: $box-shadow-large;
  border-radius: $border-radius-medium;
  pointer-events: none;

  &:before {

    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);

    border: .375rem solid transparent;
    border-bottom-color: #fff;

    content: "";

  }

  .is-opened & {

    margin-top: $unit-s;
    z-index: $z-index-modal;
    opacity: 1;

  }

  .c-annotate-image__reaction {

    position: relative;
    margin: 0;

    img {

      filter: none;

    }

  }

  .c-annotate-image__value {

    margin-left: .25rem;
    padding: 0;

    box-shadow: none;
    background: none;

  }

}