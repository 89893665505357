/*
 * Library
 *
 * Library contains:
 * - Filters (Search, select etc.)
 * - Content
 */

.c-library {

  position: relative;

  display: flex;

  overflow: hidden;

  /*
   * Compensate for container padding
   */

  .c-modal & {

    margin: 0 - $unit-m;

    @include breakpoint(medium) {

      margin: 0 - $unit-l;

    }

  }

}

/*
 * Library options
 *
 * Sidebar with form elements to filter
 * content items.
 */

$library-options-width: 16rem;
$library-options-width-wide: 18rem;

.c-library__options {

  position: relative;
  z-index: $z-index-library + 10;

  width: $library-options-width;
  padding: $unit-m;

  overflow-y: auto;

  background-color: #fff;
  box-shadow: $box-shadow-small;
  transition: all .1s ease-in;

  @include breakpoint(to-medium) {

    position: absolute;
    z-index: $z-index-library + 10;
    top: 0;
    left: -100%;
    bottom: 0;

    box-shadow: $box-shadow-medium;

  }

  @include breakpoint(extra-small) {

    width: $library-options-width-wide;

  }


  @include breakpoint(medium) {

    flex: 1 0 $library-options-width-wide;
    padding: $unit-l;

  }

  // For scrolling purposes
  &:after {

    display: block;
    height: $unit-m;
    margin-bottom: 0 - $unit-m;

    content: "";

  }

  .is-options-opened & {

    left: 0;

  }

}

/*
 * Options toggle
 */

.c-library__toggle {

  position: fixed;
  z-index: $z-index-library + 10;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 3rem;
  height: 3rem;
  border: none;
  padding: 0;

  background: $color-link;
  color: $color-text-strong-invert;
  border-radius: 0;
  border-top-right-radius: $border-radius-small;

  transition: all .1s ease-in;

  &:hover,
  &:focus,
  &:active {

    background-color: $color-link-hover;
    color: $color-text-strong-invert;

  }

  .o-icon {

    top: 0;
    margin-left: 0!important;
    margin-right: 0!important;

  }

  .o-label {

    @include hide-visually();

  }

  .is-options-opened & {

    left: $library-options-width;

    @include breakpoint(extra-small) {

      left: $library-options-width-wide;

    }

  }

  @include breakpoint(medium) {

    display: none;

  }

}

/*
 * Library content
 */

.c-library__content {

  display: flex;
  flex-direction: column;
  width: 100%;

  &.is-scrollable {

    overflow-y: scroll;

    .c-library__items {

      overflow-y: initial;

    }

  }

}

/*
 * Search
 */

.c-library__search {

  position: relative;
  z-index: $z-index-tooltip;

  padding: $unit-m;
  padding-bottom: 0;

  &:before {

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    height: $unit-m;
    background-image: linear-gradient(to bottom, #fff 50%, rgba(#fff, 0));

    content: "";

  }

  @include breakpoint(medium) {

    padding: $unit-l;
    padding-bottom: 0;

  }

}

.c-library__message{

  margin: $unit-m;
  margin-bottom: 0;
  
  .o-icon {
    top: 50%;
    transform: translateY(-50%);
  }

  @include breakpoint(medium) {

    margin: $unit-l;
    margin-bottom: 0;

  }

}

.c-library__load-more {

  padding: $unit-m;
  margin-top: -$unit-m*2;

  @include breakpoint(medium) {

    padding: $unit-l;
    margin-top: -$unit-l*2;

  }

}

.c-library__items {

  position: relative;

  display: grid;
  grid-gap: $unit-m;
  align-content: flex-start;

  padding: $unit-m;
  // height: 100%;  // 2021-08-31: Removed this height as it made conflicts when using additional action button below
  overflow-y: scroll;

  // For scrolling purposes
  &:after {

    display: block;
    height: $unit-m;
    margin-bottom: 0 - $unit-m;

    content: "";

  }

  @include breakpoint(small) {

    grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));

    &:after {

      grid-column: -1 / 1;

    }

  }

  @include breakpoint(medium) {

    padding: $unit-l;

  }

  @include breakpoint(large) {

  }

  &.is-loading {

    &:before {

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 2rem;
      height: 2rem;
      border: .1875rem solid darken($color-primary-light, 10%);

      border-radius: 50%;
      border-top-color: $color-link;

      content: "";

      animation: rotate360 1s infinite linear;

    }

  }

  &.is-empty {

    &:before {

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      color: $color-text;
      text-align: center;
      font-weight: $medium;
      font-size: 1rem;

      content: attr(data-results);

    }

  }

}

.c-library__item {

  position: relative;

  padding: $unit-m;

  border-radius: $border-radius-small;
  background-color: #fff;
  border: .0625rem solid $color-border-normal;

  &:hover {

    border-color: transparent;
    box-shadow: $box-shadow-large;
    transition: all .1s ease-in;

  }

}

.c-library__results {

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

}

.c-library__results-message {

  margin: 0;

  color: $color-text;
  text-align: center;
  font-weight: $medium;
  font-size: 1rem;

}

.c-library__state--floating {

  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

}

/*
 * Actions overlaying content items
 */

.c-library__actions {

  position: absolute;
  top: $unit-s;
  right: $unit-s;

}