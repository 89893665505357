/*
 * Toggle
 *
 * Toggle component (navigation & content)
 */

.c-toggle {

}



/*
 * Toggle Navigation
 */

.c-toggle-navigation {
  
}

.c-toggle-navigation__container {
  @include list-reset();
  margin-bottom: $unit-m;
  display: inline-flex;
  border: 2px solid $color-border-weak;
  border-radius: rem(100);
  padding: rem(2);
}

.c-toggle-navigation__item {
  margin: 0;
  
  button {
    border: 0;
    background: none;
    font-family: $system-ui;
    font-size: rem(16);
    line-height: 1.5;
    font-weight: $semibold;
    cursor: pointer;
    padding: rem(6) rem(12);
    color: $color-text;
    white-space: nowrap;
    
    &[aria-selected="true"] {
      background: $color-primary-dark;
      color: #fff;
      border-radius: rem(100);
    }
  }
}



/*
 * Toggle Content
 */

.c-toggle-content {
  width: 100%;
}

.c-toggle-content__container {
  margin-bottom: $unit-m;
  // width: 100%;
}


.c-toggle-button {
  position: relative;
  flex: 1 0 3.5rem;
  height: rem(56);
  max-width: rem(56);
  padding: rem(18) 0;
  margin-left: auto;
  font-size: rem(20);
  line-height: 1;
  color: $color-text-weak;
  text-align: center;
  background: transparent;
  border: none;
  cursor: pointer;

  .o-label {
    @include hide-visually();
  }

  .o-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 !important;
    transition: all .2s ease-out;
    transform: translate(-50%, -50%);
    
    .is-open & {
      transform: translate(-50%, -50%) rotate(180deg) !important;
    }
  }

  &:hover,
  &:focus,
  &:active {

    color: $color-link;
    outline: none;

    &:before {

      background-color: $color-link-tint;
      background-color: rgba($color-link, .1);

    }

  }

  &:before {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;

    background-color: rgba(255,255,255,0);
    transition: all .1s ease-in;

    content: "";

  }

}