/*
 * Loading
 *
 * Small loading indicator
 */

.o-loading {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 1rem;
  height: 1rem;
  border: .125rem solid darken($color-primary-light, 10%);

  border-radius: 50%;

  border-top-color: $color-link;

  content: "";

  animation: rotate360 1s infinite linear;

}

.o-loading--large {

  width: 2rem;
  height: 2rem;

  border-width: .1875rem;

}