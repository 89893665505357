/*
 * Feedback
 *
 * Different types of feedback
 */

.c-feedback {

  position: relative;

  margin-bottom: $unit-m;
  border: .0625rem solid $color-border-weak;

  border-radius: $border-radius-small;
  transition: all .1s ease-in;
  
  overflow: hidden;
  
  min-height: rem(calc(116px + 48px));

  &:hover {

    border-color: transparent;
    box-shadow: $box-shadow-large;

  }

  @include breakpoint(medium) {

    &:hover {

      .c-feedback__action {

        opacity: 1;

      }

    }

  }

}

.c-feedback + .c-feedback {

  margin-top: 0 - $unit-s;

}

.c-feedback__header {

  padding: 1rem 1rem .5rem 1rem;
  padding-right: rem(16*2 + 20);

  // margin-bottom: .5rem;

  font-size: .875rem;
  line-height: 1.4285714286;
  
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0;
  
  > * {
    position: relative;
    text-align: center;
  }
  
  > :nth-child(2n+1) {
    border-bottom: 1px solid $color-border-weak;
    border-right: 1px solid $color-border-weak;
    padding: $unit-xxs $unit-xs;
    padding-left: 0;
    top: rem(-1);
  }
  
  > :nth-child(2n) {
    border-bottom: 1px solid $color-border-weak;
    border-left: 1px solid $color-border-weak;
    padding: $unit-xxs $unit-xs;
    padding-right: 0;
    top: rem(-1);
    left: rem(-1);
  }

  //  The following two lines are to make sure we take care of the cases
  //  where we have 2 or 3 items only!

  > :last-child, > :nth-last-child(2) { border-bottom: 0 !important; }
  > :first-child, > :nth-child(2) { border-bottom: 1px solid $color-border-weak !important; }

  @include breakpoint(medium) {
    padding: 1.5rem 1.5rem .5rem 1.5rem;
    padding-right: rem(24*2 + 20);
  }
  
  .l-main > .c-feedback & {
    
    @include breakpoint(large) {
      display: flex;
      grid-gap: $unit-xs;
      
      > *:not(.c-feedback__actions),
      > :first-child,
      > :nth-child(2) {
        border: 0 !important;
        border-left: 1px solid transparent-color($color-black, .1) !important;
        padding: 0 0 0 $unit-xs !important;
      }
      
      > :first-child {
        border-left: 0 !important;
        padding-left: 0 !important;
      }
    }
  }

}

.c-feedback__subject {
  padding: 0 1rem;
  padding-right: rem(16*2 + 20);
  font-weight: $bold;
  color: $color-primary-dark;
  
  @include breakpoint(medium) {
    padding: 0 1.5rem;
    padding-right: rem(24*2 + 20);
  }
}

.c-feedback__content {

  display: flow-root;
  padding: 0 1rem 1rem 1rem;
  padding-right: rem(16*2 + 20);

  // word-break: break-all;

  p {

    margin-bottom: $unit-xs;
    margin-top: 0;

    font-size: .875rem;
    line-height: 1.4285714286;
    
    word-break: break-word;

    &:first-child {

      margin-top: 0;

    }

  }

  @include breakpoint(medium) {

    padding: 0 1.5rem 1.5rem 1.5rem;
    padding-right: rem(24*2 + 20);

    p {

      font-size: 1rem;
      line-height: 1.5;

    }

  }

}

.c-feedback__media {

  margin-top: .125rem;

  @include breakpoint(extra-small) {

    float: left;
    margin-right: $unit-s;
    max-width: 10rem;

  }

  img {

    max-width: 100%;
    margin-bottom: 0;

    border-radius: $border-radius-small;

  }

  // display: none;

}

.c-feedback__sentiment {

  margin-right: .5rem;
  padding-right: .5rem;
  border-right: .0625rem solid $color-border-normal;

  font-weight: $semibold;
  color: $color-text-strong;

  .o-icon {

    position: relative;
    top: .125rem;

  }

  .o-icon-grin      { color: darken($color-accent-green, 10%); }
  .o-icon-meh       { color: darken($color-accent-yellow, 10%); }
  .o-icon-meh-blank { color: darken($color-accent-blue, 10%); }
  .o-icon-frown     { color: darken($color-accent-red, 10%); }

}

.c-feedback__type {

  // display: none;

  // margin-right: .5rem;
  // padding-right: .5rem;
  border-right: .0625rem solid $color-border-normal;

  font-weight: $semibold;
  color: $color-text-strong;

}

.c-feedback__author {

  margin-top: 0;
  margin-bottom: 0;
  // margin-right: .5rem;

  font-weight: $semibold;
  color: $color-text-strong;

}

.c-feedback__reaction {
  display: flex;
  justify-content: center;
  
  img {
    max-width: rem(20);
    margin: 0 $unit-xxs 0 0;
  }
}

.c-feedback__time {

  color: $color-text-weak;

}

.c-feedback__actions {

  //  =info (2022-02-21)
  //  We opted-out of using feedback actions for now, when we decide to start
  //  using them again, simply remove the following line.
  
  //  =info =update (2022-05-04)
  //  Feedback actions are still removed by default, since having feedback actions
  //  will eventually break the layout of feedback component when used as part
  //  of "Browse Feedback" modal. Instead, we've chosen to style feedback
  //  actions if the parent is not ".c-feedback--browse", which indicates
  //  "Browse Feedback" modal being used.
  
  //  For further info scroll down to "=feedback=actions" part. This is where
  //  we define additional styles to properly display "feedback header".

  display: flex;

  position: absolute;
  top: $unit-s !important;
  right: $unit-s !important;
  left: auto !important;

  list-style: none;
  margin: 0;
  padding: 0 !important;

  // margin-top: .5rem;

  display: flex;
  flex-direction: column-reverse;
  grid-gap: $unit-xs;
  align-items: center;
  
  border: none !important;

  @include breakpoint(medium) {

    top: $unit-m !important;
    right: $unit-m !important;

  }

}

.c-feedback__action {

  display: flex;
  align-items: center;

  border: none;
  background: transparent;
  padding: 0;

  font-size: .875rem;
  line-height: 1.4285714286;
  cursor: pointer;

  .o-icon {

    font-size: 1rem;
    color: $color-text-weak;

  }

  .o-label {

    @include hide-visually(hide);
    color: $color-text;

  }

  .o-icon + .o-label {

    margin-left: .5rem;

  }

  .o-icon-star {

    display: block;

  }

  .o-icon-star-filled {

    display: none;

  }

  &:focus {

    opacity: 1;

  }

  &:hover,
  &:focus,
  &:active {

    .o-icon {

      color: $color-link;

    }

  }

  @include breakpoint(medium) {

    opacity: 0;

    .o-icon {

      font-size: 1.25rem;

    }

  }

}

.c-feedback__reply {

  padding: 0 1rem 1rem 1rem;
  padding-right: rem(16*2 + 20);


  p {

    margin-bottom: 0;
    margin-top: 0;

    font-size: .875rem;
    line-height: 1.4285714286;
    color: $color-text;

  }

  .o-icon {

    margin-right: .5rem;
    color: $color-text;

  }

  @include breakpoint(medium) {

    padding: 0 1.5rem 1.5rem 1.5rem;
    padding-right: rem(24*2 + 20);

  }

}

.c-feedback__reply-meta {

  display: block;
  margin-bottom: .5rem;

  font-size: .875rem;
  line-height: 1.4285714286;
  color: $color-text-weak;

}

.c-feedback__form {

  display: none;

  padding: 1rem;

  background-color: #fff;
  border-top: .0625rem solid $color-border-weak;
  border-bottom-left-radius: $border-radius-small;
  border-bottom-right-radius: $border-radius-small;

  @include breakpoint(medium) {

    padding: 1.5rem;

  }

}

/*
 * Modifiers
 */

.c-feedback--highlight {

  background-color: $color-success-tint;
  border-color: transparent;

  .c-feedback__action--star {

    opacity: 1;

    background-color: $color-success-tint;

    border-radius: 50%;

    box-shadow: 0 0 0 .25rem $color-success-tint;

    .o-icon {

      color: $color-success;

    }

    .o-icon-star {

      display: none;

    }

    .o-icon-star-filled {

      display: block;

    }

  }

}

.c-feedback--reply {

  box-shadow: $box-shadow-extra-large;

  .c-feedback__form {

    display: block;

  }

}

/*
 * Modifiers: Browse feedback
 *
 * - Make text smaller
 * - Image can be full width
 */

.c-feedback--browse {

  margin-bottom: 0;
  height: 100%;

  .c-feedback__content {

    p {

      font-size: .875rem;
      line-height: 1.5;

    }

  }

  .c-feedback__media {

    display: flex;
    align-items: center;

    border-radius: $border-radius-small;

    max-width: initial;
    width: 100%;
    margin-right: 0;
    margin-top: $unit-xs;
    margin-bottom: $unit-s;

    // overflow: hidden;
    // background-color: $color-grey-x-light;

    img {

      max-height: 12rem;
      margin-left: auto;
      margin-right: auto;

      border-radius: 0;

    }

  }

  &.c-feedback--highlight {

    .c-feedback__media {

      // background-color: #fff;
      background-color: transparent;

    }

  }

  &.c-feedback--only-media {

    .c-feedback__media {

      margin-bottom: 0;

    }

    .c-feedback__content p {

      display: none;

    }

  }

}

.c-feedback--browse + .c-feedback--browse {

  margin-top: 0;

}

/*
 * Modifiers: Location
 *
 * - Media can be swapped with the location reference image
 */

.c-feedback--location {

  .c-feedback__media {

    position: relative;

  }

  .c-feedback__image {}

  .c-feedback__location {

    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 1;
    transition: $transition-default;
    
    object-fit: cover;
    // overflow: hidden;
    
//     margin-left: -$unit-s;
//     margin-right: -$unit-s;
// 
//     @include breakpoint(medium) {
//       margin-left: -$unit-m;
//       margin-right: -$unit-m;
//     }

    
    img {
      max-height: none;
    }

  }

//   .c-feedback__media:focus,
//   .c-feedback__media:hover {
// 
//     > img {
// 
//       opacity: 0;
// 
//     }
// 
//     .c-feedback__location {
// 
//       opacity: 1;
// 
//     }
// 
//   }

}

/*
 * Print report
 *
 * Modifications for printed reports
 */

.l-print {

  .c-feedback {

    page-break-inside: avoid;

    border: solid .0625rem $color-border-weak;

    box-shadow: none;
    background-color: transparent;

  }

  .c-feedback__header {

    display: flex;
    align-items: center;
    padding: 1.25rem 1.25rem .25rem 1.25rem;

    font-size: .75rem;
    line-height: 1.5;

  }

  .c-feedback__content {

    padding: .25rem 1.25rem 1.25rem 1.25rem;

    p {

      font-size: .75rem;
      line-height: 1.5;

    }

  }

  .c-feedback__reply {

    padding: 0 1.25rem 1.25rem 1.25rem;
    margin-top: -.75rem;

    .o-icon {

      display: none;

    }

    p {

      font-size: .75rem;
      line-height: 1.5;

    }

  }

  .c-feedback__reply-meta {

    margin-bottom: .25rem;

    font-size: .75rem;
    line-height: 1.5;

  }

  .c-feedback__time,
  .c-feedback__author,
  .c-feedback__type {

    order: 2;

  }

  .c-feedback__actions {

    position: relative;
    top: 0;
    left: 0;
    margin-right: .5rem;

    order: 1;
    display: none;

  }

  .c-feedback__actions li {

    margin: 0;

  }

  .c-feedback__action .o-icon {

    display: none;

    // font-size: 1rem;

  }

  // Override
  .c-feedback--highlight {

    .c-feedback__actions {

      display: block;

    }

    .c-feedback__action--reply,
    .c-feedback__action--trash {

      display: none;

    }

    .c-feedback__action--star {

      display: block;
      padding-left: .375rem;
      padding-right: .375rem;

      font-size: .625rem;
      line-height: 1.3333333333;
      font-family: $font-headline;
      font-weight: $semibold;
      font-variant: small-caps;
      text-transform: uppercase;
      letter-spacing: .025em;

      border-radius: $border-radius-small;
      vertical-align: middle;
      color: $color-success;
      box-shadow: none;
      background-color: $color-success-tint;
      -webkit-print-color-adjust: exact;

      .o-icon {

        display: none;

      }

      .o-label {

        @include hide-visually(unhide);
        margin: 0;

        color: inherit;

      }

    }

  }

}

//  Browse feedback item and info window
//  These updates were taken from components.browse-feedback.scss

.c-browse-feedback__item,
.c-info-window {
  
  .c-feedback__header {
    padding: 1rem 1rem .5rem 1rem;

    @include breakpoint(medium) {
        padding: 1.5rem 1.5rem .5rem 1.5rem;
    }
  }

  .c-feedback__content,
  .c-feedback__reply {
    padding: 0 1rem 1rem 1rem;

    @include breakpoint(medium) {
      padding: 0 1.5rem 1.5rem 1.5rem;
    }
  }
  
  .c-feedback__actions {
    display: none !important;
  }
}