/*
 * Modal
 */

.c-modal {

  position: fixed;
  z-index: $z-index-modal;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: $unit-m;
  overflow-y: auto;
  display: none;

  background-color: transparentize($color-grey-x-light, .2);

  // For scrolling purposes
  &:after {

    display: block;
    height: $unit-m;
    margin-bottom: 0 - $unit-m;

    content: "";

  }

}

.c-modal__container {

  display: block;

  background-color: #fff;
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-large;

  animation: modal-animate .25s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;

}

.c-modal__header {

  position: relative;

  display: flex;
  padding: $unit-xs $unit-m;
  border-bottom: .0625rem solid $color-border-weak;

  min-height: $unit-xs + 3rem + $unit-xs;

  @include breakpoint(small) {

    padding: $unit-s $unit-m;
    min-height: $unit-s + 3rem + $unit-s;

  }

  @include breakpoint(medium) {

    padding: $unit-s $unit-l;

  }

}

.c-modal__title {

  margin-top: auto;
  margin-bottom: auto;
  margin-right: 3rem;

  font-size: 1rem;
  line-height: 1.5;
  // font-family: $font-body;

  @include breakpoint(small) {

    font-size: 1.125rem;

  }

  @include breakpoint(medium) {

    font-size: 1.25rem;

  }

}

.c-modal__close {

  position: absolute;
  top: $unit-xs;
  right: $unit-xs;
  bottom: $unit-xs;

  padding: 0;
  min-width: 3rem;
  min-height: 3rem;

  color: $color-text-weak;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {

    color: $color-link;
    background-color: $color-grey-x-light;

  }

  .o-icon {

    font-size: 1.25rem;

  }

  .o-label {

    @include hide-visually();

  }

  @include breakpoint(small) {

    top: $unit-s;
    right: $unit-m;
    bottom: $unit-s;

  }

}

.c-modal__content {

  padding: $unit-m;

  @include breakpoint(medium) {

    padding: $unit-l;

  }

}

/*
 * Modifiers
 */

.c-modal--full-screen {

  padding: 0;

  &:after {

    display: none;

  }

  .c-modal__container {

    border-radius: 0;
    box-shadow: none;

  }

  .c-modal__container {

    display: flex;
    flex-direction: column;
    height: 100%;

  }

  .c-modal__content {

    display: flex;
    flex-direction: column;
    height: 100%;

    overflow: auto;

    // > *:last-child:after {

    //   height: $unit-m;
    //   display: block;
    //   content: "";

    // }

  }

  @include breakpoint(small) {

    .c-nav-tabs {

      margin-left: auto;
      margin-right: auto;

    }

  }

}

.c-modal--fill-screen {

  .c-modal__container {

    min-height: 100%;

  }

}

.c-modal--centered,
.c-modal--centered-top,
.c-modal--centered-wide {

  display: flex;
  align-items: flex-start;
  justify-content: center;

  .c-modal__container {

    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    max-width: $container-max-width;

  }

}

.c-modal--centered-wide {

  .c-modal__container {

    max-width: $container-max-width-wide;

  }

}

.c-modal--centered-top {

  .c-modal__container {

    margin-top: 0;

  }

}

.is-modal-opened {

  overflow: hidden;
  height: 100vh;

}


//  SVG Container
.c-modal__content:has(.svg__container) {
  display: flex;
  
  .svg__container {
    width: 100%;
    
    pre {
      overflow: visible;
      margin: 0;
      padding: 0;
      border: 0;
      text-align: center;
    }
    
    svg {
      
      .svg-pan-zoom_viewport {
        transform: none !important;
      }
    }
  }
  
  .mermaid {
    display: none;
    
    &[data-processed="true"] {
      display: block;
    }
    
    .edgeLabel {
      
      div {
        background: $color-white;
      }
    }
    
    .node .label div {
      text-align: center;
    }
  }
}


@keyframes modal-animate {

  0% {

    opacity: 0;
    transform: scale(.5);

  }

  100% {

    opacity: 1;
    transform: scale(1);

  }

}