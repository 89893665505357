/*
 * Forms
 *
 * Layout and other overall form elements
 */

@use "sass:math";

.l-form {

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  margin-bottom: 1.5rem;

  &:last-child {

    margin-bottom: 0;

  }

}

.l-form__item {

  width: 100%;

  margin-bottom: $unit-m;

  &:last-child {

    margin-bottom: 0;

  }
  
  .l-form__item.group-with-next + & {
    margin-top: - calc($unit-m * 0.75);
  }

}

.l-form__item--button-group {
  
  .c-field__group {
    display: flex;
    grid-gap: math.div($unit-xs, 1.5);
    flex-wrap: wrap;
    
    > * {
      margin: 0;
    }
  }

}

.l-form__item.is-disabled {
  
  .o-input,
  .o-checkbox,
  .o-radio,
  .o-select,
  .o-textarea,
  .o-button {
    cursor: none;
    pointer-events: none;
    opacity: .5;
  }
  
  input, textarea, select {
  }
  
  input[type="radio"],
  input[type="checkbox"] {
    
  }
}

.l-form__item--l {

  @include breakpoint(medium) {

    flex: 0 1 span(6 of 8);
    width: span(6 of 8);

  }

}

.l-form__item--m {

  @include breakpoint(medium) {

    flex: 0 1 span(4 of 8);
    width: span(4 of 8);

  }

}

.l-form__item--s {

  @include breakpoint(medium) {

    flex: 0 1 span(3 of 9);
    width: span(3 of 9);

  }

}

.l-form__item--xs {

  @include breakpoint(medium) {

    flex: 0 1 span(2 of 8);
    width: span(2 of 8);
    display: flex;
    flex-direction: column;
    
    .c-field__label {
      margin-top: auto;
    }

  }

}

/*
 * Modifiers
 */

.l-form--single-column {

  flex-flow: column;

}

.l-form--single-row {

  flex-flow: row;
  align-items: flex-end;

  .l-form__item {

    margin-bottom: 0;

  }

}