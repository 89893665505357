.o-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.o-icon-name {
  font-size: 32px;
  color: red;
}
========================================== */

.o-icon-balance-scale {
  width: 1.25em;
}

.o-icon-meh-blank {
  width: 0.96875em;
}

.o-icon-smile {
  width: 0.96875em;
}

.o-icon-grin {
  width: 0.96875em;
}

.o-icon-frown {
  width: 0.96875em;
}

.o-icon-meh {
  width: 0.96875em;
}

.o-icon-share {
  width: 1.125em;
}

.o-icon-user-lock {
  width: 1.25em;
}

.o-icon-user-edit {
  width: 1.25em;
}

.o-icon-arrow-to-bottom {
  width: 0.75em;
}

.o-icon-lock {
  width: 0.875em;
}

.o-icon-phone-laptop {
  width: 1.25em;
}

.o-icon-eye {
  width: 1.125em;
}

.o-icon-desktop {
  width: 1.125em;
}

.o-icon-tablet {
  width: 0.875em;
}

.o-icon-mobile {
  width: 0.625em;
}

.o-icon-edit {
  width: 1.125em;
}

.o-icon-file-spreadsheet {
  width: 0.75em;
}

.o-icon-file-pdf {
  width: 0.75em;
}

.o-icon-star-filled {
  width: 1.125em;
}

.o-icon-star {
  width: 1.125em;
}

.o-icon-reply {
  width: 1.125em;
}

.o-icon-caret-down {
  width: 0.625em;
}

.o-icon-caret-up {
  width: 0.625em;
}

.o-icon-triangle {
  width: 1.125em;
}

.o-icon-megaphone {
  width: 1.125em;
}

.o-icon-file-chart-line {
  width: 0.75em;
}

.o-icon-home {
  width: 1.125em;
}

.o-icon-poll {
  width: 0.875em;
}

.o-icon-upload {
  width: 1.125em;
}

.o-icon-external-link {
  width: 1.125em;
}

.o-icon-minus {
  width: 0.875em;
}

.o-icon-plus {
  width: 0.875em;
}

.o-icon-calendar-alt {
  width: 0.875em;
}

.o-icon-ellipsis-v {
  width: 0.25em;
}

.o-icon-map-pin {
  width: 0.625em;
}

.o-icon-arrow-left {
  width: 0.875em;
}

.o-icon-arrow-right {
  width: 0.875em;
}

.o-icon-arrow-down {
  width: 0.875em;
}

.o-icon-arrow-up {
  width: 0.875em;
}

.o-icon-linkedin {
  width: 0.875em;
}

.o-icon-youtube {
  width: 1.125em;
}

.o-icon-instagram {
  width: 0.875em;
}

.o-icon-facebook {
  width: 0.875em;
}

.o-icon-x {
  width: 0.875em;
}

.o-icon-bell {
  width: 0.875em;
}

.o-icon-angle-down {
  width: 0.625em;
}

.o-icon-angle-left {
  width: 0.375em;
}

.o-icon-angle-right {
  width: 0.375em;
}

.o-icon-angle-up {
  width: 0.625em;
}

.o-icon-building {
  width: 0.875em;
}

.o-icon-map-marked {
  width: 1.125em;
}

.o-icon-map {
  width: 1.125em;
}

.o-icon-images {
  width: 1.125em;
}

.o-icon-user {
  width: 0.875em;
}

.o-icon-users {
  width: 1.25em;
}

.o-icon-times {
  width: 0.625em;
}

.o-icon-bars {
  width: 0.875em;
}