/*
 * Question: Multiple
 */

.c-question-multiple__items {

  display: grid;
  grid-column-gap: $unit-xs;
  grid-template-columns: repeat(4, 1fr);

  @include breakpoint(extra-small) {

    grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));

  }

}

.c-question-multiple__item {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  text-align: center;

}

.c-question-multiple__media {

  margin-bottom: $unit-xs;
  width: 100%;

  img {

    margin-bottom: 0;

    width: 100%;
    max-width: unset;

    border-radius: $border-radius-small;

  }

}

.c-question-multiple__label {

  margin-top: 0;
  margin-bottom: 0;

  font-family: $font-body;
  letter-spacing: 0;
  font-size: .875rem;
  font-size: .75rem;

  @include breakpoint(to-extra-small) {

    writing-mode: sideways-lr;
    text-orientation: sideways;

  }

  @include breakpoint(medium) {

    // font-size: 1rem;

  }

  @include breakpoint(large) {

    max-height: 10rem;

  }

}

.c-question-multiple__bar {

  position: relative;

  height: 10rem;
  width: 100%;
  margin-top: 2.75rem;
  margin-bottom: .125rem;

}

.c-question-multiple__score {

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: $color-success;
  border-bottom-left-radius: $border-radius-small;
  border-bottom-right-radius: $border-radius-small;

}

.c-question-multiple__percent,
.c-question-multiple__votes {

  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;

}

.c-question-multiple__percent {

  margin-bottom: 1.5rem;

  font-size: 1rem;
  line-height: 1.25;
  text-align: center;
  font-weight: $bold;
  color: $color-success;

}

.c-question-multiple__votes {

  margin-bottom: .25rem;

  font-size: .875rem;
  line-height: 1.4285714286;
  text-align: center;
  color: $color-text-weak;

}

/*
 * Modifiers
 */

.c-question--library {

  .c-question-multiple__items {

    grid-gap: $unit-s;

    @include breakpoint(small) {

      grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));

    }

  }

  .c-question-multiple__media {

    margin-bottom: 0;

  }

  .c-question-multiple__label {

    margin-top: $unit-xs;

    font-size: .875rem;
    line-height: 1.428571428571429;
    font-family: $font-body;
    letter-spacing: 0;

  }

}