/*
 * Filter tabs
 *
 * Not proper tabs, but links to certain filters.
 * Used within the action bar. If used elsewhere, different styles should apply.
 */
 
.c-filter-tabs {
  
  ul {
    @include list-reset();
    display: flex;
    background: $color-primary-light;
    border-radius: $border-radius-small;
    overflow: hidden;
  }
  
  li {
    margin: 0;
    
    &:first-child a {
      border-top: 0;
    }
  }
  
  a {
    font-size: 0.875rem;  //  14px
    line-height: 1;
    font-weight: $bold;
    text-transform: uppercase;
    padding: $unit-xs $unit-s;
    border-top: 1px solid $color-white;
    display: block;
    transition: $transition-fast;
    
    &:hover {
      background: $color-text-strong;
      color: $color-white;
    }
  }
  
  .is-active a {
    background: $color-primary-dark;
    color: $color-white;
  }
  
  
  @include breakpoint (to-small) {
    
    ul {
      flex-direction: column;
    }
  }
  
  
  @include breakpoint(small) {
    
    li {
      
      &:first-child a {
        border-left: 0;
      }
    }
    
    a {
      border-left: 1px solid $color-white;
      border-top: none !important;
    }
  }
  
  
  @include breakpoint(medium) {
    
    a {
      padding-top: 1.0625rem; //17px
      padding-bottom: 1.0625rem;
    }
  }
}