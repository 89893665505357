/*
 * Browser upgrade
 *
 * Information for upgrading to up–to–date browsers
 * This CSS is copied and included in an inline <style>
 * so some generic styling is also added here.
 */

.c-browser {

  box-sizing: border-box;

  text-align: center;
  font-family: $font-body;
  color: $color-text;

  * {

    box-sizing: border-box;

    margin: 0;
    padding: 0;

  }

  strong {

    color: $color-text;
    font-weight: $semibold;

  }

  a {

    color: $color-link;

  }

}

.c-browser__title {

  margin-bottom: .5rem;

  font-weight: $semibold;

}

.c-browser__description {

  margin-bottom: 0;
  max-width: 72ch;
  margin-left: auto;
  margin-right: auto;

  color: $color-text-strong;
  font-weight: $regular;
  font-size: 1rem;
  line-height: 1.5;

}

.c-browser__list {

  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  width: 100%;
  margin: 2rem 0;

}

.c-browser__item {

  display: flex;
  flex-basis: 12rem;
  flex-direction: column;
  align-items: center;

  border: .0625rem solid $color-border-weak;
  padding: 1rem 1rem;
  margin: .5rem;

  border-radius: $border-radius-small;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {

    background-color: $color-link-tint;
    border-color: transparent;

    .c-browser__name {

      color: $color-link;

    }

  }

}

.c-browser__logo {

  display: block;
  width: 6rem;
  margin-bottom: .5rem;

}

.c-browser__name {

  margin-bottom: 0;

  font-size: 1.125rem;
  font-weight: $semibold;

}

.c-browser__meta {

  margin-bottom: 0;

  font-size: .875rem;
  color: $color-text-weak;

}

.c-browser__details {

  max-width: 72ch;
  margin-left: auto;
  margin-right: auto;

  font-size: .875rem;
  line-height: 1.4285714286;
  color: $color-text;

}

/*
 * Modifier
 */

.c-browser--fill {

  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: 80vh;

  padding: 1rem;

  @include breakpoint(medium) {

    padding: 2rem;

  }

  @include breakpoint(large) {

    padding: 6rem 2rem;

  }

}