/*
 * Hide visually
 *
 * Hide element visually but keep it for screen readers
 */

.u-hide-visually {

  @include hide-visually();

}

/*
 * Text
 *
 * Specific text sizes
 */

.u-text-small {

  font-size: .875rem;
  line-height: 1.4285714286;

  color: $color-text-weak;

}

/*
 * Horizontal rule
 *
 * Sizes
 */

.hr--s {

  margin-top: $unit-s;
  margin-bottom: $unit-s;

}


/*
 * Horizontal rule
 *
 * Sizes
 */

.u-inline-spacer {

  margin-left: $unit-xs;
  margin-right: $unit-xs;

}

/*
 * Highlight
 *
 * Highlight a word in a text search
 */

.u-highlight {

  background-color: tint($color-warning, 60%);
  box-shadow: 0 0 0 .25rem tint($color-warning, 60%);
  border-radius: $border-radius-small;

}

/*
 * Rich text
 *
 * Adding basic styles to rich text content
 */

.u-rich-text {

  @include overflow-wrap;

  ol,
  ul {

    padding-left: $unit-l;

  }

  ul {

    list-style-type: disc;

  }

  a:not(.c-button) {

    text-decoration: underline;
    text-decoration-color: $color-link;
    text-decoration-thickness: .05em;

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong;
      text-decoration-color: $color-accent-green;
      text-decoration-thickness: .1em;
      background-color: transparentize($color-accent-green, .8);
      box-shadow: 0 0 0 .25rem transparentize($color-accent-green, .8);
      border-radius: $border-radius-small;
      outline: none;

    }

  }

}

/*
 * Sentiment colors
 */

// // Positive
// .u-sentiment-positive {

//   .o-icon-grin {

//     color: $color-sentiment-positive!important;

//   }

// }

// // Mixed
// .u-sentiment-mixed {

//   .o-icon-meh {

//     color: $color-sentiment-mixed!important;

//   }

// }

// // Negative
// .u-sentiment-negative {

//   .o-icon-frown {

//     color: $color-sentiment-negative!important;

//   }

// }

// // Neutral
// .u-sentiment-neutral {

//   .o-icon-meh-blank {

//     color: $color-sentiment-neutral!important;

//   }

// }

/*
 * Emoji
 */

.u-emoji {

  width: 1.25rem;
  height: 1.25rem;

  display: inline-block;
  vertical-align: middle;
  background-size: 1.25rem 1.25rem;

  &.u-emoji--1 { background-image: assets-path("images/polls/slider-emoji-1.png"); }
  &.u-emoji--2 { background-image: assets-path("images/polls/slider-emoji-2.png"); }
  &.u-emoji--3 { background-image: assets-path("images/polls/slider-emoji-3.png"); }
  &.u-emoji--4 { background-image: assets-path("images/polls/slider-emoji-4.png"); }
  &.u-emoji--5 { background-image: assets-path("images/polls/slider-emoji-5.png"); }
  &.u-emoji--6 { background-image: assets-path("images/polls/slider-emoji-6.png"); }
  &.u-emoji--7 { background-image: assets-path("images/polls/slider-emoji-7.png"); }

  .o-radio &,
  .o-checkbox & {

    margin-right: $unit-xs;
    position: relative;
    top: -.0625rem;
    vertical-align: middle;

  }

}

/*
 * Flex group
 */

.u-display-flex {

  display: flex;

}

/*
 * Layout testing
 */

.u-outline-elements {

  * {

    background-color: rgba($color-link, .1);

  }

}