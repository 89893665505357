/*
 * Nav masthead
 *
 * Masthead styles include:
 * - Back button
 * - Title
 * - Item
 */

$page-sidebar: 18rem;
$nav-masthead-back-button: 3rem;
$nav-masthead-title: $page-sidebar - $nav-masthead-back-button;
$nav-masthead-fade: $unit-m;

.c-nav-masthead {

  min-height: 3rem;
  display: flex;
  align-items: stretch;

  @include breakpoint(medium) {

    min-height: 4rem;

  }

}

/*
 * Group: Back + Title
 */

.c-nav-masthead__group {

  display: flex;
  margin-right: $unit-m;
  border: .0625rem solid $color-border-weak;

  border-radius: $border-radius-small;

  @include breakpoint(medium) {

    width: auto;
    margin-right: $unit-l;

  }

}

.c-nav-masthead__back {

  display: flex;
  align-items: center;
  justify-content: center;
  padding: $unit-xs $unit-s;
  min-width: 3rem;
  margin-top: -.0625rem;
  margin-left: -.0625rem;
  margin-bottom: -.0625rem;
  margin-right: -.0625rem;
  border: .0625rem solid $color-border-weak;

  border-radius: $border-radius-small;

  &:hover,
  &:focus,
  &:active {

    color: $color-text-strong;
    background-color: $color-link-tint;
    border-color: transparent;

  }

  .o-label {

    @include hide-visually();

  }

  .o-icon {

    font-size: 1.25rem;

  }

}

.c-nav-masthead__title {

  display: flex;
  flex: 1 0 $nav-masthead-title;
  align-items: center;
  margin-bottom: 0;
  padding: 0 $unit-s;
  max-width: $nav-masthead-title;
  width: 100%;

  font-size: 1rem;
  line-height: 1.25;
  font-family: $font-body;
  color: $color-text;
  font-weight: $semibold;

  .o-label {

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

  }


  @include breakpoint(medium) {

    width: $nav-masthead-title;

    font-size: 1.125rem;

  }

  @include breakpoint(to-large) {

    display: none;

  }

}

.c-nav-masthead__avatar {

  width: 3rem;
  height: 3rem;
  flex: 0 0 3rem;
  margin-right: $unit-xs;
  margin-bottom: 0;
  margin-left: 0 - $unit-xs;

  border-radius: 50%;

}

/*
 * Content
 */

.c-nav-masthead__content {

  position: relative;

  display: flex;
  align-items: stretch;
  width: 100%;
  min-width: 0;

  box-shadow: inset 0 -.0625rem 0 0 $color-border-weak;

  &:before,
  &:after {

    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    width: $unit-m;

    content: "";

    pointer-events: none;

  }

  &:before {

    right: 100%;
    background-image: linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,1) 80%);

  }

  &:after {

    right: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1) 80%);

    @include breakpoint(medium) {

      right: auto;
      left: 100%;

    }

  }

}

.c-nav-masthead__items {

  position: relative;
  display: flex;
  // width: 100%;

  margin: 0;
  padding: 0;
  list-style: none;

  /*
   * Scroll
   * 1. Move beneath the fade
   * 2. Make items one scrollable line
   * 3. Add scroll when needed
   * 4. Make sure the width includes the fade size
   *    by using calc() to compensate for the negative
   *.   margin
   */

  margin-left: 0 - $nav-masthead-fade;   /* [1] */

  white-space: nowrap;        /* [2] */
  overflow-x: auto;           /* [3] */

  @include breakpoint(medium) {

    margin-right: 0 - $nav-masthead-fade;  /* [1] */
    width: calc(100% + #{$nav-masthead-fade} + #{$nav-masthead-fade}); /* [4] */

  }

}

.c-nav-masthead__item {

  display: flex;
  margin-top: 0;
  margin-right: $unit-s;

  font-size: 1rem;
  line-height: 1.5;
  font-weight: $semibold;

  /*
   * Scroll
   * 1. Compensate for parent padding on the left side
   * 2. Compensate for parent padding on the right side
   */

  &:first-child {

    padding-left: $nav-masthead-fade; /* [1] */

  }

  &:last-child {

    padding-right: $nav-masthead-fade; /* [1] */

  }

  @include breakpoint(medium) {

    margin-right: $unit-m;

    font-size: 1.125rem;

  }

}

.c-nav-masthead__item--end {

  @include breakpoint(medium) {

    margin-left: auto;
    margin-right: 0;

  }

}

.c-nav-masthead__link {

  display: flex;
  align-items: center;
  border: none;
  padding-left: 0;
  padding-right: 0;

  color: $color-text;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {

    color: $color-text-strong;
    box-shadow: inset 0 -.1875rem 0 0 $color-accent-green;

  }

  .o-icon:first-child {

    margin-right: $unit-xs;

  }

  .o-icon:last-child {

    margin-left: $unit-xs;

  }

  .is-active & {

    color: $color-text-strong;
    box-shadow: inset 0 -.1875rem 0 0 $color-accent-green;

  }

}

.c-nav-masthead__notification {

  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1.5rem;
  min-height: 1.5rem;
  margin-left: $unit-xs;

  font-size: .875rem;
  text-align: center;
  color: $color-text-strong;
  background-color: $color-accent-green;
  border-radius: 50%;

}