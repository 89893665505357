.c-header-nav {

  display: none;

  position: absolute;
  top: 100%;
  left: 0;
  right: 0;

  background-color: #fff;
  box-shadow: $box-shadow-small;

}

.c-header-nav__item {

  font-size: 1rem;
  line-height: 1.5;
  font-family: $font-stack-system;

  &:not(:first-child) {

    border-top: 1px solid $color-border-normal;

  }

}

.c-header-nav__link {

  display: block;
  width: 100%;
  border: none;
  padding: 1rem;

  color: $color-text-strong;

  // In the case of buttons, add these:
  font-size: 1rem;
  line-height: 1.5;
  background: transparent;
  text-align: left;
  font-family: $font-body;
  text-transform: none;
  cursor: pointer;

  transition: all .1s ease-in;

  &:hover,
  &:focus,
  &:active {

    color: $color-text-weak;

  }

}

.c-header-nav__avatar {

  float: left;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .5rem;
  margin-bottom: 0;

  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='120' height='120' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='rgba(59,59,59,.6)' fill-rule='nonzero'%3e%3ccircle cx='60' cy='44' r='28'/%3e%3ccircle cx='60' cy='132' r='60'/%3e%3c/g%3e%3c/svg%3e");
  background-color: $color-primary-light;
  background-size: 100% 100%;
  border-radius: .75rem;

}

.c-header-nav__label {}

.c-header-nav__value {

  display: inline-block;
  margin-left: .5rem;
  width: 1.5rem;
  height: 1.5rem;

  font-size: .875rem;
  line-height: 1.5;
  text-align: center;
  border-radius: .75rem;

  color: $color-text-strong-invert;
  background: $color-notification;

}

.c-header-nav__item--parent {

  .o-icon {

    float: right;
    width: 1.5rem;
    margin: 0;

    font-size: 1.5rem;
    color: $color-text-weak;

    transition: all .1s ease-in;

  }

  .o-icon-bell {

    float: left;
    width: 1.5rem;
    margin-right: .5rem;

  }

  &.is-opened .o-icon-angle-down {

    transform: rotate(180deg);

  }

}

.c-header-nav__item--actions {

  display: flex;
  justify-content: space-between;
  padding: 1rem;

}

.c-header-nav__action {

  padding: .625rem 1rem;
  border: .125rem solid $color-primary-light;
  width: 100%;

  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  text-transform: none;
  font-family: $font-stack-system;

  border-radius: $border-radius-small;

}

.c-header-nav__action:not(:first-child) {

  margin-left: 1rem;

}

.c-header-nav__action--primary {

    color: $color-text-strong;
    background-color: $color-primary-light;

}

.c-header-nav__action--secondary {

  color: $color-text-strong;

}

/*
 * Desktop
 *
 * Desktop view of the navigation
 */

@include breakpoint(large) {

  .c-header-nav {

    position: relative;
    top: initial;
    left: initial;
    right: initial;

    display: flex;
    align-items: center;
    margin-left: auto;
    height: 100%;

    background: none;
    box-shadow: none;

  }

  .c-header-nav__item {

    &:not(:first-child) {

      border-top: none;
      margin-left: 1rem;

    }

  }

  .c-header-nav__link {

    padding: .75rem 0;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: $unit-xxs;

    color: $color-text-invert;

    .o-icon {

      // position: relative;
      // top: .125rem;
      // width: 1rem;
      // margin-left: .25rem;

      font-size: 1rem;
      color: $color-text-invert;
      transition: $transition-fast;

    }

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong-invert;
      
      .o-icon {
        color: $color-text-strong-invert;
      }

    }

  }

  .c-header-nav__item--parent {

    position: relative;

  }

  .c-header-nav__item--actions {

    padding-right: 0;

  }

  .c-header-nav__item--icon {

    .c-header-nav__value {

      position: absolute;
      top: -.375rem;
      right: -.375rem;

      margin-left: 0;

    }

    .c-header-nav__link {

      display: flex;
      align-items: center;

      width: 3rem;
      height: 3rem;
      padding: 0;

      border-radius: 1.5rem;
      background-color: $color-primary-light;
      text-align: center;

      &:hover,
      &:focus,
      &:active {

        box-shadow: 0 0 0 .25rem rgba(255,255,255,.1);
        background-color: #fff;

      }

    }

    .c-header-nav__link > .o-icon-angle-down,
    .c-header-nav__label {

      @include hide-visually;

    }

    .o-icon-bell {

      @include hide-visually(unhide);

      display: block;
      width: 2rem;
      height: 1.5rem;
      margin: 0 .5rem;
      color: $color-text-strong;

    }

    .c-header-nav__avatar {

      width: 3rem;
      height: 3rem;
      margin-right: 0;
      border-radius: 1.5rem;

    }

  }

  .c-header-nav__item--profile {

    padding: 0;

  }

  .c-header-nav__item--separator {

    padding-left: 1rem;
    position: relative;

    &:before {

      left: 0;
      top: 50%;
      height: 6rem;
      transform: translateY(-50%);
      position: absolute;
      border-left: 1px solid rgba(255,255,255,.1);

      content: "";

    }

  }

  .c-header-nav__action {

    white-space: nowrap;

  }

  .c-header-nav__action--primary {

    color: $color-text;

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong;

    }

  }

  .c-header-nav__action--secondary {

    color: $color-text-invert;

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong-invert;

    }

  }

}

/*
 * Interactions
 *
 * Toggle on and off on mobile
 */

@include breakpoint(to-large) {

  .c-header-nav-opened {

    .c-header-nav {

      display: block;

    }

  }

}