/*
 * Stats
 *
 * Stat items with summary information:
 * - title
 * - highlight
 * - description
 * - note and positive or negative remark
 */

.c-stat-list {

  margin-bottom: 2rem;

}

.c-stat {

  display: flex;
  flex-flow: column;

}

.c-stat__title {

  display: flex;
  align-items: center;

  margin-bottom: .25rem;

  font-size: 1rem;
  line-height: 1.25;
  // line-height: 1.5;
  color: $color-text-strong;
  font-family: $font-body;
  font-weight: $semibold;
  letter-spacing: 0;

  abbr {

    margin-left: $unit-xs;

  }

}

.c-stat__icon {

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 2.5rem;
  min-height: 2.5rem;
  margin-right: $unit-xs;

  background-color: $color-accent-green;
  border-radius: 50%;

  .o-icon {

    font-size: 1.25rem;
    width: 2rem;

    display: block;

  }

  // Fix for HTML2Canvas reports
  .o-icon--inline,
  .o-icon--inline .o-icon {

    width: auto;

  }

}

.c-stat__highlight {

  color: $color-link;
  font-weight: $light;
  font-size: 2rem;
  line-height: 1.25;

  @include breakpoint(medium) {

    font-size: 2.5rem;
    line-height: 1.2;

  }

}

.c-stat__description {

  margin-top: .25rem;
  margin-bottom: 0;

  font-size: 1rem;
  line-height: 1.5;
  color: $color-text;

  .o-icon {

    height: 1.25rem;

    vertical-align: middle;

  }

  small {

    display: block;

    font-size: .875rem;
    line-height: 1.4285714286;
    color: $color-text-weak;

  }

}

.c-stat__note {

  display: block;

}

.c-stat__positive {

  color: $color-success;
  font-weight: $semibold;

}

.c-stat__negative {

  color: $color-danger;
  font-weight: $semibold;

}

/*
 * Modifiers
 */

.c-stat--row {

  display: grid;
  grid-column-gap: .5rem;
  grid-row-gap: 0;
  grid-template-columns: auto 100%;

  .c-stat__highlight {

    align-self: flex-start;
    grid-row: 1 / span 2;
    // min-width: 4rem;

    font-size: 2rem;
    font-weight: $regular;
    white-space: nowrap;

  }

  .c-stat__title,
  .c-stat__description {

    align-self: center;
    margin: 0;

  }

}

.c-stat--poll {

  display: grid;
  grid-column-gap: .5rem;

}

/*
 * Layout
 */

.l-stat-list {

  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-columns: 1fr;

}

.l-stat-list--two {

  @include breakpoint(extra-small) {

    grid-template-columns: 1fr 1fr;

  }

}

.l-stat-list--three {

  @include breakpoint(extra-small) {

    grid-template-columns: 1fr 1fr;

  }

  @include breakpoint(large) {

    grid-template-columns: 1fr 1fr 1fr;

  }

}

.l-stat-list--four {

  @include breakpoint(extra-small) {

    grid-template-columns: 1fr 1fr;

  }

  @include breakpoint(large) {

    grid-template-columns: 1fr 1fr 1fr 1fr;

  }

}

.l-stat-list--rows {

  grid-row-gap: 1rem;

}

.l-stat--s {}

.l-stat--m {

  @include breakpoint(extra-small) {

    grid-column: span 2;

  }

}

.l-stat--l {

  .c-visualisation__content {

    svg {
      width: 100%;
      height: auto;
      max-width: rem(400);
    }
  }
  

  @include breakpoint(medium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    .c-stat__title {
      grid-column: 1 / span 2;
    }
    
    .c-visualisation__content {
      
      svg {
        max-width: none;
      }
    }
    
    .c-visualisation__legend {
      padding: $unit-xl;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    
    
  }

}


/*
 * Print report
 *
 * Modifications for printed reports
 */

.l-print {

  .c-stat-list {

    page-break-inside: avoid;

  }

  .c-stat__title {

    font-size: .75rem;
    line-height: 1.5;

  }

  .c-stat__highlight {

    font-size: 2rem;
    line-height: 1.25;

  }

  .c-stat__description {

    font-size: .75rem;
    line-height: 1.5;

    small {

      font-size: .75rem;
      line-height: 1.5;

    }

  }

  .l-stat-list--four {

    grid-template-columns: 1fr 1fr 1fr 1fr;

  }

}