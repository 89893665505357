/*
 * Preview
 */

$preview-mobile-width: 400px;
$preview-mobile-height: 720px;

$preview-tablet-width: 770px; // 768 usually, but with border it's 770px
$preview-tablet-height: 960px;

$preview-desktop-width: 1024px;
$preview-desktop-height: 800px;

.c-preview {

  position: relative;

  margin: auto;
  display: inline-block;

  box-shadow: $box-shadow-extra-large;
  box-shadow: inset 0 0 .5rem .25rem #fff,
              inset 0 0 2rem .5rem rgba($color-primary-dark, .05),
              0 .5rem 1.5rem 0 rgba($color-primary-dark, .1),
              0 1rem 4rem 0 rgba($color-primary-dark, .1);

  transition: all .1s ease-in;

}

.c-preview--mobile  {

  padding: 5rem 1rem;
  border-radius: 2rem;

  .c-preview__content {

    width: $preview-mobile-width;
    height: $preview-mobile-height;

    &:after,
    &:before {

      position: absolute;
      left: 50%;

      content: "";

    }

    &:before {

      top: 2.5rem;
      transform: translate(-50%, -50%);

      width: 4rem;
      height: .5rem;

      border-radius: .25rem;
      background-color: rgba($color-primary-dark, .05);
      box-shadow: inset 0 .0625rem .125rem 0 rgba($color-primary-dark, .1);

    }

    &:after {

      bottom: 2.5rem;
      transform: translate(-50%, 50%);

      width: 3rem;
      height: 3rem;

      border-radius: 50%;
      background-color: rgba($color-primary-dark, .05);
      box-shadow: inset 0 .0625rem .125rem 0 rgba($color-primary-dark, .1);
    }

  }

}

.c-preview--tablet  {

  padding: 2rem;
  border-radius: 2rem;

  .c-preview__content {

    width: $preview-tablet-width;
    height: $preview-tablet-height;

  }

}

.c-preview--desktop {

  padding: 1rem 1rem 1rem 1rem;
  border-radius: 1rem;

  .c-preview__content {

    width: $preview-desktop-width;
    height: $preview-desktop-height;

  }

}

.c-preview__content {

  overflow: hidden;

  border-radius: $border-radius-medium;
  border: .0625rem solid $color-border-weak;

  iframe {

    transition: all .1s ease-in;

  }

}

.c-preview__open {

  position: absolute;
  top: 100%;
  left: 0;
  right: 0;

  margin-top: $unit-s;

  text-align: center;

  .o-button {

    font-size: .875rem;
    color: $color-text-weak;

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong;

    }

  }

}

.c-preview-open {

  margin-left: auto;
  margin-right: auto;
  padding-top: $unit-m;
  padding-bottom: $unit-m;

  .o-button {

    font-size: .875rem;
    color: $color-text-weak;

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong;

    }

  }
}